import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
    AccountingSalaryConfig,
    AccountingSettings,
    Company,
    CostCenter,
    Venue,
    WageType,
} from "@pentacode/core/src/model";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./scroller";
import "./bonus-type-item";
import { alert, confirm } from "./alert-dialog";
import { clone, deserialize } from "@pentacode/core/src/encoding";
import { singleton } from "../lib/singleton";
import { AccountingSalaryConfigDialog } from "./accounting-salary-config-dialog";
import "./sortable-list";
import { patterns } from "@pentacode/core/src/validation";
import { CostCenterDialog } from "./cost-center-dialog";
import { WageTypeDialog } from "./wage-type-dialog";
import "./entity-filters";
import "./help";
import "./popover";
import "./spinner";
import { BMD_WAGE_TYPES } from "@pentacode/core/src/payroll-bmd";

@customElement("ptc-settings-accounting")
export class SettingsAccounting extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/accounting/;

    @state()
    private _loading = false;

    @state()
    private _accountingSalaryConfigs: AccountingSalaryConfig[] = [];

    @state()
    private _costCenters: CostCenter[] = [];

    @state()
    private _wageTypes: WageType[] = [];

    @state()
    private _venueId: number | null = null;

    @singleton("ptc-accounting-salary-config-dialog")
    private _accountingSalaryConfigDialog: AccountingSalaryConfigDialog;

    @singleton("ptc-cost-center-dialog")
    private _costCenterDialog: CostCenterDialog;

    @singleton("ptc-wage-type-dialog")
    private _wageTypeDialog: WageTypeDialog;

    @query("form")
    private _form: HTMLFormElement;

    private get _venue() {
        return (this._venueId && app.company?.venues.find((venue) => venue.id === this._venueId)) || null;
    }

    private get _data() {
        if (!this._form) {
            return {};
        }

        const data = new FormData(this._form);

        return this._venue
            ? {
                  accountingConsultant: (data.get("consultant") as string) || null,
                  accountingClient: (data.get("client") as string) || null,
                  cashbookLedger: (data.get("cashbookLedger") as string) || null,
                  realAccountsLength: data.get("realAccountsLength")
                      ? Number(data.get("realAccountsLength") as string)
                      : null,
              }
            : {
                  consultant: data.get("consultant") as string,
                  client: data.get("client") as string,
                  companyNumber: data.get("companyNumber") as string,
                  cashbookLedger: data.get("cashbookLedger") as string,
                  realAccountsLength: Number(data.get("realAccountsLength") as string),
              };
    }

    private get _hasDataChanged() {
        return this._venue
            ? Object.entries(this._data).some(([prop, value]) => this._venue![prop as keyof Venue] !== value)
            : Object.entries(this._data).some(
                  ([key, value]) => app.company!.settings.accounting[key as keyof AccountingSettings] !== value
              );
    }

    private _reset() {
        if (this._venue) {
            (this.renderRoot.querySelector(`[name="consultant"]`) as HTMLInputElement).value =
                this._venue.accountingConsultant?.toString() || "";
            (this.renderRoot.querySelector(`[name="client"]`) as HTMLInputElement).value =
                this._venue.accountingClient?.toString() || "";
            (this.renderRoot.querySelector(`[name="cashbookLedger"]`) as HTMLInputElement).value =
                this._venue.cashbookLedger?.toString() || "";
            (this.renderRoot.querySelector(`[name="realAccountsLength"]`) as HTMLInputElement).value =
                this._venue.realAccountsLength?.toString() || "";
        } else {
            const { salaryConfigs, ...rest } = app.company!.settings.accounting;
            this._accountingSalaryConfigs =
                salaryConfigs.map((config) => deserialize(AccountingSalaryConfig, config)) || [];
            this._costCenters = app.company?.costCenters || [];
            this._wageTypes = app.company?.wageTypes || [];
            for (const [prop, value] of Object.entries(rest)) {
                const input = this.renderRoot.querySelector(`[name="${prop}"]`) as HTMLInputElement;
                if (input) {
                    input.value = value.toString();
                }
            }
        }
        this.requestUpdate();
    }

    updated(changes: Map<string, unknown>) {
        if ((changes.has("active") && this.active) || changes.has("_venueId")) {
            this._reset();
        }
    }

    private async _addAccountingSalaryConfig(
        config: AccountingSalaryConfig | undefined = new AccountingSalaryConfig()
    ) {
        config = await this._accountingSalaryConfigDialog.show(config);

        if (config) {
            this._accountingSalaryConfigs.push(config);
            await this._submit();
        }
    }

    private async _editAccountingSalaryConfig(config: AccountingSalaryConfig) {
        const updated = await this._accountingSalaryConfigDialog.show(config);
        if (updated) {
            this._wageTypes = app.company?.wageTypes || [];
            Object.assign(config, updated);
            await this._submit();
        }
    }

    private async _removeAccountingSalaryConfig(index: number) {
        if (
            await confirm("Sind Sie sicher dass Sie diesen Lohnartensatz löschen möchten?", "Löschen", "Abbrechen", {
                type: "destructive",
                title: "Lohnartensatz Löschen",
            })
        ) {
            this._accountingSalaryConfigs.splice(index, 1);
            await this._submit();
        }
    }

    private async _duplicateAccountingSalaryConfig(config: AccountingSalaryConfig) {
        const duplicate = clone(config);
        delete duplicate.id;
        duplicate.name += " (Kopie)";
        await this._addAccountingSalaryConfig(duplicate);
    }

    private async _addCostCenter() {
        const costCenter = await this._costCenterDialog.show();
        if (costCenter) {
            this._costCenters.push(costCenter);
            await this._submit();
        }
    }

    private async _editCostCenter(i: number) {
        const costCenter = await this._costCenterDialog.show(this._costCenters[i]);
        if (costCenter) {
            this._costCenters[i] = costCenter;
            await this._submit();
        }
    }

    private async _removeCostCenter(index: number) {
        if (
            await confirm("Sind Sie sicher dass Sie diese Kostenstelle löschen möchten?", "Löschen", "Abbrechen", {
                type: "destructive",
                title: "Kostenstelle Löschen",
            })
        ) {
            this._costCenters.splice(index, 1);
            await this._submit();
        }
    }

    private async _addWageType() {
        const wageType = await this._wageTypeDialog.show();
        if (wageType) {
            this._wageTypes.push(wageType);
            await this._submit();
        }
    }

    private async _editWageType(i: number) {
        const wageType = await this._wageTypeDialog.show(this._wageTypes[i]);
        if (wageType) {
            const oldWageType = this._wageTypes[i];
            this._wageTypes[i] = wageType;
            this._accountingSalaryConfigs.forEach((config) => {
                config.updateWageTypeNumber(oldWageType.number, wageType.number);
            });
            await this._submit();
        }
    }

    private async _removeWageType(index: number) {
        if (
            await confirm("Sind Sie sicher dass Sie diese Lohnart löschen möchten?", "Löschen", "Abbrechen", {
                type: "destructive",
                title: "Lohnart Löschen",
            })
        ) {
            this._wageTypes.splice(index, 1);
            await this._submit();
        }
    }
    private async _orderChanged() {
        this._accountingSalaryConfigs.forEach((t, i) => (t.order = i));
        await this._submit();
    }

    private async _generateBMDWageTypes(company: Company) {
        if (
            await confirm(
                "Möchten Sie alle Standard BMD Lohnarten generieren? Bestehende Lohnarten werden nicht überschrieben.",
                "OK",
                "Abbrechen",
                {
                    type: "question",
                    title: "BMD Lohnarten",
                }
            )
        ) {
            this._loading = true;

            const additionalBMDWageTypes = BMD_WAGE_TYPES.filter(
                (wageType) => !company.wageTypes.some((wt) => wt.number === wageType.number)
            );

            try {
                await app.updateCompany({
                    wageTypes: [...company.wageTypes, ...additionalBMDWageTypes].sort(
                        (a, b) => Number(a.number) - Number(b.number)
                    ),
                });

                this._reset();
            } catch (e) {
                await alert(e.message, { type: "warning" });
            }

            this._loading = false;
        }
    }

    private async _submit(e?: Event) {
        e?.preventDefault();

        if (!app.company) {
            return;
        }
        this._loading = true;
        try {
            if (this._venue) {
                await app.updateVenue({
                    id: this._venue.id,
                    ...this._data,
                });
            } else {
                const settings = clone(app.company.settings);
                const formData = this._data;
                Object.assign(settings.accounting, {
                    ...formData,
                    salaryConfigs: this._accountingSalaryConfigs,
                });
                await app.updateCompany({ settings, costCenters: this._costCenters, wageTypes: this._wageTypes });
            }
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._reset();
        this._loading = false;
    }

    static styles = [shared, css``];

    private _renderForm(venue: Venue | null = null) {
        const countrySpecificPatterns = patterns[app.company?.country ?? "DE"] ?? patterns.DE;
        return html`
            <form @submit=${this._submit} @input=${() => this.requestUpdate()} class="vertical layout fit-vertically">
                ${app.accessibleVenues.length > 1
                    ? html`
                          <div style="max-width: 50em; margin: 0 auto;">
                              <div class="padded horizontal tabs">
                                  <button type="button" ?active=${!venue} @click=${() => (this._venueId = null)}>
                                      <i class="globe"></i> Global
                                  </button>

                                  ${app.accessibleVenues.map(
                                      (ven) => html`
                                          <button
                                              type="button"
                                              ?active=${ven.id === venue?.id}
                                              @click=${() => (this._venueId = ven.id)}
                                          >
                                              <i class="people-roof"></i> ${ven.name}
                                          </button>
                                      `
                                  )}
                              </div>
                          </div>
                      `
                    : ""}

                <ptc-scroller class="stretch">
                    <div style="max-width: 50em; margin: 0 auto;">
                        <input type="hidden" name="venueId" .value=${venue?.id.toString() || ""} />

                        <div class="padded">
                            <h2>Allgemein</h2>
                            <div class="fields horizontal spacing layout">
                                <div class="field stretch">
                                    <label>
                                        Beraternummer
                                        <ptc-help-anchor
                                            page="handbuch/einstellungen/buchhaltung/#berater--und-mandantennummer"
                                        >
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>

                                    <input name="consultant" pattern=${countrySpecificPatterns.consultantNumber} />
                                </div>

                                <div class="field stretch">
                                    <label>
                                        Mandantennummer
                                        <ptc-help-anchor
                                            page="handbuch/einstellungen/buchhaltung/#berater--und-mandantennummer"
                                        >
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>

                                    <input name="client" pattern=${countrySpecificPatterns.clientNumber} />
                                </div>
                            </div>

                            <div class="horizontal spacing evenly stretching layout">
                                ${!this._venue
                                    ? html`
                                          <div class="field stretch">
                                              <label>
                                                  Betriebsnummer
                                                  <ptc-help-anchor
                                                      page="handbuch/einstellungen/buchhaltung/#betriebsnummer"
                                                  >
                                                      <i class="faded question-circle"></i>
                                                  </ptc-help-anchor>
                                              </label>

                                              <input
                                                  name="companyNumber"
                                                  pattern=${countrySpecificPatterns.companyNumber}
                                              />
                                          </div>

                                          <div class="stretch"></div>
                                      `
                                    : ""}
                            </div>

                            <h2>Kassenbuch</h2>

                            <div class="horizontal spacing evenly stretching layout">
                                <div class="field">
                                    <label>
                                        Buchungskonto
                                        <ptc-help-anchor page="handbuch/einstellungen/buchhaltung/#buchungskonto">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>

                                    <input name="cashbookLedger" type="number" />
                                </div>

                                <div class="field">
                                    <label>
                                        Sachkontenlänge
                                        <ptc-help-anchor page="handbuch/einstellungen/buchhaltung/#sachkontenlänge">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>

                                    <select ?required=${!this._venue} name="realAccountsLength">
                                        ${this._venue ? html` <option value="">[Länge Wählen]</option> ` : ""}
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                    </select>
                                </div>
                            </div>

                            ${!venue
                                ? html`
                                      <h2>Kostenstellen</h2>

                                      <div class="box">
                                          <ptc-sortable-list
                                              .items=${this._costCenters}
                                              .renderItem=${(costCenter: CostCenter, i: number) => html`
                                                  <div class="relative border-bottom">
                                                      <div
                                                          class="double-padded click"
                                                          style="padding-right: 3.5em"
                                                          @click=${() => this._editCostCenter(i)}
                                                      >
                                                          <div class="horizontal spacing layout">
                                                              <div class="larger stretch">${costCenter.name}</div>
                                                              <div class="subtle">#${costCenter.number}</div>
                                                          </div>
                                                          <ptc-entity-filters
                                                              readonly
                                                              hideFiltericon
                                                              class="top-margined"
                                                              .filters=${costCenter.entities}
                                                          ></ptc-entity-filters>
                                                      </div>
                                                      <button
                                                          class="transparent slim margined absolute top right"
                                                          type="button"
                                                      >
                                                          <i class="ellipsis-h"></i>
                                                      </button>

                                                      <ptc-popover class="popover-menu" hide-on-click>
                                                          <button
                                                              @click=${() => this._removeCostCenter(i)}
                                                              type="button"
                                                          >
                                                              <i class="trash"></i>
                                                              Entfernen
                                                          </button>
                                                      </ptc-popover>
                                                  </div>
                                              `}
                                              @item-moved=${this._orderChanged}
                                          >
                                          </ptc-sortable-list>

                                          <div class="margined vertical layout" @click=${() => this._addCostCenter()}>
                                              <button class="transparent" type="button">
                                                  <i class="plus"></i> Neue Kostenstelle
                                              </button>
                                          </div>
                                      </div>

                                      <div class="relative layout between-justifying end-aligning">
                                          <h2>Lohnarten</h2>

                                          ${app.company?.country === "AT"
                                              ? html` <button class="transparent slim margined" type="button">
                                                        <i class="ellipsis-h"></i>
                                                    </button>

                                                    <ptc-popover class="popover-menu" hide-on-click>
                                                        <button
                                                            @click=${() => this._generateBMDWageTypes(app.company!)}
                                                            type="button"
                                                        >
                                                            <i class="layer-plus"></i>
                                                            BMD Lohnarten generieren
                                                        </button>
                                                    </ptc-popover>`
                                              : ""}
                                      </div>

                                      <div class="box">
                                          <ptc-sortable-list
                                              .items=${this._wageTypes}
                                              .renderItem=${(wageType: WageType, i: number) => html`
                                                  <div class="relative border-bottom">
                                                      <div
                                                          class="double-padded click"
                                                          style="padding-right: 3.5em"
                                                          @click=${() => this._editWageType(i)}
                                                      >
                                                          <div class="horizontal spacing layout">
                                                              <div class="larger stretch">${wageType.name}</div>
                                                              <div class="subtle">#${wageType.number}</div>
                                                          </div>
                                                          <div class="subtle small">${wageType.description}</div>
                                                      </div>
                                                      <button
                                                          class="transparent slim margined absolute top right"
                                                          type="button"
                                                      >
                                                          <i class="ellipsis-h"></i>
                                                      </button>

                                                      <ptc-popover class="popover-menu" hide-on-click>
                                                          <button @click=${() => this._removeWageType(i)} type="button">
                                                              <i class="trash"></i>
                                                              Entfernen
                                                          </button>
                                                      </ptc-popover>
                                                  </div>
                                              `}
                                              @item-moved=${this._orderChanged}
                                          >
                                          </ptc-sortable-list>

                                          <div class="margined vertical layout" @click=${() => this._addWageType()}>
                                              <button class="transparent" type="button">
                                                  <i class="plus"></i> Neue Lohnart
                                              </button>
                                          </div>
                                      </div>

                                      <h2>Lohnartensätze</h2>

                                      <div class="box">
                                          <ptc-sortable-list
                                              .items=${this._accountingSalaryConfigs}
                                              .renderItem=${(t: AccountingSalaryConfig, i: number) => html`
                                                  <div class="relative border-bottom">
                                                      <div
                                                          class="double-padded click"
                                                          style="padding-right: 3em"
                                                          @click=${() => this._editAccountingSalaryConfig(t)}
                                                      >
                                                          <div class="larger">${t.name}</div>
                                                          <ptc-entity-filters
                                                              readonly
                                                              hideFiltericon
                                                              class="top-margined"
                                                              .filters=${t.entities}
                                                          ></ptc-entity-filters>
                                                      </div>

                                                      <button
                                                          class="transparent slim margined absolute top right"
                                                          type="button"
                                                      >
                                                          <i class="ellipsis-h"></i>
                                                      </button>

                                                      <ptc-popover class="popover-menu" hide-on-click>
                                                          <button
                                                              @click=${() => this._editAccountingSalaryConfig(t)}
                                                              type="button"
                                                          >
                                                              <i class="pencil-alt"></i>
                                                              Bearbeiten
                                                          </button>

                                                          <button
                                                              @click=${() => this._removeAccountingSalaryConfig(i)}
                                                              type="button"
                                                          >
                                                              <i class="trash"></i>
                                                              Entfernen
                                                          </button>

                                                          <button
                                                              @click=${() => this._duplicateAccountingSalaryConfig(t)}
                                                              type="button"
                                                          >
                                                              <i class="clone"></i>
                                                              Duplizieren
                                                          </button>
                                                      </ptc-popover>
                                                  </div>
                                              `}
                                              @item-moved=${this._orderChanged}
                                          >
                                          </ptc-sortable-list>

                                          <div
                                              class="margined vertical layout"
                                              @click=${() => this._addAccountingSalaryConfig()}
                                          >
                                              <button class="transparent" type="button">
                                                  <i class="plus"></i> Neuer Lohnartensatz
                                              </button>
                                          </div>
                                      </div>
                                  `
                                : ""}
                        </div>
                    </div>
                </ptc-scroller>

                <div
                    class="padded spacing evenly stretching horizontal layout"
                    style="width: 100%; box-sizing: border-box; max-width: 50em; margin: 0 auto;"
                    ?hidden=${!this._hasDataChanged}
                >
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${this._reset}>Abbrechen</button>
                </div>
            </form>
        `;
    }

    render() {
        if (!app.company) {
            return;
        }
        return html`
            ${this._renderForm(this._venue)}

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
