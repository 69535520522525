import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { ExportResponse } from "@pentacode/core/src/api";
import { fileIcon, MimeType } from "@pentacode/core/src/model";
import { asSinglePDF, downloadFromUrl, formatFileSize, unzip } from "../lib/util";
import { print } from "../lib/print";
import "./spinner";
import "./scroller";
import { shared } from "../styles";
import type { Scroller } from "./scroller";

@customElement("ptc-exports-result")
export class ExportsResult extends LitElement {
    /**
     * Promise that resolves to the export response. Loading and reponse states will be set accordingly.
     * If null, states will be reset to initial state.
     */
    @property({ type: Object })
    exportResponse: ExportResponse | null = null;

    /**
     * Scroller to scroll to the response anchor
     */
    @property({ type: Object })
    scroller: Scroller | null = null;

    @property()
    onCancel?: () => Promise<void>;

    @property({ type: Boolean })
    loading: boolean = false;

    @state()
    _loadingText = "";

    @state()
    private _displayInfoMessages = false;

    @state()
    private _displayWarningMessages = true;

    @state()
    private _displayErrorMessages = true;

    async updated(changedProperties: Map<PropertyKey, unknown>) {
        if (!changedProperties.has("exportResponse") && !changedProperties.has("loading")) {
            return;
        }

        // starting to load
        if (this.loading && !this.exportResponse) {
            this._loadingText = "Ihr Export wird vorbereitet. Bitte haben Sie etwas Geduld...";
            this.requestUpdate();
            await this.updateComplete;

            if (this.scroller) {
                this.scroller.scrollTop = (
                    this.renderRoot.querySelector("#responseAnchor") as HTMLDivElement
                )?.offsetTop;
            }
        } else if (!this.loading && this.exportResponse) {
            this._loadingText = "";
            this._displayInfoMessages = false;
            this._displayWarningMessages = !!this.exportResponse?.messages?.some((m) => m.type === "warning");
            this._displayErrorMessages = !!this.exportResponse?.messages?.some((m) => m.type === "error");
        } else if (!this.loading && !this.exportResponse) {
            this._loadingText = "";
            this._displayInfoMessages = false;
            this._displayWarningMessages = false;
            this._displayErrorMessages = false;
        }
    }

    private get _openFileUrl() {
        if (!this.exportResponse) {
            return "";
        }

        switch (this.exportResponse.type) {
            case MimeType.MSExcel:
            case MimeType.MSExcelLegacy:
            case MimeType.MSWord:
            case MimeType.MSWordLegacy:
                return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.exportResponse.url)}`;
            default:
                return this.exportResponse.url;
        }
    }

    private async _printResponse() {
        if (!this.exportResponse) {
            return;
        }

        this._loadingText = "Druck wird vorbereitet...";

        try {
            if (this.exportResponse.type === MimeType.Zip) {
                const file = await fetch(this.exportResponse.url);
                const files = await unzip(await file.arrayBuffer());
                const pdf = await asSinglePDF(files);
                const url = await URL.createObjectURL(new Blob([new Uint8Array(pdf)], { type: MimeType.PDF }));
                await print(url, false);
            } else {
                const file = await fetch(this.exportResponse.url);
                const url = await URL.createObjectURL(await file.blob());
                await print(url, false);
            }
        } finally {
            this._loadingText = "";
        }
    }

    private async _downloadResponse() {
        if (!this.exportResponse) {
            return;
        }

        this._loadingText = "Download wird vorbereitet...";

        try {
            if (this.exportResponse.type === MimeType.Zip) {
                await downloadFromUrl(this.exportResponse.url, this.exportResponse.fileName);
            } else {
                const file = await fetch(this.exportResponse.url);
                const url = await URL.createObjectURL(await file.blob());
                await downloadFromUrl(url, this.exportResponse.fileName);
            }
        } finally {
            this._loadingText = "";
        }
    }

    /**
     * @returns a tuple of classes for the message list item and the icon
     */
    private getMessageClasses = (type: "info" | "warning" | "error"): [string, string] => {
        if (type === "info") {
            return ["", "info-circle"];
        } else if (type === "warning") {
            return ["orange colored-text", "exclamation-triangle"];
        }

        return ["red colored-text", "hexagon-exclamation"];
    };

    static styles = [shared];

    render() {
        return html`
            ${this.exportResponse?.messages?.length
                ? html`
                      <div class="double-margined box">
                          <div class="half-padded centering spacing horizontal layout">
                              <button
                                  class="transparent skinny"
                                  ?active=${this._displayInfoMessages}
                                  @click=${() => (this._displayInfoMessages = !this._displayInfoMessages)}
                              >
                                  <i class="info-circle"></i>
                                  ${this.exportResponse.messages.filter((m) => m.type === "info").length}
                              </button>
                              <button
                                  class="transparent skinny orange"
                                  ?active=${this._displayWarningMessages}
                                  @click=${() => (this._displayWarningMessages = !this._displayWarningMessages)}
                              >
                                  <i class="exclamation-triangle"></i>
                                  ${this.exportResponse.messages.filter((m) => m.type === "warning").length}
                              </button>
                              <button
                                  class="transparent skinny red"
                                  ?active=${this._displayErrorMessages}
                                  @click=${() => (this._displayErrorMessages = !this._displayErrorMessages)}
                              >
                                  <i class="hexagon-exclamation"></i>
                                  ${this.exportResponse.messages.filter((m) => m.type === "error").length}
                              </button>
                          </div>
                          <ptc-scroller style="max-height: 20em">
                              <div class="double-padded">
                                  <ul class="smaller">
                                      ${this.exportResponse.messages
                                          .filter(
                                              (m) =>
                                                  (m.type === "info" && this._displayInfoMessages) ||
                                                  (m.type === "warning" && this._displayWarningMessages) ||
                                                  (m.type === "error" && this._displayErrorMessages)
                                          )
                                          .map(({ type, content }) => {
                                              const [liClass, iconClass] = this.getMessageClasses(type);

                                              return html`<li
                                                  class="${liClass}"
                                                  style="text-indent: -1.3em; margin-left: 1.3em; margin-bottom: 0.2em;"
                                              >
                                                  <i class="bold inline ${iconClass}"></i>
                                                  ${content}
                                              </li>`;
                                          })}
                                  </ul>
                              </div>
                          </ptc-scroller>
                      </div>
                  `
                : ""}
            ${this._loadingText || this.exportResponse
                ? html`
                      <div class="double-margined centering vertical layout">
                          <i class="arrow-down"></i>
                      </div>
                  `
                : ""}

            <div id="responseAnchor"></div>

            <div
                class="double-margined double-padded centering text-centering horizontal layout box"
                ?hidden=${!this._loadingText}
            >
                <ptc-spinner ?active=${!!this._loadingText} class="tiny"></ptc-spinner>
                <div class="subtle left-margined">${this._loadingText}</div>
            </div>

            ${this.exportResponse && !this.loading
                ? html`
                      <div class="margined text-centering">
                          <a href="${this.exportResponse.url}" target="_blank" class="margined block no-wrap" download>
                              <i class="${fileIcon(this.exportResponse.type)}"></i> ${this.exportResponse.fileName}
                              <span class="small subtle no-wrap">${formatFileSize(this.exportResponse.size)}</span>
                          </a>

                          <div class="small margined spacing centering horizontal layout">
                              <button
                                  @click=${() => window.open(this._openFileUrl, "_blank")}
                                  ?hidden=${this.exportResponse!.type === MimeType.Zip}
                                  class="slim ghost"
                              >
                                  <i class="eye"></i> Öffnen
                              </button>
                              <button @click=${this._downloadResponse} class="slim ghost">
                                  <i class="download"></i> Downloaden
                              </button>
                              <button
                                  @click=${this._printResponse}
                                  class="slim ghost"
                                  ?hidden=${![MimeType.PDF, MimeType.Zip].includes(this.exportResponse.type)}
                              >
                                  <i class="print"></i> Drucken
                              </button>
                          </div>
                      </div>

                      <div class="double-margined vertical layout">
                          <button
                              class="transparent"
                              @click=${() => {
                                  this.onCancel && void this.onCancel();
                              }}
                          >
                              Neuer Export...
                          </button>
                      </div>
                  `
                : ""}
        `;
    }
}
