import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { app, router } from "../init";
import { shared } from "../styles";
import "./rich-text-editor";
import "./entity-multi-select";
import { StateMixin } from "../mixins/state";
import {
    Contract,
    Employee,
    EmploymentType,
    employmentTypeLabel,
    getEmploymentTypes,
    JobApplication,
    JobPosting,
    MimeType,
    Position,
    Salary,
} from "@pentacode/core/src/model";
import type { EntityMultiSelect } from "./entity-multi-select";
import type { RichTextEditor } from "./rich-text-editor";
import { alert, confirm } from "./alert-dialog";
import { CreateOrUpdateJobPostingParams } from "@pentacode/core/src/api";
import { DateInput } from "./date-input";
import { formatDate, formatNumber, parseDateString, toDateString } from "@pentacode/core/src/util";
import "./rich-content";
import "./avatar";
import { singleton } from "../lib/singleton";
import { JobApplicationDialog } from "./job-application-dialog";
import { NewEmployeeDialog } from "./new-employee-dialog";
import { readFileAsDataURL } from "../lib/util";
import { truncate } from "../lib/lit-dependent-util";
import type { RichContent } from "./rich-content";
import "./scroller";
import { DateString, Euros, Hours } from "@pentacode/openapi";

@customElement("ptc-recruiting-posting")
export class RecruitingPosting extends StateMixin(LitElement) {
    @property({ attribute: false })
    posting: JobPosting;

    @state()
    private _loading = false;

    @state()
    private _editing = false;

    @state()
    private _images: string[] = [];

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='title']")
    private _titleInput: HTMLInputElement;

    @query("select[name='venueId']")
    private _venueIdSelect: HTMLSelectElement;

    @query("select[name='employmentType']")
    private _employementTypeSelect: HTMLSelectElement;

    @query("select[name='payType']")
    private _payTypeSelect: HTMLSelectElement;

    @query("input[name='payAmount']")
    private _payAmountInput: HTMLInputElement;

    @query("input[name='hoursPerWeek']")
    private _hoursPerWeekInput: HTMLInputElement;

    @query("ptc-date-input[name='start']")
    private _startInput: DateInput;

    @query("ptc-date-input[name='end']")
    private _endInput: DateInput;

    @query("ptc-entity-multi-select")
    private _positionsSelector: EntityMultiSelect<Position>;

    @query("ptc-rich-text-editor")
    private _descriptionEditor: RichTextEditor;

    @query("ptc-rich-content")
    private _descriptionContainer: RichContent;

    @query(".sidebar-inner")
    private _sidebarInner: HTMLDivElement;

    @query("input[type='file']")
    private _imageInput: HTMLInputElement;

    @singleton("ptc-job-application-dialog")
    private _jobApplicationDialog: JobApplicationDialog;

    @singleton("ptc-new-employee-dialog")
    private _newEmployeeDialog: NewEmployeeDialog;

    updated(changes: Map<string, unknown>) {
        if (changes.has("posting")) {
            this._editing = !this.posting.id;
        }

        setTimeout(() => {
            if (this._descriptionContainer) {
                this._descriptionContainer.previewHeight = Math.max(this._sidebarInner.offsetHeight - 80, 0);
            }
        }, 100);
    }

    async edit() {
        this._editing = true;
        await this.updateComplete;
        this._resetForm();
    }

    cancelEdit() {
        this._editing = false;
        this.dispatchEvent(new CustomEvent(this.posting.id ? "posting-edit-canceled" : "posting-create-canceled"));
    }

    private _resetForm() {
        if (!this._titleInput) {
            return;
        }
        this._titleInput.value = this.posting.title;
        this._startInput.value = this.posting.start;
        this._endInput.value = this.posting.end;
        this._payAmountInput.value = this.posting.payAmount?.toFixed(2) || "";
        this._payTypeSelect.value = this.posting.payType;
        this._venueIdSelect.value = this.posting.venueId.toString();
        this._descriptionEditor.content = this.posting.description;
        this._positionsSelector.selected = this.posting.positions || [];
        this._employementTypeSelect.value = this.posting.employmentType.toString();
        this._hoursPerWeekInput.value = this.posting.hoursPerWeek?.toFixed(2) || "";
        this._images = this.posting.images;
    }

    private async _submit(e: Event) {
        e.preventDefault();

        const data = new FormData(this._form);

        const title = data.get("title") as string;
        const start = (data.get("start") as DateString) || null;
        const end = (data.get("end") as DateString) || null;
        const positions = this._positionsSelector.selected.map((p) => p.id);
        const description = this._descriptionEditor.content;
        const payType = data.get("payType") as "monthly" | "hourly";
        const payAmount = Number(data.get("payAmount")) as Euros;
        const venueId = Number(data.get("venueId"));
        const hoursPerWeek = data.get("hoursPerWeek") ? (Number(data.get("hoursPerWeek")) as Hours) : null;
        const employmentType = Number(data.get("employmentType")) as EmploymentType;

        this._loading = true;

        try {
            const isNew = !this.posting.id;
            this.posting = await app.api.createOrUpdateJobPosting(
                new CreateOrUpdateJobPostingParams({
                    id: this.posting.id,
                    title,
                    start,
                    end,
                    description,
                    positions,
                    payType,
                    payAmount,
                    venueId,
                    hoursPerWeek,
                    employmentType,
                    images: this._images,
                })
            );
            this._editing = false;
            this.dispatchEvent(
                new CustomEvent(isNew ? "posting-created" : "posting-edited", { detail: { posting: this.posting } })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _deletePosting() {
        if (
            !(await confirm(
                "Sind Sie sicher, dass Sie diese Stellenausschreibung und alle Verbundenen Bewerbungen löschen möchten?",
                "Löschen",
                "Abbrechen",
                { type: "destructive", icon: "trash", title: "Ausschreibung Löschen" }
            ))
        ) {
            return;
        }

        this._loading = true;
        try {
            await app.api.deleteJobPosting(this.posting.id);
            this.dispatchEvent(new CustomEvent("posting-deleted"));
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _openApplication(application: JobApplication) {
        const action = (await this._jobApplicationDialog.show({ application, posting: this.posting }))?.action;

        if (action === "approve") {
            const employee = await this._newEmployeeDialog.show(
                new Employee({
                    firstName: application.firstName,
                    lastName: application.lastName,
                    avatar: application.headShot,
                    birthday: application.birthday || undefined,
                    address: application.address,
                    postalCode: application.postalCode,
                    city: application.city,
                    email: application.email,
                    phone: application.phone,
                    positions: this.posting.positions,
                    contracts: [
                        new Contract({
                            start: this.posting.start || toDateString(new Date()),
                            end: this.posting.end,
                            hoursPerWeek: this.posting.hoursPerWeek || (0 as Hours),
                            employmentType: this.posting.employmentType,
                            salaries: [
                                new Salary({
                                    type: this.posting.payType,
                                    amount: this.posting.payAmount,
                                    positionId: null,
                                }),
                            ],
                        }),
                    ],
                })
            );
            if (employee) {
                await this._deleteApplication(application);
                router.go(`employees/${employee.id}`);
            }
        } else if (action === "reject") {
            await this._deleteApplication(application);
            this.dispatchEvent(new CustomEvent("posting-edited", { detail: { posting: this.posting } }));
        }
    }

    private async _deleteApplication(application: JobApplication) {
        this._loading = true;
        try {
            await app.api.deleteJobApplication(application.id);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private _selectImage() {
        this._imageInput.value = "";
        this._imageInput.click();
    }

    private async _imageSelected() {
        const file = this._imageInput?.files?.[0];
        if (file) {
            if (file && file.size > 2e6) {
                void alert("Bilder dürfen nicht größer als 2MB sein!", { title: "Datei zu groß", type: "warning" });
                this._imageInput.value = "";
                return;
            }
            this._images.push(await readFileAsDataURL(file));
            this.requestUpdate();
        }
    }

    private async _removeImage(index: number) {
        this._images.splice(index, 1);
        this.requestUpdate();
    }

    static styles = [
        shared,
        DateInput.styles,
        css`
            :host {
                position: relative;
            }

            input.pay-amount {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                text-align: right;
            }

            .pay-type {
                border-left: none;
                border-radius: 0 var(--border-radius) var(--border-radius) 0;
            }

            .sidebar {
                width: 21em;
            }

            label {
                color: var(--color-primary);
            }

            .applications .grid {
                grid-template-columns: repeat(auto-fill, minmax(var(--grid-column-width, 16em), 1fr));
            }

            .images {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.5em;
            }

            .image-container {
                padding-bottom: 75%;
                position: relative;
            }

            .image-container img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                border-radius: 0.5em;
            }

            .image-container button {
                position: absolute;
                top: -1em;
                right: -1em;
                background: var(--color-bg) !important;
                opacity: 1 !important;
                padding: 0.2em;
                z-index: 1;
            }

            .image-container:not(:hover) button {
                display: none;
            }
        `,
    ];

    private _renderEditing() {
        return html`
            <form class="fullbleed vertical layout" @submit=${this._submit}>
                <div class="padded spacing horizontal layout stretch">
                    <div class="stretch vertical spacing layout">
                        <div class="vertical layout">
                            <input
                                placeholder="Ausschreibungs-Titel"
                                class="large slim"
                                name="title"
                                required
                                maxlength="100"
                            />
                        </div>
                        <div class="stretch vertical layout">
                            <label>Beschreibung</label>
                            <div class="relative stretch collapse">
                                <ptc-rich-text-editor class="fit-vertically"></ptc-rich-text-editor>
                            </div>
                        </div>
                        <div class="spacing evenly stretching horizontal layout">
                            <button class="primary">Speichern</button>
                            <button class="transparent" type="button" @click=${this.cancelEdit}>Abbrechen</button>
                        </div>
                    </div>
                    <div class="sidebar vertical layout">
                        <ptc-scroller class="stretch collapse">
                            <div class="padded">
                                <div class="field">
                                    <label>Standort</label>

                                    <select name="venueId">
                                        ${app.accessibleVenues.map(
                                            (venue) => html`
                                                <option .value=${venue.id.toString()}>${venue.name}</option>
                                            `
                                        )}
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Beschäftigungsverhältnis</label>

                                    <select name="employmentType">
                                        ${getEmploymentTypes().map(
                                            ({ type, label }) => html`
                                                <option .value=${type.toString()}>${label}</option>
                                            `
                                        )}
                                    </select>
                                </div>

                                <div class="field">
                                    <label>Positionen</label>

                                    <ptc-entity-multi-select
                                        .existing=${app.company?.venues.flatMap((v) =>
                                            v.departments.flatMap((d) => d.positions)
                                        ) || []}
                                        .getId=${(pos: Position) => pos.id}
                                        .getLabel=${(pos: Position) => pos.name}
                                        .getColor=${(pos: Position) => app.getPositionColor(pos)}
                                        .getIcon=${() => "arrows-down-to-people"}
                                        class="slim"
                                    ></ptc-entity-multi-select>
                                </div>

                                <div class="horizontal spacing evenly stretching layout">
                                    <div class="field">
                                        <label>Beginn</label>

                                        <ptc-date-input name="start" picker="popover"></ptc-date-input>
                                    </div>

                                    <div class="field">
                                        <label>Ende</label>

                                        <ptc-date-input name="end" picker="popover"></ptc-date-input>
                                    </div>
                                </div>

                                <div class="horizontal spacing layout">
                                    <div class="field">
                                        <label>St. / Woche</label>
                                        <input
                                            type="number"
                                            name="hoursPerWeek"
                                            min="0"
                                            max="70"
                                            step="0.001"
                                            class="text-right-aligning slim"
                                        />
                                    </div>

                                    <div class="field stretch">
                                        <label>Bezahlung</label>

                                        <div class="horizontal layout">
                                            <div class="right icon input stretch">
                                                <input
                                                    type="number"
                                                    name="payAmount"
                                                    min="0"
                                                    step="0.01"
                                                    .value=${this.posting.payAmount?.toFixed(2) || ""}
                                                    class="slim pay-amount"
                                                />
                                                <i class="euro-sign"></i>
                                            </div>

                                            <select name="payType" class="slim pay-type">
                                                <option value="hourly" ?selected=${this.posting.payType === "hourly"}>
                                                    pro Stunde
                                                </option>
                                                <option value="monthly" ?selected=${this.posting.payType === "monthly"}>
                                                    monatlich
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label>Bilder</label>
                                    <div class="images">
                                        ${this._images.map(
                                            (img, index) =>
                                                html` <div class="image-container">
                                                    <img src="${img}" />
                                                    <button
                                                        type="button"
                                                        class="small subtle icon"
                                                        @click=${() => this._removeImage(index)}
                                                    >
                                                        <i class="times"></i>
                                                    </button>
                                                </div>`
                                        )}

                                        <button
                                            type="button"
                                            class="subtle"
                                            @click=${this._selectImage}
                                            ?hidden=${this._images.length >= 4}
                                        >
                                            <i class="rectangle-history-circle-plus big"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ptc-scroller>
                    </div>
                </div>
            </form>

            <input
                type="file"
                style="position: absolute; opacity: 0; pointer-events: none;"
                @change=${this._imageSelected}
                accept="${MimeType.JPG},${MimeType.PNG}"
            />
        `;
    }

    private _renderDisplay() {
        return html`
            <div class="fullbleed vertical layout">
                <div class="padded horizontal center-aligning layout">
                    <div class="large bold padded stretch">${this.posting.title}</div>
                    <button class="transparent" @click=${this._deletePosting} ?hidden=${!this.posting.id}>
                        <i class="trash"></i>
                    </button>
                    <button class="transparent" @click=${() => this.edit()}>
                        <i class="pencil-alt"></i>
                    </button>
                </div>
                <ptc-scroller class="stretch">
                    <div class="padded spacing horizontal layout">
                        <div class="padded stretch">
                            <label>Beschreibung</label>
                            <ptc-rich-content
                                class="padded"
                                .content=${this.posting.description}
                                .previewHeight=${400}
                            ></ptc-rich-content>
                        </div>
                        <div class="sidebar">
                            <div class="padded sidebar-inner">
                                <label>Standort</label>

                                <div class="padded">${app.getVenue(this.posting.venueId)?.name}</div>

                                <label>Beschäftigungsverhältnis</label>

                                <div class="padded">${employmentTypeLabel(this.posting.employmentType)}</div>

                                <label>Positionen</label>

                                <div class="padded small pills stretch">
                                    ${this.posting.positions?.map(
                                        (position) => html`
                                            <div
                                                class="pill"
                                                style="--color-highlight: ${app.getPositionColor(position)}"
                                            >
                                                <i class="arrows-down-to-people"></i>
                                                ${position.name}
                                            </div>
                                        `
                                    )}
                                </div>

                                <div class="horizontal evenly stretching layout">
                                    <div>
                                        <label>Beginn</label>

                                        <div class="padded">
                                            ${this.posting.start ? formatDate(this.posting.start) : "Ab Sofort"}
                                        </div>
                                    </div>

                                    <div>
                                        <label>Ende</label>

                                        <div class="padded">
                                            ${this.posting.end ? formatDate(this.posting.end) : "Unbegrenzt"}
                                        </div>
                                    </div>
                                </div>

                                <div class="horizontal evenly stretching layout">
                                    <div>
                                        <label>St./Woche</label>

                                        <div class="padded">
                                            ${this.posting.hoursPerWeek
                                                ? formatNumber(this.posting.hoursPerWeek)
                                                : "Keine Angabe"}
                                        </div>
                                    </div>

                                    <div>
                                        <label>Bezahlung</label>

                                        <div class="padded">
                                            ${this.posting.payAmount
                                                ? `
                                                      ${formatNumber(this.posting.payAmount)} € /
                                                      ${this.posting.payType === "monthly" ? "Monat" : "Stunde"}
                                                  `
                                                : "Keine Angabe"}
                                        </div>
                                    </div>
                                </div>

                                ${this.posting.images.length
                                    ? html`
                                          <label>Bilder</label>

                                          <div class="images">
                                              ${this.posting.images.map(
                                                  (img) =>
                                                      html` <div class="image-container">
                                                          <img src="${img}" />
                                                      </div>`
                                              )}
                                          </div>
                                      `
                                    : ""}

                                <label>Bewerbungs-Link</label>

                                <div class="padded break-all">
                                    <a href="${process.env.PTC_JOBS_URL}/postings/${this.posting.uuid}" target="_blank">
                                        ${process.env.PTC_JOBS_URL}/postings/${this.posting.uuid}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="padded applications">
                        <label>Bewerbungen</label>

                        ${!this.posting.applications?.length
                            ? html`<div class="small double-padded subtle box" style="display: inline-block">
                                  Es liegen aktuell keine Bewerbungen vor.
                              </div>`
                            : ""}

                        <div class="margined spacing grid">
                            ${this.posting.applications?.map((application) => {
                                const age =
                                    application.birthday &&
                                    Math.floor(
                                        (Date.now() - parseDateString(application.birthday)!.getTime()) /
                                            (24 * 3600 * 365.25 * 1000)
                                    );
                                return html`
                                    <div
                                        class="double-padded box click"
                                        @click=${() => this._openApplication(application)}
                                    >
                                        <div class="horizontal start-aligning spacing layout">
                                            <ptc-avatar
                                                .employee=${new Employee({
                                                    avatar: application.headShot,
                                                    firstName: application.firstName,
                                                    lastName: application.lastName,
                                                })}
                                                class="large"
                                                .badge=${age ? { text: age.toString() } : undefined}
                                            ></ptc-avatar>
                                            <div class="stretch">
                                                <div class="bold">${application.firstName} ${application.lastName}</div>
                                                <div class="small">
                                                    Beworben am <strong>${formatDate(application.created)}</strong>
                                                </div>
                                                <div class="small">
                                                    Verfügbar ab
                                                    <strong>
                                                        ${application.availableFrom
                                                            ? formatDate(application.availableFrom)
                                                            : "sofort"}
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>

                                        ${application.message
                                            ? html`
                                                  <div class="top-margined small subtle">
                                                      <i class="comment"></i> ${truncate(application.message, 50)}
                                                  </div>
                                              `
                                            : ""}
                                    </div>
                                `;
                            })}
                        </div>
                    </div>
                </ptc-scroller>
            </div>
        `;
    }

    render() {
        if (!this.posting) {
            return;
        }

        return html`
            ${this._editing ? this._renderEditing() : this._renderDisplay()}

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
