import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";

@customElement("ptc-time-input")
export class TimeInput extends LitElement {
    readonly type = "time";

    @property()
    name: string;

    @property({ type: Boolean, reflect: true })
    disabled: boolean;

    @property({ reflect: true })
    icon: string;

    @property()
    min: string = "00:00";

    @property()
    max: string = "24:00";

    @property({ type: Boolean })
    required: boolean;

    @property({ type: Boolean })
    readonly: boolean;

    @property({ type: Number })
    hourDigits = 2;

    @property()
    get value() {
        return (this._hiddenInput && this._hiddenInput.value) || null;
    }
    set value(val: string | null) {
        const updateValues = () => {
            const normalized = (val && this._normalize(val.slice(0, 5))) || "";
            const inputValue = normalized ? normalized.slice(0, 5).slice(-3 - this.hourDigits) : "";
            this._hiddenInput.value = normalized;
            this._input.value = inputValue;
            this._hasChanged = false;
        };

        if (this._input && this._hiddenInput) {
            updateValues();
        } else if (this.updateComplete) {
            void this.updateComplete.then(() => {
                if (!this._input || !this._hiddenInput) return;
                updateValues();
            });
        }
    }

    focus() {
        this._input.focus();
    }

    blur() {
        this._input.blur();
    }

    setCustomValidity(val: string) {
        this._input.setCustomValidity(val);
    }

    @query("input[type='text']")
    private _input: HTMLInputElement;

    @query("input[type='hidden']")
    private _hiddenInput: HTMLInputElement;

    private _hasChanged = false;

    private _normalize(val: string) {
        val = val.replace(/[^\d]/g, "").slice(-4);

        if (!val) {
            return null;
        }

        const hours = Number(val.slice(0, -2));
        const minutes = Number(val.slice(-2));
        // hours += Math.floor(minutes / 60);
        // minutes = minutes % 60;
        return (hours < 24 && minutes < 60) || (hours === 24 && minutes === 0)
            ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`
            : null;
    }

    private _focus() {
        try {
            this._input.select();
        } catch (e) {
            this._input.setSelectionRange(0, this._input.value.length);
        }
    }

    private _blur() {
        const normalized = this._normalize(this._input.value) || "";
        this._input.value = normalized ? normalized.slice(0, 5).slice(-3 - this.hourDigits) : "";
        this._hiddenInput.value = normalized;
        this._validate();
        if (this._hasChanged) {
            this.dispatchEvent(new CustomEvent("change", { composed: true, bubbles: true }));
        }
        this._hasChanged = false;
    }

    private _oninput() {
        const val = this._input.value.replace(/[^\d]/g, "").slice(-2 - this.hourDigits);
        this._input.value = val
            ? val.slice(0, -2).padStart(this.hourDigits, "–") + ":" + val.slice(-2).padStart(2, "–")
            : "";
        this._hiddenInput.value = this._normalize(this._input.value) || "";
        this._validate();
        this._hasChanged = true;
    }

    private _validate() {
        if (this._input.value && this._input.value > this.max) {
            this.setCustomValidity(`Bitte geben Sie einen Wert kleiner oder gleich ${this.max} ein!`);
        } else if (this._input.value && this._input.value < this.min) {
            this.setCustomValidity(`Bitte geben Sie einen Wert größer oder gleich ${this.min} ein!`);
        } else {
            this.setCustomValidity("");
        }
    }

    static styles = css`
        ptc-time-input {
            position: relative;
            display: inline-block;
            min-width: 5em;
        }

        ptc-time-input input {
            width: 100%;
            text-align: center;
            font-variant-numeric: tabular-nums lining-nums;
            letter-spacing: 0.05em;
        }

        ptc-time-input.slim input {
            padding: 0.5em;
        }

        ptc-time-input[icon] input {
            padding-left: 2em;
        }

        ptc-time-input i {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0.5em;
            margin: auto 0;
            opacity: 0.9;
            font-size: 90%;
            transform: translate(0, 1px);
        }
    `;

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            ${this.icon ? html` <i class="${this.icon}"></i> ` : ""}

            <input
                type="text"
                inputmode="numeric"
                placeholder="${new Array(this.hourDigits).fill("–").join("")}:––"
                @focus=${this._focus}
                @blur=${this._blur}
                @input=${this._oninput}
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                autocomplete="nah"
            />

            <input type="hidden" .name=${this.name}></input>
        `;
    }
}
