import "./date-range-picker";
import { getRange, formatDate, formatNumber } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
// import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import { alert, confirm } from "./alert-dialog";
import "./scroller";
import { singleton } from "../lib/singleton";
import { GetRevenueEntriesParams, UpdateRevenueEntriesParams } from "@pentacode/core/src/api";
import { PayAdvanceDialog } from "./pay-advance-dialog";
import { RevenueEntry, RevenueType } from "@pentacode/core/src/model";
import { DateRange } from "packages/core/src/time";

@customElement("ptc-employees-advances-single")
export class EmployeesAdvancesSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/advances/;

    @routeProperty({ arg: "id", type: Number })
    employeeId: number;

    defaultRange = () => getRange(new Date(), "month");
    rangeChanged = () => this._load();

    @singleton("ptc-pay-advance-dialog")
    private _payAdvanceDialog: PayAdvanceDialog;

    get routeTitle() {
        return `Vorschüsse: ${this._employee && this._employee.name}`;
    }

    @state()
    private _loading = false;

    @state()
    private _entries: RevenueEntry[] = [];

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        this._loading = true;

        try {
            this._entries = (
                await app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        ...this.dateRange,
                        type: [RevenueType.PayAdvance],
                        employee: this._employee.id,
                    })
                )
            ).filter((e) => !e.draft);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newAdvance() {
        const created = await this._payAdvanceDialog.show(new RevenueEntry({ employeeId: this._employee!.id }));
        if (created) {
            // update revenue entries in the background
            void this._load();
        }
    }

    private async _deleteAdvance(entry: RevenueEntry) {
        if (entry.cashbook) {
            if (
                await confirm(
                    "Das Löschen von Barvorschüssen ist nur über das Kassenbuch möglich.",
                    "Kassenbuch Öffnen",
                    "Abbrechen",
                    {
                        title: "Vorschuss Löschen",
                    }
                )
            ) {
                this.go("revenues/cashbook", { date: entry.date });
            }
            return;
        }

        const confirmed = await confirm(
            "Sind Sie sicher, dass Sie diesen Vorschuss löschen möchten?",
            "Löschen",
            "Abbrechen",
            {
                title: "Vorschuss Löschen",
                type: "destructive",
            }
        );

        if (!confirmed) {
            return;
        }

        this._loading = true;

        try {
            await app.api.updateRevenueEntries(
                new UpdateRevenueEntriesParams({
                    removed: [entry],
                })
            );

            // Reload the data in the background
            void this._load();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [shared, css``];

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }

        return html`
            <div class="fullbleed vertical layout">
                <div class="padded center-aligning horizontal layout border-bottom">
                    <ptc-date-range-picker
                        @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                        .range=${this.dateRange}
                    ></ptc-date-range-picker>
                    <div class="stretch"></div>
                    <button class="slim transparent" @click=${this._newAdvance}><i class="plus"></i></button>
                </div>
                <ptc-scroller class="stretch">
                    <div class="double-padded" style="max-width: 50em; margin: 0 auto;">
                        <table class="fill-horizontally rounded">
                            <thead>
                                <tr>
                                    <th scope="col">Datum</th>
                                    <th scope="col">Zahlungsmittel</th>
                                    <th scope="col">Betrag (€)</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this._entries.map(
                                    (entry) => html`
                                        <tr>
                                            <td>${formatDate(entry.date)}</td>
                                            <td>${entry.cashbook ? "Bar" : "Überweisung"}</td>
                                            <td>${formatNumber(Math.abs(entry.amount))}</td>
                                            <td style="width: 0;">
                                                <button
                                                    class="skinny transparent"
                                                    @click=${() => this._deleteAdvance(entry)}
                                                >
                                                    <i class="trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    `
                                )}
                            </tbody>
                        </table>
                    </div>
                </ptc-scroller>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
