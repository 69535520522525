import { LitElement, html, css } from "lit";
import { customElement, property, state, query, queryAll } from "lit/decorators.js";
import { RevenueGroup, RevenueType, revenueTypeIcon, taxKeyLabel } from "@pentacode/core/src/model";
import { GetRevenueGroupsParams, UpdateRevenueGroupParams } from "@pentacode/core/src/api";
import { formatDate, formatNumber } from "@pentacode/core/src/util";
import { clone } from "@pentacode/core/src/encoding";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert, confirm } from "./alert-dialog";
import "./scroller";
import "./spinner";
import { Dialog } from "./dialog";
import { RevenuesGroupForm } from "./revenues-group-form";
import { ifDefined } from "lit/directives/if-defined.js";
import { isSafari } from "../lib/util";

@customElement("ptc-revenues-groups-dragelement")
export class DragElement extends LitElement {
    @property({ attribute: false })
    group: RevenueGroup;

    static styles = [
        shared,
        css`
            :host {
                display: block;
                max-width: 20em;
                border-radius: var(--border-radius);
                background: var(--color-bg);
                position: absolute;
                z-index: -1;
            }

            .name {
                margin: 0.5em 0.8em;
                font-weight: bold;
            }

            .pills {
                margin: 0.5em 0.5em 0 0.5em;
            }
        `,
    ];

    render() {
        if (!this.group) {
            return;
        }

        const { name, ledger, costCenter, taxKey, cashbook, reporting } = this.group;

        return html`
            <div class="name">${name}</div>
            <div class="small pills">
                ${ledger ? html` <div class="pill"><strong>Konto: </strong> ${ledger}</div> ` : ""}
                ${taxKey ? html` <div class="pill"><strong>St: </strong> ${taxKeyLabel(taxKey)}</div> ` : ""}
                ${costCenter ? html` <div class="pill"><strong>KoSt: </strong> ${costCenter}</div> ` : ""}
                ${reporting ? html` <div class="pill"><i class="chart-pie"></i></div> ` : ""}
                ${cashbook ? html` <div class="pill"><i class="coins"></i></div> ` : ""}
            </div>
        `;
    }
}

@customElement("ptc-revenues-groups")
export class RevenuesGroups extends Routing(StateMixin(LitElement)) {
    routePattern = /revenues\/groups(?:\/(?<type>\w+))?/;

    get routeTitle() {
        return this.venue ? `Umsatzgruppen: ${this.venue.name}` : undefined;
    }

    @routeProperty({ arg: "type" })
    private _type: RevenueType;

    @state()
    private _loading = false;

    @state()
    private _groups: RevenueGroup[] = [];

    @state()
    private _selectedGroup: RevenueGroup | null = null;

    @state()
    private _draggingGroup: RevenueGroup | null = null;

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#formDialog")
    private _formDialog: Dialog<void, void>;

    // @query(".add-group-button")
    // private _groupsContainer: HTMLButtonElement;

    @query(".groups-container")
    private _groupsContainer: HTMLDivElement;

    @queryAll(".group")
    private _groupElements: NodeListOf<HTMLDivElement>;

    @query("#groupForm")
    private _groupForm: RevenuesGroupForm;

    @query(".dragelement")
    private _dragElement: HTMLDivElement;

    @query(".add-button-container")
    private _addButtonContainer: HTMLButtonElement;

    async handleRoute() {
        if (![RevenueType.Sales, RevenueType.Expense, RevenueType.Cashless].includes(this._type)) {
            this.go("revenues/groups/sales", this.router.params, true);
            return;
        }

        await this._load();
    }

    private async _load() {
        if (this._loading || !this.venue || !this._type) {
            return;
        }

        this._loading = true;

        try {
            this._groups = await app.api.getRevenueGroups(
                new GetRevenueGroupsParams({
                    venue: this.venueId,
                    type: [this._type],
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _filterByString(e: RevenueGroup, filter: string) {
        return e.name.toLowerCase().includes(filter) || e.ledger.includes(filter) || e.costCenter.includes(filter);
    }

    private _editGroup(e: RevenueGroup, changes: Partial<RevenueGroup> = {}) {
        this._selectedGroup = e;
        this._groupForm.group = Object.assign(clone(e), changes);
        void this._formDialog.show();
    }

    private async _submitGroupForm({ detail: { group } }: CustomEvent<{ group: RevenueGroup }>) {
        const existing = this._selectedGroup!;
        this._selectedGroup = null;
        this._formDialog.hide();

        if (
            !existing.count ||
            (await confirm(
                `Wollen Sie diese Änderung wirklich an ${existing.count} ` +
                    `Einträgen vornehmen (festgeschriebene Einträge bleiben unverändert)?`
            ))
        ) {
            void this._updateGroup(group, undefined, true);
        }
    }

    private _cancelGroupForm() {
        this._selectedGroup = null;
        this._formDialog.hide();
    }

    private async _dragstart(e: DragEvent, group: RevenueGroup) {
        // const dragEl = document.createElement("ptc-revenues-groups-dragelement") as DragElement;
        // dragEl.group = group;
        // this.renderRoot!.appendChild(dragEl);
        this._groupsContainer.classList.toggle("reordering", !!group.id);
        this._draggingGroup = group;
        await this.updateComplete;
        e.dataTransfer!.setData("text/plain", "42");
        e.dataTransfer!.setDragImage(this._dragElement, 50, 50);
    }

    private async _dragend(e: DragEvent) {
        e.preventDefault();

        for (const el of [this._addButtonContainer, this._groupsContainer, ...this._groupElements]) {
            el.classList.remove("dragover");
        }

        this._groupsContainer.classList.remove("reordering");
    }

    private _groupsDragenter(e: DragEvent) {
        if (!this._draggingGroup || !!this._draggingGroup.id) {
            return;
        }
        e.preventDefault();
        this._groupsContainer.classList.add("dragover");
        for (const el of this._groupElements) {
            el.classList.remove("dragover");
        }
    }

    private _groupsDragover(e: DragEvent) {
        if (!this._draggingGroup || !!this._draggingGroup.id) {
            return;
        }
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = "copy";
        }
    }

    private _groupsDragleave() {
        this._groupsContainer.classList.remove("dragover");
    }

    private async _groupsDrop(e: DragEvent) {
        e.preventDefault();
        if (this._draggingGroup && !this._draggingGroup.id) {
            await this._updateGroup(this._draggingGroup, this._draggingGroup);
        }
        this._draggingGroup = null;
    }

    private _groupDragenter(e: DragEvent, group: RevenueGroup | null) {
        if (!this._draggingGroup || (!this._draggingGroup.id && group === null)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        for (const el of this._groupElements) {
            el.classList.toggle(
                "dragover",
                !!group && group.id !== this._draggingGroup.id && el.dataset.group === group.id.toString()
            );
        }
        this._addButtonContainer.classList.toggle("dragover", group === null);
    }

    private _groupDragover(e: DragEvent, group: RevenueGroup | null) {
        e.preventDefault();

        if (!group || !this._draggingGroup || this._draggingGroup.id === group.id) {
            return;
        }

        e.stopPropagation();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = "link";
        }
    }

    private _groupDragleave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    private async _groupDrop(e: DragEvent, group: RevenueGroup | null) {
        if (
            !this._draggingGroup ||
            (!this._draggingGroup.id && group === null) ||
            (group && this._draggingGroup.id === group.id)
        ) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (!this._draggingGroup.id) {
            if (
                await confirm(
                    `Möchten Sie diese ${this._draggingGroup.count} Einträge zur Gruppe ${group!.name} hinzufügen? ` +
                        `Text, Konto, Steuersatz und Kostenstelle werden dann an diese Gruppe angepasst ` +
                        `(festgeschriebene Einträge bleiben unverändert).`,
                    "Hinzufügen",
                    "Abbrechen",
                    {
                        title: "Einträge Hinzufügen",
                        icon: "question-circle",
                    }
                )
            ) {
                await this._updateGroup(group!, this._draggingGroup);
            }
        } else {
            const oldIndex = this._groups.indexOf(this._draggingGroup);
            this._groups.splice(oldIndex, 1);
            const newIndex = group === null ? this._groups.findIndex((g) => !g.id) : this._groups.indexOf(group);
            this._groups.splice(newIndex, 0, this._draggingGroup);
            this.requestUpdate();
            void app.api.updateRevenueGroup(
                new UpdateRevenueGroupParams({ order: this._groups.filter((g) => !!g.id).map((g) => g.id) })
            );
        }

        this._draggingGroup = null;
    }

    private async _updateGroup(group: RevenueGroup, merge?: RevenueGroup, updateEntries = false) {
        this._loading = true;

        if (!group.id) {
            group.order = Math.max(...this._groups.map((g) => g.order || 0)) + 1;
        }

        try {
            await app.api.updateRevenueGroup(new UpdateRevenueGroupParams({ group, merge, updateEntries }));
            this._loading = false;
            void this._load();
        } catch (e) {
            void alert(e.message, { type: "warning" });
            this._loading = false;
        }
    }

    private async _deleteGroup(group: RevenueGroup) {
        this._formDialog.hide();

        if (
            !(await confirm(
                "Sind Sie sicher dass Sie diese Umsatzgruppe löschen möchten? Die zugehörigen Einträge bleiben erhalten.",
                "Gruppe Löschen",
                "Abbrechen",
                { title: "Löschen", type: "destructive" }
            ))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.api.deleteRevenueGroup(group.id);
            this._loading = false;
            void this._load();
        } catch (e) {
            void alert(e.message, { type: "warning" });
            this._loading = false;
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .main {
                flex: 1;
                min-height: 0;
            }

            .groups-container {
                width: 23em;
            }

            .groups {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
                grid-gap: 1em;
                margin: 1em;
            }

            .group {
                border-radius: calc(2 * var(--border-radius));
                border: solid 1px var(--shade-2);
                background: var(--color-bg);
                position: relative;
            }

            .group.dragover {
                background: var(--blue-bg);
            }

            .groups-container.reordering .group.dragover::before,
            .groups-container.reordering .add-button-container.dragover::before {
                content: "";
                display: block;
                width: 100%;
                background: var(--color-primary);
                border-radius: var(--border-radius);
                height: 6px;
                position: absolute;
                top: -0.8em;
            }

            .groups-container.dragover .add-group-button {
                background: var(--blue-bg);
                pointer-events: none;
            }

            .group-name {
                font-size: var(--font-size-medium);
                margin: 0.5em 0.8em;
                font-weight: bold;
            }

            .group .pills {
                margin: 0.8em 0.8em 0.4em 0.8em;
            }

            .add-button-container {
                position: relative;
            }

            .add-group-button {
                border-radius: calc(2 * var(--border-radius));
                border: dashed 1px var(--shade-2);
                background: transparent;
                min-height: 6em;
                width: 100%;
            }

            .add-button-hint {
                font-size: var(--font-size-tiny);
                color: var(--shade-5);
                margin-top: 0.5em;
            }

            .suggestions {
                flex: 1;
                min-height: 0;
                overflow: auto;
                font-size: var(--font-size-small);
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .row {
                display: grid;
                grid-template-columns: 1fr 6em 7em 4em 5em 5em 4em 8em 3em 3em;
                border-bottom: solid 1px var(--shade-1);
                min-width: 56em;
                cursor: grab;
            }

            .row > * {
                padding: 0.5em;
            }

            .row:not(.header-row):hover > * {
                background: #f1f1f1 !important;
            }

            .row:not(.header-row) > :not(:last-child) {
                border-right: dashed 1px var(--shade-1);
            }

            .row > .name {
                font-weight: 600;
                position: sticky;
                left: 0;
                z-index: 2;
                background: var(--color-bg);
            }

            .row > .tax,
            .row > .date,
            .row > .reporting,
            .row > .cashbook {
                text-align: center;
            }

            .row > .count,
            .row > .amount {
                text-align: right;
            }

            .row.header-row > * {
                text-align: center;
                font-weight: bold;
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 3;
                background: var(--color-bg);
            }

            .group-form {
                margin: 1em;
            }

            .form-dialog {
                --dialog-max-width: 35em;
            }

            .form-dialog h1 {
                margin: 0.7em 0 0 0;
                text-align: center;
            }

            .form-dialog .delete-button {
                position: absolute;
                right: 1em;
                top: 1.5em;
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                ptc-scroller {
                    height: auto;
                }
            }
        `,
    ];

    render() {
        if (!this.venue) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        return html`
            <div class="header horizontal center-aligning layout padded-medium noprint">
                <div class="horizontal tabs">
                    ${[RevenueType.Sales, RevenueType.Expense, RevenueType.Cashless].map(
                        (type) => html`
                            <button ?active=${this._type === type} @click=${() => this.go(`revenues/groups/${type}`)}>
                                <i class="${ifDefined(revenueTypeIcon(type))}"></i>
                            </button>
                        `
                    )}
                </div>

                <div class="stretch"></div>

                <div class="small right icon input noprint">
                    <input id="filterInput" placeholder="Suchen..." @input=${this._updateFilter} />
                    <i class="${this._filterString ? "times click" : "search"} icon" @click=${this._clearFilter}></i>
                </div>
            </div>

            <div class="horizontal layout main">
                <ptc-scroller
                    class="groups-container border-right"
                    @dragover=${(evt: DragEvent) => this._groupsDragover(evt)}
                    @dragenter=${(evt: DragEvent) => this._groupsDragenter(evt)}
                    @dragleave=${() => this._groupsDragleave()}
                    @drop=${(evt: DragEvent) => this._groupsDrop(evt)}
                >
                    <div class="groups">
                        ${this._groups
                            .filter((g) => !!g.id)
                            .map(
                                (group) => html`
                                    <div
                                        class="group click"
                                        data-group="${group.id}"
                                        draggable="true"
                                        @dragstart=${(evt: DragEvent) => this._dragstart(evt, group)}
                                        @dragend=${(evt: DragEvent) => this._dragend(evt)}
                                        @dragover=${(evt: DragEvent) => this._groupDragover(evt, group)}
                                        @dragenter=${(evt: DragEvent) => this._groupDragenter(evt, group)}
                                        @dragleave=${(evt: DragEvent) => this._groupDragleave(evt)}
                                        @drop=${(evt: DragEvent) => this._groupDrop(evt, group)}
                                        @click=${() => this._editGroup(group)}
                                    >
                                        <div class="group-name">
                                            <i class="${ifDefined(revenueTypeIcon(group.type))}"></i> ${group.name}
                                        </div>
                                        <div class="small pills">
                                            ${group.ledger
                                                ? html` <div class="pill"><strong>Knt: </strong> ${group.ledger}</div> `
                                                : ""}
                                            ${group.taxKey
                                                ? html`
                                                      <div class="pill">
                                                          <strong>St: </strong> ${taxKeyLabel(group.taxKey)}
                                                      </div>
                                                  `
                                                : ""}
                                            ${group.costCenter
                                                ? html`
                                                      <div class="pill">
                                                          <strong>KoSt: </strong> ${group.costCenter}
                                                      </div>
                                                  `
                                                : ""}
                                            ${group.reporting
                                                ? html` <div class="pill"><i class="chart-pie"></i></div> `
                                                : ""}
                                            ${group.cashbook
                                                ? html` <div class="pill"><i class="coins"></i></div> `
                                                : ""}
                                            ${group.daily
                                                ? html` <div class="pill"><i class="cash-register"></i></div> `
                                                : ""}
                                            ${group.lastUsed
                                                ? html`
                                                      <div class="pill">
                                                          <i class="history"></i>
                                                          ${formatDate(group.lastUsed)}
                                                      </div>
                                                  `
                                                : ""}
                                            ${group.postingKey
                                                ? html`
                                                      <div class="pill"><i class="key"></i> ${group.postingKey}</div>
                                                  `
                                                : ""}

                                            <div class="pill">
                                                <i class="tally"></i>
                                                ${group.count}
                                            </div>

                                            <div class="pill">
                                                <i class="euro-sign"></i>
                                                ${formatNumber(Math.abs(group.amount))}
                                            </div>
                                        </div>
                                    </div>
                                `
                            )}

                        <div
                            class="add-button-container"
                            @dragover=${(evt: DragEvent) => this._groupDragover(evt, null)}
                            @dragenter=${(evt: DragEvent) => this._groupDragenter(evt, null)}
                            @dragleave=${(evt: DragEvent) => this._groupDragleave(evt)}
                            @drop=${(evt: DragEvent) => this._groupDrop(evt, null)}
                        >
                            <button
                                class="add-group-button"
                                @click=${() =>
                                    this._editGroup(new RevenueGroup({ type: this._type, venueId: this.venueId }))}
                            >
                                <i class="plus"></i> Neue Umsatzgruppe

                                <div class="add-button-hint">Klicken oder Einträge hineinziehen</div>
                            </button>
                        </div>
                    </div>
                </ptc-scroller>

                <div class="suggestions scroller stretch">
                    <div class="row header-row ${this._type}">
                        <div class="name">Text</div>
                        <div class="date">Zul. Verw.</div>
                        <div class="tax">Steuer</div>
                        <div class="postingkey">BU</div>
                        <div class="ledger">Konto</div>
                        <div class="costcenter">KoSt.</div>
                        <div class="attribution" hidden>Zuordnung</div>
                        <div class="count">Anz.</div>
                        <div class="amount">Betrag</div>
                        <div class="reporting" title="Berichte">
                            <i class="chart-pie"></i>
                        </div>
                        <div class="cashbook" title="Kassenbuch">
                            <i class="coins"></i>
                        </div>
                    </div>

                    ${this._groups
                        .filter((g) => !g.id && this._filterByString(g, this._filterString))
                        .map(
                            (g) => html`
                                <div
                                    class="row ${this._type}"
                                    draggable="true"
                                    @dragstart=${(evt: DragEvent) => this._dragstart(evt, g)}
                                    @dragend=${(evt: DragEvent) => this._dragend(evt)}
                                >
                                    <div class="name">${g.name}</div>
                                    <div class="date">${g.lastUsed && formatDate(g.lastUsed)}</div>
                                    <div class="tax">${taxKeyLabel(g.taxKey)}</div>
                                    <div class="postingkey">${g.postingKey}</div>
                                    <div class="ledger">${g.ledger}</div>
                                    <div class="costcenter">${g.costCenter}</div>
                                    <div class="count">${formatNumber(g.count || 0, 0)}</div>
                                    <div class="amount">${formatNumber(Math.abs(g.amount), 2)} €</div>
                                    <div class="reporting">${g.reporting ? html` <i class="check"></i> ` : ""}</div>
                                    <div class="cashbook">${g.cashbook ? html` <i class="check"></i> ` : ""}</div>
                                </div>
                            `
                        )}
                </div>
            </div>

            <ptc-dialog id="formDialog" class="form-dialog" prevent-dismiss>
                <button
                    class="transparent icon delete-button"
                    @click=${() => this._deleteGroup(this._selectedGroup!)}
                    ?hidden=${!this._selectedGroup || !this._selectedGroup.id}
                >
                    <i class="trash"></i>
                </button>
                <h1>
                    ${this._selectedGroup && this._selectedGroup.id ? "Umsatzgruppe Bearbeiten" : "Neue Umsatzgruppe"}
                </h1>
                <ptc-revenues-group-form
                    id="groupForm"
                    class="group-form"
                    @submit=${this._submitGroupForm}
                    @cancel=${this._cancelGroupForm}
                ></ptc-revenues-group-form>
            </ptc-dialog>

            <ptc-revenues-groups-dragelement
                class="dragelement"
                .group=${this._draggingGroup}
            ></ptc-revenues-groups-dragelement>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
