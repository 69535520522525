import "./popover";
import { LitElement, html, css, TemplateResult, PropertyValues } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { EntityFilter, Role } from "@pentacode/core/src/model";
import { app } from "../init";
import { colors, shared } from "../styles";
import { Checkbox } from "./checkbox";
import "./scroller";
import { confirm } from "./alert-dialog";
import { EntityFiltersEl } from "./entity-filters";
import { popover } from "../directives/popover";
import { PERMISSIONS, Permission, PermissionInfo } from "@pentacode/core/src/permissions";

@customElement("ptc-permissions-form")
export class PermissionsForm extends LitElement {
    @property({ type: Array })
    permissions: Permission[];

    @property({ attribute: false })
    filters: EntityFilter[];

    @property({ type: Number })
    accessRole: Role;

    @property({ type: Boolean })
    isNew = false;

    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-entity-filters[name='filters']")
    private _filtersInput: EntityFiltersEl;

    @query("select[name='role']")
    private _roleSelect: HTMLSelectElement;

    @state()
    private _page: "manage" | "staff" = "staff";

    get data(): { permissions: Permission[]; role: Role; filters: EntityFilter[] } {
        if (!this._form) {
            return { permissions: [], role: Role.Worker, filters: [] };
        }
        const formData = new FormData(this._form);
        return {
            permissions: [...new Set<Permission>(formData.getAll("permissions") as Permission[])],
            role: Number(formData.get("role") as string),
            filters: this._filtersInput.filters,
        };
    }

    get hasChanged() {
        const { permissions, role, filters } = this.data;
        return (
            role !== this.accessRole ||
            permissions.length !== this.permissions.length ||
            permissions.some((p) => !this.permissions.includes(p as Permission)) ||
            filters.length !== this.filters.length ||
            filters.some((filter) => !this.filters.some((f) => f.type === filter.type && f.value === filter.value))
        );
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        this.requestUpdate("permissions");
    }

    updated(changes: Map<string, unknown>) {
        let hasRelevantChanges = false;
        if (changes.has("accessRole")) {
            this._roleSelect.value = this.accessRole.toString();
            this._page = this.accessRole > Role.Manager ? "staff" : "manage";
            hasRelevantChanges = true;
        }

        if (changes.has("permissions")) {
            const permsEls = this.renderRoot.querySelectorAll(
                `ptc-checkbox-button[name="permissions"]`
            ) as NodeListOf<any>;
            for (const el of permsEls) {
                const shouldBeChecked = this.accessRole <= Role.Owner || this.permissions.includes(el.value);

                if (shouldBeChecked !== el.checked) {
                    el.checked = shouldBeChecked;
                    hasRelevantChanges = true;
                }
            }
        }

        if (changes.has("filters")) {
            this._filtersInput.filters = this.accessRole === Role.Owner ? [] : this.filters;
            hasRelevantChanges = true;
        }

        if (hasRelevantChanges) {
            this.requestUpdate();
        }
    }

    private _permissionChanged(e: Event) {
        const input = e.target as Checkbox;
        const key = input.value;
        // const checked = this.data.permissions.includes(key);
        const checked = input.checked;

        const els = this.renderRoot.querySelectorAll(
            `ptc-checkbox-button[name="permissions"][value^="${key}"]`
        ) as NodeListOf<any>;
        [...els].forEach((el) => (el.checked = checked));

        if (!checked) {
            for (const perm of PERMISSIONS.children.filter(
                (p: PermissionInfo) => p.requires && p.requires.includes(key)
            )) {
                const els = this.renderRoot.querySelectorAll(
                    `ptc-checkbox-button[name="permissions"][value^="${perm.key}"]`
                ) as NodeListOf<any>;
                [...els].forEach((el) => (el.checked = false));
            }
        }

        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    private async _roleChanged(e: Event) {
        const input = e.target as HTMLSelectElement;
        const role = Number(input.value) as Role;

        if (
            role === Role.Owner &&
            !(await confirm(
                "Wollen Sie diesen Mitarbeiter wirklich zum Besitzer dieses Unternehmens machen? Der aktuelle Besitzer wird dann zum Manager degradiert.",
                "Bestätigen",
                "Abbrechen",
                { title: "Besitzer Wechseln", icon: "user-crown" }
            ))
        ) {
            input.value = this.accessRole?.toString() || "";
            return;
        }

        this.requestUpdate();
        await this.updateComplete;

        for (const permission of PERMISSIONS.children.filter(
            (p: PermissionInfo) => p.requiresRole && p.requiresRole < role
        )) {
            const els = this.renderRoot.querySelectorAll(
                `ptc-checkbox-button[name="permissions"][value^="${permission.key}"]`
            ) as NodeListOf<HTMLInputElement>;
            els.forEach((el) => (el.checked = false));
        }

        this._page = role > Role.Manager ? "staff" : "manage";

        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: block;
                position: relative;
            }
        `,
    ];

    private _renderPermission({
        key,
        requires,
        title,
        description,
        children,
        color,
        feature,
        grid,
        readonly,
        hide,
        icon,
    }: PermissionInfo): TemplateResult {
        const perms = this.data.permissions;
        const features = (app.company && app.company.features) || [];

        const label = html`${icon ? html`<i class="${icon}"></i> ` : ""}${title}
        ${description
            ? html`<i
                  class="info-circle"
                  ${popover(html` <div class="small" style="max-width: 20em;">${description}</div> `, {
                      trigger: "hover",
                      class: "non-interactive",
                      preferAlignment: ["right"],
                      style: "z-index: 101;",
                  })}
              ></i>`
            : ""}`;

        return html`
            <div
                class="${!hide ? "check-group" : ""}"
                ?hidden=${feature && !features.includes(feature)}
                ?disabled=${readonly || (requires && requires.some((key) => !perms.includes(key as Permission)))}
                style="--color-highlight: ${colors[color!] || color || "inherit"}"
            >
                ${key
                    ? html`
                          <ptc-checkbox-button
                              data-testid=${`permissions-button-${key}`}
                              .testId=${`permissions-checkbox-${key}`}
                              buttonClass="transparent slim"
                              name="permissions"
                              .value=${key}
                              .label=${label}
                              @input=${this._permissionChanged}
                              data-permission=${key}
                              ?hidden=${hide}
                              ?disabled=${this.accessRole <= Role.Owner}
                          ></ptc-checkbox-button>
                      `
                    : html` <div class="title">${label}</div> `}
                ${children && children.length
                    ? html`
                          <div
                              class="${grid ? "grid" : ""} children"
                              ?hidden=${!!key && !perms.includes(key as Permission)}
                              style="margin-top: 0"
                          >
                              ${children.map((child) => this._renderPermission(child))}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    render() {
        const role = this.data.role;
        return html`
            <form id="permissionsForm">
                <div class="fullbleed vertical layout">
                    <div class="padded horizontal spacing layout border-bottom">
                        <div
                            class="small box bold select-wrapper ${role === Role.Owner
                                ? "purple"
                                : role === Role.Manager
                                  ? "orange"
                                  : "grey"}"
                        >
                            <i
                                class="${role === Role.Owner
                                    ? "user-crown"
                                    : role === Role.Manager
                                      ? "user-tie"
                                      : "user-hard-hat"}"
                                style="margin: 0 -0.4em 0 0.4em"
                            ></i>
                            <select
                                name="role"
                                class="slim plain transparent"
                                @change=${(e: Event) => this._roleChanged(e)}
                            >
                                <option .value=${Role.Worker.toString()} ?disabled=${this.accessRole === Role.Owner}>
                                    Mitarbeiter
                                </option>
                                <option .value=${Role.Manager.toString()} ?disabled=${this.accessRole === Role.Owner}>
                                    Manager
                                </option>
                                <option
                                    .value=${Role.Owner.toString()}
                                    ?disabled=${(app.profile?.role !== Role.Owner && !app.account?.admin) || this.isNew}
                                >
                                    Besitzer
                                </option>
                            </select>
                            <i class="caret-down"></i>
                        </div>

                        <div class="stretch"></div>

                        <button class="slim transparent" type="button" ?hidden=${this.isNew}>
                            <i class="ellipsis-h"></i>
                        </button>

                        <ptc-popover>
                            <button
                                type="button"
                                class="transparent"
                                ?disabled=${this.accessRole <= Role.Owner}
                                @click=${() => this.dispatchEvent(new CustomEvent("revoke-access"))}
                            >
                                <i class="ban"></i> Zugang Entziehen
                            </button>
                        </ptc-popover>

                        <div class="horizontal tabs">
                            <button
                                type="button"
                                ?active=${this._page === "staff"}
                                @click=${() => (this._page = "staff")}
                            >
                                <i class="mobile"></i> Mitarbeiterzugang
                            </button>
                            <button
                                type="button"
                                ?active=${this._page === "manage"}
                                @click=${() => (this._page = "manage")}
                                ?disabled=${this.data.role > Role.Manager}
                            >
                                <i class="user-tie"></i>
                                Verwaltung
                            </button>
                        </div>
                    </div>

                    <div class="small relative stretch">
                        <ptc-scroller class="fullbleed" ?hidden=${this._page !== "staff"}>
                            <div class="small half-padded margined semibold">
                                <i class="list-check"></i> Berechtigungen
                            </div>
                            ${PERMISSIONS.children
                                .filter(
                                    (scope: PermissionInfo) => !scope.requiresRole || scope.requiresRole < Role.Manager
                                )
                                .map((scope) => this._renderPermission(scope))}
                        </ptc-scroller>

                        <ptc-scroller class="fullbleed" ?hidden=${this._page !== "manage"}>
                            <div class="padded border-bottom">
                                <div class="small half-padded semibold">
                                    <i class="person-harassing"></i> Zuständigkeitsbereiche
                                </div>
                                <ptc-entity-filters
                                    name="filters"
                                    @change=${() => this.dispatchEvent(new CustomEvent("change"))}
                                    ?disabled=${role <= Role.Owner}
                                    hideFilterIcon
                                    hideEmployeeCount
                                    .filterTypes=${["venue", "department", "position"] as const}
                                ></ptc-entity-filters>
                            </div>
                            <div class="small half-padded margined semibold">
                                <i class="list-check"></i> Berechtigungen
                            </div>
                            ${PERMISSIONS.children
                                .filter(
                                    (scope: PermissionInfo) => scope.requiresRole && scope.requiresRole >= Role.Manager
                                )
                                .map((perm) => this._renderPermission(perm))}
                        </ptc-scroller>
                    </div>
                </div>
            </form>
        `;
    }
}
