import {
    BonusesBalance,
    Employee,
    EmployeeStatus,
    EntityFilter,
    TimeBalance,
    VacationBalance,
} from "@pentacode/core/src/model";
import { getRange, toDateString, parseDateString, formatNumber, dateAdd, formatRange } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import "./employees-filter";
import { Balance } from "./balance";
import { GetBalancesParams } from "@pentacode/core/src/api";
import { DateRange } from "@pentacode/core/src/time";
import { DateString } from "@pentacode/openapi";
import "./avatar";
import "./spinner";
import { EmployeeSortProperty } from "@pentacode/core";

@customElement("ptc-employees-ledgers-all-year")
export class EmployeesLedgersAllYear extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/ledgers-year(?:\/(?<page>\w+))?/;

    get routeTitle() {
        switch (this._page) {
            case "time":
                return "AZ-Konten: Alle Mitarbeiter";
            case "vacation":
                return "Urlaubs-Konten: Alle Mitarbeiter";
            case "bonuses":
                return "SFN-Konten: Alle Mitarbeiter";
        }
    }

    private get _year() {
        const range = getRange(this.date, "year");
        const currMonth = getRange(toDateString(new Date()), "month");

        // Only display balances up to the current month
        if (range.to > currMonth.to) {
            range.to = currMonth.to;
        }

        return range;
    }

    private get _months() {
        let { from: monthStart, to: yearEnd } = this._year;
        const months: DateRange[] = [];

        do {
            months.push(getRange(monthStart, "month"));
            monthStart = dateAdd(monthStart, { months: 1 });
        } while (monthStart < yearEnd);

        return months;
    }

    @routeProperty({ arg: "page" })
    private _page: "time" | "vacation" | "bonuses";

    @state()
    private _loading = false;

    @state()
    private _timeBalances: TimeBalance[] = [];

    @state()
    private _vacationBalances: VacationBalance[] = [];

    @state()
    private _bonusesBalances: BonusesBalance[] = [];

    handleRoute() {
        if (!["time", "vacation", "bonuses"].includes(this._page)) {
            this.redirect("employees/all/ledgers-year/time");
            return;
        }
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("date")) {
            // update in the background
            void this.load();
        }
    }

    async load() {
        if (!this._year) {
            return;
        }

        const { from, to } = this._year;

        this._loading = true;

        try {
            const filters = app.accessibleEmployees
                .filter((e) => e.status !== EmployeeStatus.Probation && !!e.getContractForRange({ from, to }))
                .map((e) => ({ type: "employeeId", value: e.id }) as EntityFilter);
            const params = new GetBalancesParams({
                filters,
                from,
                to,
                resolution: "month",
            });
            const [timeBalances, vacationBalances, bonusesBalances] = await Promise.all([
                await app.api.getTimeBalances(params),
                await app.api.getVacationBalances(params),
                await app.api.getBonusesBalances(params),
            ]);
            this._timeBalances = timeBalances;
            this._vacationBalances = vacationBalances;
            this._bonusesBalances = bonusesBalances;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _yearSelected(e: Event) {
        const date = parseDateString(this.date) || new Date();
        date.setFullYear(parseInt((e.target as HTMLSelectElement).value));
        this.go(null, { date: toDateString(date) });
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .year-selector {
                font-size: var(--font-size-medium);
                padding-top: 0.3em;
                padding-bottom: 0.3em;
            }

            .scroller {
                overflow: auto;
                flex: 1;
                min-height: 0;
            }

            .scroller-inner {
                min-width: 1100px;
            }

            .row {
                display: flex;
                break-inside: avoid;
            }

            .row > * {
                border-right: solid 1px var(--shade-2);
                border-bottom: solid 1px var(--shade-2);
                flex: 1;
                min-width: 0;
                box-sizing: border-box;
                background: var(--color-bg);
            }

            .row-month {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .row-month-value {
                font-size: var(--font-size-small);
                padding: 0.3em;
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .row-month-value:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .row-month-value-balance {
                font-weight: bold;
            }

            .row-month-reset {
                font-weight: bold;
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 9;
            }

            .header-row .row-month {
                font-weight: 600;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 0.5em;
            }

            .row-header {
                position: sticky;
                left: 0;
                z-index: 8;
                background: var(--color-bg);
                width: 12em;
                flex: none;
            }

            .employee-header {
                padding: 0.5em;
            }

            .employee-header ptc-avatar {
                margin-right: 0.5em;
                margin-top: 0.2em;
            }

            .employee-name {
                margin-bottom: 0.2em;
            }

            .row-balance {
                position: sticky;
                right: 0;
                z-index: 8;
                border-left: solid 1px var(--shade-2);
                border-right: none;
                width: 6em;
                flex: none;
            }

            .row-labels {
                width: 2.5em;
                flex: none;
                position: sticky;
                left: 12em;
                z-index: 8;
            }

            .row-carry {
                border-top: solid 1px var(--shade-2);
                border-left: solid 1px var(--shade-2);
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                .scroller-inner {
                    min-width: 1050px;
                }

                .row-header {
                    width: 9em;
                }

                .header-row,
                .row-header,
                .row-labels,
                .row-balance {
                    position: static;
                }

                .pill {
                    background: none;
                    color: var(--color-fg);
                }
            }
        `,
    ];

    private _renderEmployee(
        employee: Employee,
        months: {
            from: DateString;
            to: DateString;
            time?: TimeBalance;
            vacation?: VacationBalance;
            bonuses?: BonusesBalance;
        }[]
    ) {
        const balances = months.map(({ from, to }) => ({
            from,
            to,
            time: this._timeBalances.find((s) => s.employeeId === employee.id && s.from === from && s.to === to),
            vacation: this._vacationBalances.find(
                (s) => s.employeeId === employee.id && s.from === from && s.to === to
            ),
            bonuses: this._bonusesBalances.find((s) => s.employeeId === employee.id && s.from === from && s.to === to),
        }));

        const hasReset = balances.some((s) => !!s.time?.reset || s.vacation?.reset || s.bonuses?.reset);
        const janBalances = balances.find((s) => s.from.endsWith("01-01"));
        const lastBalances = balances[balances.length - 1];

        const v = this._page;

        return html`
            <div class="row">
                <div
                    class="row-header vertical layout click"
                    @click=${() => this.go(`employees/${employee.id}/ledgers`)}
                >
                    <div class="employee-header horizontal start-aligning layout">
                        <ptc-avatar class="small noprint" .employee=${employee}></ptc-avatar>
                        <div class="stretch collapse">
                            <div class="employee-name">${employee.lastName}, ${employee.firstName}</div>
                            ${employee.staffNumber
                                ? html`
                                      <div class="tiny pills">
                                          <div class="pill">Pnr: <strong>${employee.staffNumber}</strong></div>
                                      </div>
                                  `
                                : ""}
                        </div>
                    </div>
                    ${v !== "bonuses" && janBalances && janBalances[v] && typeof janBalances[v]?.carry === "number"
                        ? html`
                              <div class="stretch"></div>
                              <div class="horizontal end-justifying layout">
                                  <div class="row-carry row-month-value horizontal layout">
                                      <div>${new Date(this.date).getFullYear() - 1} &nbsp;</div>
                                      <ptc-balance .value=${janBalances[v]?.carry || 0}></ptc-balance>
                                  </div>
                              </div>
                          `
                        : ""}
                </div>

                <div class="row-month row-labels">
                    ${hasReset ? html` <div class="row-month-value">Übtr</div> ` : ""}
                    <div class="row-month-value">${v === "time" ? "Soll" : v === "vacation" ? "Zuw" : "Pau"}</div>
                    <div class="row-month-value">${v === "time" ? "Ist" : v === "vacation" ? "Gen" : "B.fr."}</div>
                    ${v === "bonuses" ? html`<div class="row-month-value">B.pf.</div>` : ""}
                    <div class="row-month-value">Diff</div>
                    <div class="row-month-value row-month-value-balance">Sld</div>
                </div>

                ${balances.map(({ from, to, time, vacation, bonuses }) => {
                    const balance = v === "time" ? time : v === "vacation" ? vacation : bonuses;
                    const contracts = employee.getAllContractsForRange({ from, to }) || [];
                    const sfnLedgerEnabled = contracts.some((c) => c.enableSFNLedger);
                    if (!balance) {
                        return html`<div class="row-month"></div>`;
                    }
                    const resetValue = balance?.reset;
                    return html`
                        <div
                            class="row-month click"
                            @click=${() =>
                                this.go(`employees/${employee.id}/time`, {
                                    date_from: balance.from,
                                    date_to: balance.to,
                                })}
                        >
                            ${typeof resetValue === "number"
                                ? html`
                                      <div class="row-month-value">
                                          <ptc-balance .value=${resetValue}></ptc-balance>
                                      </div>
                                  `
                                : ""}
                            <div class="row-month-value">
                                ${v === "bonuses" && !sfnLedgerEnabled ? "N/A" : formatNumber(balance.nominal!)}
                            </div>
                            <div class="row-month-value">${formatNumber(balance.actual)}</div>
                            ${v === "bonuses"
                                ? html`
                                      <div class="row-month-value">
                                          ${balance.actual === (balance as BonusesBalance).untaxed
                                              ? `(${formatNumber((balance as BonusesBalance).taxed)})`
                                              : formatNumber((balance as BonusesBalance).taxed)}
                                      </div>
                                  `
                                : ""}
                            ${typeof balance.balance === "number" && typeof balance.difference === "number"
                                ? html`
                                      <div class="row-month-value">
                                          <ptc-balance .value=${balance.difference}></ptc-balance>
                                      </div>
                                      <div class="row-month-value row-month-value-balance">
                                          <ptc-balance .value=${balance.balance}></ptc-balance>
                                      </div>
                                  `
                                : html`
                                      <div class="row-month-value">N/A</div>
                                      <div class="row-month-value row-month-value-balance">N/A</div>
                                  `}
                        </div>
                    `;
                })}

                <div class="row-month row-month-reset row-balance">
                    <div class="row-month-value">
                        ${typeof lastBalances[v]?.balance === "number"
                            ? html` <ptc-balance .value=${lastBalances[v]!.balance!}></ptc-balance> `
                            : "N/A"}
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        if (!app.company || !this._year || !this._page) {
            return html``;
        }

        const months = this._months;

        const years: number[] = [];
        const currYear = new Date().getFullYear();
        for (let year = currYear; year > 2010; year--) {
            years.push(year);
        }

        const view = this._page;

        return html`
            <div class="header spacing horizontal center-aligning layout padded-medium noprint">
                <select class="year-selector" @change=${this._yearSelected}>
                    ${years.map(
                        (year) => html`
                            <option .value=${year.toString()} ?selected=${year === currYear}>${year}</option>
                        `
                    )}
                </select>

                <div class="stretch"></div>

                <div class="tabs">
                    <button @click=${() => this.go("employees/all/ledgers-month")}>Monat</button>
                    <button active>Jahr</button>
                </div>

                <div class="small padded faded">•</div>

                <div class="tabs">
                    <button ?active=${view === "time"} @click=${() => this.go("employees/all/ledgers-year/time")}>
                        Stunden
                    </button>
                    <button
                        ?active=${view === "vacation"}
                        @click=${() => this.go("employees/all/ledgers-year/vacation")}
                    >
                        Urlaub
                    </button>
                    <button ?active=${view === "bonuses"} @click=${() => this.go("employees/all/ledgers-year/bonuses")}>
                        SFN
                    </button>
                </div>

                <div class="stretch"></div>

                <button class="transparent slim" title="Konten als PDF-Datei Exportieren" @click=${() => print()}>
                    <i class="print"></i>
                </button>
            </div>

            <ptc-employees-filter
                class="noprint border-bottom"
                .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
            ></ptc-employees-filter>

            <div class="header horizontal center-aligning layout padded small-caps bottom-margined printonly">
                <div>
                    <strong
                        >${view === "time"
                            ? "Arbeitszeitkonten"
                            : view === "vacation"
                              ? "Urlaubskonten"
                              : "SFN-Konten"}</strong
                    >
                    Jahresübersicht
                    <strong>${this._year}</strong>
                </div>
                <div class="stretch"></div>
                <div class="subtle">${app.company!.name}</div>
            </div>

            <div class="scroller">
                <div class="scroller-inner">
                    <div class="row header-row">
                        <div class="row-header horizontal end-aligning layout"></div>

                        <div class="row-month row-labels"></div>

                        ${months.map(
                            (month) => html`
                                <div
                                    class="row-month click"
                                    @click=${() =>
                                        this.go("employees/all/ledgers-month", {
                                            date: month.from,
                                        })}
                                >
                                    <div>${formatRange(month)}</div>
                                </div>
                            `
                        )}

                        <div class="row-month row-month-reset row-balance">
                            <div>Saldo</div>
                        </div>
                    </div>

                    ${app
                        .getFilteredEmployees(getRange(this.date, "year"))
                        .map((emp) => this._renderEmployee(emp, months))}
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
