import {
    Document,
    DocumentTag,
    fileExtension,
    fileIcon,
    Form,
    formStatusColor,
    formStatusLabel,
    MimeType,
} from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import { alert, confirm } from "./alert-dialog";
import "./scroller";
import { DocumentDialog } from "./document-dialog";
import { EditDocumentDialog } from "./edit-document-dialog";
import { formatDate, formatMonth, getRange, toDateString } from "@pentacode/core/src/util";
import { request } from "../lib/ajax";
import { downloadFromUrl, readFileAsDataURL, selectFile } from "../lib/util";
import "./spinner";
import { getDefaultForms } from "@pentacode/core/src/forms";
import "./popover";

@customElement("ptc-employees-documents-single")
export class EmployeesDocumentsSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<employeeId>\d+)\/documents/;

    @routeProperty({ arg: "employeeId", type: Number })
    employeeId: number;

    @routeProperty({ param: "openDoc", type: Number })
    private _openDoc: number;

    get routeTitle() {
        return `Dokumente: ${this._employee && this._employee.name}`;
    }

    async handleRoute() {
        await this._load();

        if (this._openDoc) {
            const document = this._documents.find((d) => d.id === this._openDoc);
            if (document) {
                await this._openDocument(document);
            }
            this.go(null, { openDoc: undefined }, true);
        }
    }

    @state()
    private _loading = false;

    @state()
    private _documents: Document[] = [];

    @state()
    private _months: {
        date: string;
        documents: Document[];
    }[];

    @state()
    private _selectedTag: DocumentTag | null = null;

    @singleton("ptc-document-dialog")
    private _documentDialog: DocumentDialog;

    @singleton("ptc-edit-document-dialog")
    private _editDocumentDialog: EditDocumentDialog;

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        this._loading = true;

        try {
            this._documents = await app.api.getDocuments({ employeeId: this.employeeId });

            this._documents.sort((a, b) => (a.date < b.date ? -1 : 1));

            const months: { date: string; documents: Document[] }[] = [];

            for (const document of this._documents) {
                const date = getRange(document.date, "month").from;
                if (date === months[0]?.date) {
                    months[0].documents.push(document);
                } else {
                    months.unshift({ date, documents: [document] });
                }
            }

            this._months = months;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newDocument({ file, form }: { file?: File; form?: Form } = {}) {
        const document = new Document({
            employeeId: this.employeeId,
            date: toDateString(new Date()),
            tags: this._selectedTag ? [this._selectedTag] : [],
            form,
            name: form?.name || file?.name || "",
            type: form ? MimeType.PDF : undefined,
        });

        const created = await this._editDocumentDialog.show({ file, document });
        if (created) {
            this._load();
            app.fetchCompany();

            this._openDocument(created);
        }
    }

    private async _openDocument(document: Document) {
        const edited = await this._documentDialog.show(document);
        if (edited) {
            await this._load();

            // update in the background
            void app.fetchCompany();
        }
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();

        if (e.dataTransfer?.files.length) {
            e.dataTransfer.dropEffect = "copy";
        }
    }

    private _drop(e: DragEvent) {
        e.preventDefault();

        const file = e.dataTransfer?.files[0];

        if (file) {
            void this._newDocument({ file });
        }
    }

    private async _edit(document: Document) {
        const edited = await this._editDocumentDialog.show({ document });
        if (edited) {
            void this._load();
        }
    }

    private async _delete(document: Document) {
        const confirmed = await confirm(
            "Sind Sie sicher dass Sie dieses Dokument löschen wollen?",
            "Löschen",
            "Abbrechen",
            { title: "Dokument Löschen", type: "destructive" }
        );

        if (!confirmed) {
            return;
        }

        this._loading = true;

        try {
            await app.api.deleteDocument(document.id);
            await this._load();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _download(document: Document) {
        if (!document.url) {
            return;
        }
        const req = await request(document.url, { responseType: "blob" });
        const data = await readFileAsDataURL(req.response);
        await downloadFromUrl(data, `${document.name}.${fileExtension(document.type)}`);
    }

    static styles = [
        shared,
        css`
            .document-row:not(:hover) .document-actions {
                display: none;
            }
        `,
    ];

    private _renderNewDocumentOptions() {
        return html`
            <ptc-popover class="popover-menu" hide-on-click>
                ${getDefaultForms(app.company!).map(
                    (form) => html`
                        <button type="button" class="small" @click=${() => this._newDocument({ form })}>
                            <i class="pen-field"></i> ${form.name}
                        </button>
                    `
                )}

                <button
                    type="button"
                    class="small"
                    @click=${async () => {
                        const file = await selectFile();
                        if (file) {
                            await this._newDocument({ file });
                        }
                    }}
                >
                    <i class="folder-tree"></i> Datei Wählen...
                </button>
            </ptc-popover>
        `;
    }

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }

        return html`
            <div class="fullbleed vertical layout" @drop=${this._drop} @dragover=${this._dragover}>
                <div class="padded horizontal layout border-bottom">
                    <div class="small right icon input noprint">
                        <input id="filterInput" placeholder="Suchen..." @input=${this._updateFilter} />
                        <i
                            class="${this._filterString ? "times click" : "search"} icon"
                            @click=${this._clearFilter}
                        ></i>
                    </div>
                    <div class="stretch"></div>
                    <button class="large skinny transparent">
                        <i class="file-circle-plus"></i>
                    </button>
                    ${this._renderNewDocumentOptions()}
                </div>

                <div class="small padded spacing wrapping horizontal layout border-bottom">
                    <button
                        class="slim ${!this._selectedTag ? "primary" : "transparent"}"
                        @click=${() => (this._selectedTag = null)}
                    >
                        <i class="tags"></i> Alle
                        <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                            ${this._documents.length}
                        </span>
                    </button>
                    ${app.company?.documentTags?.map(
                        (tag) => html`
                            <button
                                class="slim ${this._selectedTag?.id === tag.id ? "primary" : "transparent"}"
                                @click=${() => (this._selectedTag = tag)}
                                style="--color-highlight: ${tag.color}"
                            >
                                <i class="tag"></i> ${tag.name}
                                <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                                    ${this._documents.filter((doc) => doc.tags.some((t) => t.name === tag.name)).length}
                                </span>
                            </button>
                        `
                    )}
                    <div class="stretch"></div>
                    <button
                        class="subtle"
                        @click=${() => this.go("settings/documents")}
                        ?disabled=${!app.hasPermission("manage.settings.documents")}
                    >
                        <i class="pencil-alt"></i> Kategorien Bearbeiten
                    </button>
                </div>

                ${!this._documents.length
                    ? html`
                          <div class="stretch centering vertical layout">
                              <i class="giant light faded files"></i>
                              <div class="margined">Dieser Mitarbeiter hat noch keine Dokumente.</div>
                              <button class="subtle top-margined">
                                  <div class="center-aligning horizontal layout" style="width: 11.5em">
                                      <i class="huge thin file-circle-plus"></i>
                                      <div class="tiny stretch left-margined text-left-aligning">
                                          Datei wählen oder per <strong>Drag & Drop</strong> hier ablegen.
                                      </div>
                                  </div>
                              </button>
                              ${this._renderNewDocumentOptions()}
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch">
                              ${this._months.map(({ date, documents }) => {
                                  documents = documents.filter(
                                      (doc) =>
                                          (!this._selectedTag ||
                                              doc.tags.some((t) => t.id === this._selectedTag?.id)) &&
                                          (doc.name.toLowerCase().includes(this._filterString.toLowerCase()) ||
                                              doc.tags.some((tag) =>
                                                  tag.name.toLowerCase().includes(this._filterString.toLowerCase())
                                              ))
                                  );
                                  return !documents.length
                                      ? null
                                      : html`
                                            <div class="small padded sticky border-bottom subtle semibold">
                                                ${formatMonth(date)}
                                            </div>
                                            ${documents.map(
                                                (document) => html`
                                                    <div
                                                        class="padded click horizontal center-aligning layout border-bottom document-row"
                                                        style="padding-left: 0;"
                                                        @click=${() => this._openDocument(document)}
                                                    >
                                                        <i class="massive light ${fileIcon(document.type)}"></i>
                                                        <div class="stretch collapse">
                                                            <div class="small semibold ellipsis bottom-margined">
                                                                ${document.name}
                                                            </div>
                                                            <div class="tiny horizontal spacing layout">
                                                                <div class="pill">
                                                                    <i class="calendar"></i> ${formatDate(
                                                                        document.date,
                                                                        false
                                                                    )}
                                                                </div>
                                                                ${document.tags.map(
                                                                    (tag) => html`
                                                                        <div
                                                                            class="pill ellipsis"
                                                                            style="max-width: 100%; --color-highlight: ${tag.color}"
                                                                        >
                                                                            <i class="tag"></i> ${tag.name}
                                                                        </div>
                                                                    `
                                                                )}
                                                                ${document.form
                                                                    ? html`<div
                                                                          class="pill"
                                                                          style="--color-highlight: ${formStatusColor(
                                                                              document.form.status
                                                                          )}"
                                                                      >
                                                                          <i class="pen-field"></i> ${formStatusLabel(
                                                                              document.form.status
                                                                          )}
                                                                      </div>`
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="horizontal layout document-actions"
                                                            @click=${(e: MouseEvent) => e.stopPropagation()}
                                                        >
                                                            <button
                                                                class="slim transparent"
                                                                @click=${() => this._download(document)}
                                                                ?disabled=${!document.url}
                                                            >
                                                                <i class="download"></i>
                                                            </button>
                                                            <button
                                                                class="slim transparent"
                                                                @click=${() => this._edit(document)}
                                                                ?hidden=${!app.hasPermission(
                                                                    "manage.employees.documents"
                                                                )}
                                                            >
                                                                <i class="pencil-alt"></i>
                                                            </button>
                                                            <button
                                                                class="slim transparent"
                                                                @click=${() => this._delete(document)}
                                                                ?hidden=${!app.hasPermission(
                                                                    "manage.employees.documents"
                                                                )}
                                                            >
                                                                <i class="trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                `
                                            )}
                                        `;
                              })}
                          </ptc-scroller>
                      `}
            </div>

            <div class="fullbleed center-aligning end-justifying vertical layout non-interactive">
                <div class="padded double-margined subtle box">
                    <strong><i class="lightbulb-on"></i> Tipp:</strong> Sie können Dateien einfach per Drag & Drop in
                    dieses Fenster ziehen um ein neues Dokument zu erstellen.
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
