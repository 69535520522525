import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared } from "../styles";
import type { ColumnOption } from "@pentacode/core";
import { columnConfigs } from "@pentacode/core";
import { Checkbox } from "./checkbox";
import "./popover";
import "./scroller";

export type ColumnSelectorChangedEvent = CustomEvent<{ columns: ColumnOption[] }>;

@customElement("ptc-column-selector")
export class ColumnSelector extends LitElement {
    @property({ type: Array })
    columns: ColumnOption[] = [];

    @query("#columnsForm")
    private _columnsForm: HTMLFormElement;

    public get displayColumns(): ColumnOption[] {
        const enabledTypes = new FormData(this._columnsForm).getAll("columns");
        return this.columns.map((column) => ({ ...column, disabled: !enabledTypes.includes(column.type) }));
    }

    private _handleChange() {
        this.dispatchEvent(
            new CustomEvent<{ columns: ColumnOption[] }>("changed", {
                detail: { columns: this.displayColumns },
            })
        );
    }

    private _selectAll() {
        this._columnsForm.querySelectorAll("input").forEach((input) => {
            (input as HTMLInputElement).checked = true;
        });
        this._handleChange();
    }

    private _selectNone() {
        this._columnsForm.querySelectorAll("input").forEach((input) => {
            (input as HTMLInputElement).checked = false;
        });
        this._handleChange();
    }

    static styles = [shared, Checkbox.styles];

    render() {
        return html`
            <button class="slim transparent" title="Spalten wählen">
                <i class="toggle-on large"></i>
            </button>

            <ptc-popover style="min-width: 10em">
                <form class="small scroller" id="columnsForm" @change=${this._handleChange}>
                    <div style="display: flex; justify-content: space-evenly;">
                        <button type="button" @click=${this._selectNone} class="transparent slim">
                            <i class="toggle-off"></i> Keine
                        </button>
                        <button type="button" @click=${this._selectAll} class="transparent slim">
                            <i class="toggle-on"></i> Alle
                        </button>
                    </div>

                    <ptc-scroller style="max-height: 40em">
                        ${this.columns.map(({ type, disabled }) => {
                            if (columnConfigs[type].disabled) {
                                return html``;
                            }
                            const checked = !disabled;
                            return html`
                                <ptc-checkbox-button
                                    .label=${columnConfigs[type].title}
                                    name="columns"
                                    .value=${type}
                                    ?checked=${checked}
                                    buttonClass="transparent slim"
                                ></ptc-checkbox-button>
                            `;
                        })}
                    </ptc-scroller>
                </form>
            </ptc-popover>
        `;
    }
}
