import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { Popover } from "./popover";
import "./color-picker";
import { colors } from "../styles";

export { defaultColors } from "./color-picker";

@customElement("ptc-color-input")
export class ColorInput extends LitElement {
    @property()
    get value() {
        return this._input && this._input.value;
    }
    set value(val: string) {
        if (this._input) {
            this._input.value = val;
        } else if (this.updateComplete) {
            void this.updateComplete.then(() => {
                if (!this._input) return;
                this._input.value = val;
            });
        }
        this.style.setProperty("--color-highlight", colors[val] || val || "initial");
    }

    @property()
    name: string;

    @property({ type: Boolean })
    required: boolean;

    @query("input")
    _input: HTMLInputElement;

    @query("ptc-popover")
    _popover: Popover;

    createRenderRoot() {
        return this;
    }

    private _valueSelected(value: string) {
        this.value = value;
        this._popover.hide();
    }

    static styles = css`
        ptc-color-input {
            display: flex;
        }

        ptc-color-input > input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        ptc-color-input > button {
            width: 2em;
            height: 1em;
            border-radius: 0.5em;
            background: var(
                --color-highlight,
                linear-gradient(to top right, white 0%, white 47%, black 48%, black 52%, white 53%, white 100%)
            );
            border: solid 1px var(--shade-2);
        }
    `;

    render() {
        return html`
            <input .name=${this.name || ""} ?required=${this.required} tabindex="-1" />
            <button type="button"></button>
            <ptc-popover>
                <ptc-color-picker
                    .value=${this.value}
                    @change=${(e: CustomEvent<{ value: string }>) => this._valueSelected(e.detail.value)}
                ></ptc-color-picker>
            </ptc-popover>
        `;
    }
}
