import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { formatDate } from "@pentacode/core/src/util";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./employees-filter";
import { Checkbox } from "./checkbox";
import "./popover";
import "./spinner";
import "./avatar";
import {
    columnConfigs,
    ColumnOption,
    EmployeeSortProperty,
    serializeColumOptions,
    serializeFilters,
} from "@pentacode/core";
import type { ColumnSelectorChangedEvent } from "./column-selector";
import "./column-selector";

@customElement("ptc-employees-profile-all")
export class EmployeesProfileAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/core_data/;

    get routeTitle() {
        return "Stammdaten: Alle Mitarbeiter";
    }

    private static _columns: ColumnOption[] = [
        { type: "lastName" },
        { type: "firstName" },
        { type: "callName" },
        { type: "staffNumber" },
        { type: "birthday" },
        { type: "address" },
        { type: "postalCode" },
        { type: "city" },
        { type: "email" },
        { type: "phone" },
        { type: "phone2" },
        { type: "timePin" },
    ];

    @state()
    private _loading: boolean = false;

    @state()
    private _displayColumns: ColumnOption[] = EmployeesProfileAll._columns;

    private get _employees() {
        return app.getFilteredEmployees();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            .row {
                display: flex;
                page-break-inside: avoid;
            }

            .row:not(.header):hover {
                cursor: pointer;
                color: var(--color-primary);
            }

            .row.header {
                font-weight: bold;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            .row > * {
                width: 10em;
                padding: 0.5em;
                flex: none;
                word-break: break-word;
                border-bottom: solid 1px var(--shade-2);
                background: var(--color-bg);
            }

            .row :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .row > .lastName {
                position: sticky;
                left: 0;
                z-index: 1;
            }

            .row > .firstName {
                width: 7em;
            }

            .row > .callName {
                width: 5em;
            }

            .row > .staffNumber {
                width: 5em;
            }

            .row > .timePin {
                width: 5em;
            }

            .row > .postalCode {
                width: 5em;
            }

            .row > .birthday {
                width: 6em;
            }

            .row > .phone,
            .row > .phone2 {
                width: 8em;
            }

            .row > .email {
                width: 15em;
            }

            .row > .address {
                width: 15em;
            }

            .row > .avatar {
                width: 2em;
                border: none;
                padding: 0.3em;
            }

            @media print {
                .row {
                    font-size: var(--font-size-tiny);
                }

                .wrapper {
                    position: static;
                    display: block;
                }
            }
        `,
    ];

    render() {
        const columns = this._displayColumns.filter((column) => !column.disabled);
        return html`
            <div class="fullbleed vertical layout wrapper">
                <div class="padded spacing center-aligning horizontal layout border-bottom noprint">
                    <div class="stretch"></div>

                    <ptc-column-selector
                        .columns=${this._displayColumns}
                        @changed=${(e: ColumnSelectorChangedEvent) => {
                            this._displayColumns = e.detail.columns;
                        }}
                    ></ptc-column-selector>

                    <button
                        class="slim transparent"
                        title="Stammdaten als Excel-Datei Exportieren"
                        @click="${() => {
                            this.go("exports/employee_table_excel", {
                                filters: serializeFilters(app.employeeFilters.filters),
                                columns: serializeColumOptions(this._displayColumns),
                            });
                        }}"
                    >
                        <i class="file-excel"></i>
                    </button>
                    <button class="slim transparent" title="Stammdaten als PDF-Datei Exportieren" @click="${print}">
                        <i class="print"></i>
                    </button>
                </div>

                <div class="padded horizontal layout small-caps bottom-margined printonly">
                    <div class="stretch">Personal-Stammdaten (Stand ${formatDate(new Date())})</div>
                    <div class="subtle">${app.company?.name}</div>
                </div>

                <ptc-employees-filter
                    class="border-bottom"
                    .sortableProps=${[
                        "firstName",
                        "lastName",
                        "staffNumber",
                        "birthday",
                        "birthdayDate",
                        "email",
                    ] as EmployeeSortProperty[]}
                ></ptc-employees-filter>

                <div class="scroller stretch">
                    <div class="header row">
                        <div class="avatar noprint"></div>
                        ${columns.map(({ type }) => html`<div class="${type}">${columnConfigs[type].title}</div>`)}
                    </div>

                    ${this._employees.map((employee) => {
                        return html`
                            <div class="employee row" @click=${() => this.go(`employees/${employee.id}/core_data`)}>
                                <div class="avatar left-padded centering layout noprint">
                                    <ptc-avatar class="tiny" .employee=${employee}></ptc-avatar>
                                </div>
                                ${columns.map(
                                    ({ type }) =>
                                        html`<div class="${type}">${columnConfigs[type].getValue({ employee })}</div>`
                                )}
                            </div>
                        `;
                    })}
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
