import { LitElement, html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
    deserializeColumOptions,
    employeeDisplayProperties,
    filterForPermissions,
    hasAnyRequiredPermissions,
    DateRange,
    EmployeeSortDirection,
    EmployeeSortProperty,
    deserializeFilters,
    ExportFormat,
    ExportParams,
    ExportResponse,
    dateAdd,
    formatMonthShort,
    getRange,
    toDateString,
} from "@pentacode/core";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty, RouteArgs } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { DateInput } from "./date-input";
import { Checkbox } from "./checkbox";
import { EntityFiltersEl } from "./entity-filters";
import type { Scroller } from "./scroller";
import { DocumentTagsInput } from "./document-tags-input";
import { DateRangePicker } from "./date-range-picker";
import { ColumnSelector } from "./column-selector";
import { alert } from "./alert-dialog";
import "./exports-menu";
import "./exports-headers";
import "./exports-result";
import "./spinner";
import "./scroller";
import "./popover";

@customElement("ptc-exports")
export class Exports extends Routing(StateMixin(LitElement)) {
    static pages = Object.values(ExportFormat);

    routePattern = /^exports(?:\/(?<page>\w+))?/;

    defaultRange = () => {
        return getRange(new Date(), this._page === "balances_excel" ? "year" : "month");
    };

    @state()
    private _loading: boolean = false;

    @state()
    private _exportResponse: ExportResponse | null = null;

    @routeProperty({ arg: "page" })
    private _page: ExportFormat;

    @query("ptc-entity-filters")
    private _filtersEl: EntityFiltersEl;

    @query("ptc-column-selector")
    private _columnSelector: ColumnSelector;

    @query("ptc-document-tags-input")
    private _tagsInput: DocumentTagsInput;

    @query("ptc-scroller")
    private _scroller: Scroller;

    @query("ptc-date-range-picker")
    private _dateRangePicker: DateRangePicker;

    @query("form")
    private _exportForm: HTMLFormElement;

    private get _exportFormData(): Partial<ExportParams> {
        const data = this._exportForm && new FormData(this._exportForm);

        if (!data) {
            return {};
        }
        return {
            //FIXME: This is unsafe usage of the API, as it is expecting defined 'from' and 'to' values
            ...this._dateRangePicker?.range,
            resolution: (data.get("resolution") || undefined) as ExportParams["resolution"],
            filters: this._filtersEl?.filters || [],
            columns:
                this._page === ExportFormat.EmployeeTableExcel
                    ? this._columnSelector?.displayColumns.filter((c) => !c.disabled) || []
                    : [],
            sendAsEmail: data.get("sendAsEmail") ? [data.get("sendAsEmail") as string] : undefined,
            saveToDocuments: data.has("saveToDocuments"),
            requireEmployeeSignature: data.has("requireEmployeeSignature"),
            venueId: data.get("venueId") ? Number(data.get("venueId") as string) : undefined,
            tags: this._tagsInput?.tags,
            sortProperty: data.get("sortProperty") as EmployeeSortProperty,
            sortDirection: data.get("sortDirection") as EmployeeSortDirection,
        };
    }

    async handleRoute(_args: RouteArgs, params: { filters: string; columns: string }) {
        if (!Exports.pages.includes(this._page) || !this._hasPermission(this._page)) {
            const page = Exports.pages.find((p) => this._hasPermission(p));
            this.go(`exports/${page}`, undefined, true);
            return;
        }

        this._exportResponse = null;

        this.requestUpdate();
        await this.updateComplete;

        let filters = params.filters && deserializeFilters(params.filters);

        if (this._filtersEl && filters) {
            if (this._page.startsWith("payroll") || this._page.startsWith("time")) {
                filters = filters.filter((f) => f.type !== "employeeStatus");
            }
            this._filtersEl.filters = filters;
            this.go(null, { ...this.router.params, filters: undefined }, true);
            return;
        }

        // Set to all columns by default, enable only the ones that were passed as enabled in route
        const columns = params.columns && deserializeColumOptions(params.columns);
        if (this._columnSelector && columns) {
            const enabledColumnTypes = new Set(columns.filter((c) => !c.disabled).map((c) => c.type));
            const newColumns = employeeDisplayProperties.map((type) => ({
                type,
                disabled: !enabledColumnTypes.has(type),
            }));
            const filteredColumns = filterForPermissions((permission) => app.hasPermission(permission), newColumns);
            this._columnSelector.columns = filteredColumns;

            this.go(null, { ...this.router.params, columns: undefined }, true);
            return;
        }

        if (this._columnSelector && !this._columnSelector.columns.length) {
            const allColumns = employeeDisplayProperties.map((type) => ({ type, disabled: false }));
            const filteredColumns = filterForPermissions((permission) => app.hasPermission(permission), allColumns);
            this._columnSelector.columns = filteredColumns;
        }

        if (this._filtersEl && !this._filtersEl.filters.length) {
            this._filtersEl.filters = [];
        }
    }

    private _hasPermission(page: ExportFormat) {
        if (page.startsWith("payroll")) {
            return app.hasPermission("manage.exports.payroll");
        } else if (page.startsWith("time")) {
            return app.hasPermission("manage.exports.time");
        } else if (page.startsWith("cashbook")) {
            return app.hasPermission("manage.exports.cashbook");
        } else if (page.startsWith("employee_data")) {
            return app.hasPermission("manage.exports.employee_data");
        } else if (page.startsWith("balances")) {
            return app.hasPermission("manage.exports.ledgers");
        } else if (page.startsWith("roster")) {
            return app.hasPermission("manage.exports.roster");
        } else if (page.startsWith("employee_table_excel")) {
            return hasAnyRequiredPermissions((permission) => app.hasPermission(permission));
        } else {
            return false;
        }
    }

    private async _submit(e: Event) {
        e.preventDefault();
        this._exportResponse = null;
        this._loading = true;
        try {
            const exportPromise = app.api.export(new ExportParams({ ...this._exportFormData, format: this._page }));
            this._exportResponse = await exportPromise;
        } catch (error) {
            void alert(error.message, { type: "warning" });
        } finally {
            this._loading = false;
        }
    }

    static styles = [
        shared,
        DateInput.styles,
        Checkbox.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 20em;
            }

            .main {
                flex: 1;
                position: relative;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        const rangeSuggestions: { from: string; to: string; label: string }[] = [];

        let date = toDateString(new Date());

        for (let i = 0; i < 12; i++) {
            rangeSuggestions.push({
                label: formatMonthShort(date),
                ...getRange(date, "month"),
            });
            date = dateAdd(date, { months: -1 });
        }

        return html`
            <div class="sub-menu scrolling">
                <ptc-exports-menu
                    .page=${this._page}
                    .hasPermission=${this._hasPermission.bind(this)}
                    @navigate=${(e: CustomEvent<ExportFormat>) => {
                        this.go(`exports/${e.detail}`, this.dateRange!);
                    }}
                ></ptc-exports-menu>
            </div>

            <div class="main">
                <ptc-scroller class="fullbleed">
                    <div class="double-padded">
                        <div style="max-width: 36em; margin: auto;">
                            <ptc-exports-headers .page=${this._page}></ptc-exports-headers>

                            <form
                                @submit=${this._submit}
                                @change=${() => this.requestUpdate()}
                                ?disabled=${this._loading || !!this._exportResponse}
                            >
                                <div class="horizontal spacing layout">
                                    ${!this._page.startsWith("employee_data")
                                        ? html`
                                              <div class="field stretch">
                                                  <label>Zeitraum</label>
                                                  <ptc-date-range-picker
                                                      @range-selected=${(e: CustomEvent<DateRange>) =>
                                                          this.go(null, e.detail)}
                                                      .range=${this.dateRange}
                                                      class="box"
                                                  ></ptc-date-range-picker>
                                              </div>
                                          `
                                        : ""}
                                    ${this._page.startsWith("balances")
                                        ? html`
                                              <div class="field">
                                                  <label>Auflösung</label>
                                                  <select class="slim" name="resolution">
                                                      <option .value=${"week"}>Woche</option>
                                                      <option .value=${"month"} selected>Monat</option>
                                                      <option .value=${"year"}>Jahr</option>
                                                  </select>
                                              </div>
                                          `
                                        : ""}
                                </div>
                                ${this._page.startsWith("payroll") ||
                                this._page.startsWith("employee_data") ||
                                this._page.startsWith("balances") ||
                                this._page === ExportFormat.TimeSheet ||
                                this._page === ExportFormat.RosterExcel ||
                                this._page.startsWith("employee_table_excel")
                                    ? html`
                                          <div class="field">
                                              <label>Mitarbeiter</label>
                                              <div class="focus-container horizontal center-aligning layout">
                                                  ${this._page.startsWith("employee_data")
                                                      ? html`
                                                            <ptc-entity-filters
                                                                hideFilterIcon
                                                                .filterTypes=${[
                                                                    "employmentType",
                                                                    "venue",
                                                                    "department",
                                                                    "position",
                                                                    "employeeId",
                                                                    "employeeStatus",
                                                                ] as const}
                                                                class="stretch"
                                                            ></ptc-entity-filters>
                                                        `
                                                      : html`
                                                            <ptc-entity-filters
                                                                hideFilterIcon
                                                                .filterTypes=${[
                                                                    "employmentType",
                                                                    "venue",
                                                                    "department",
                                                                    "position",
                                                                    "employeeId",
                                                                    "costCenter",
                                                                ] as const}
                                                                class="stretch"
                                                            ></ptc-entity-filters>
                                                        `}
                                                  <ptc-column-selector
                                                      ?hidden=${!this._page.startsWith("employee_table_excel")}
                                                  ></ptc-column-selector>
                                                  <button
                                                      class="skinny transparent"
                                                      title="Sortierung"
                                                      style="margin-right: 0.25em;"
                                                      type="button"
                                                  >
                                                      <i class="arrow-down-wide-short"></i>
                                                  </button>
                                                  <ptc-popover>
                                                      <div class="horizontal spacing center-aligning layout">
                                                          <div>
                                                              <label>Sortieren nach...</label>
                                                              <select class="semibold small slim" name="sortProperty">
                                                                  <option value="lastName" selected>Nachname</option>
                                                                  <option value="firstName">Vorname</option>
                                                                  <option value="staffNumber">Personalnummer</option>
                                                                  <option value="birthday">Geburtstag</option>
                                                                  <option value="birthdayDate">
                                                                      Geburtstag (Datum)
                                                                  </option>
                                                                  <option value="email">Email</option>
                                                              </select>
                                                          </div>
                                                          <div>
                                                              <label>&nbsp;</label>
                                                              <select class="semibold small slim" name="sortDirection">
                                                                  <option value="ascending" selected>
                                                                      Aufsteigend
                                                                  </option>
                                                                  <option value="descending">Absteigend</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                  </ptc-popover>
                                              </div>
                                          </div>
                                      `
                                    : ""}
                                ${this._page.startsWith("cashbook")
                                    ? html`
                                          <div class="field">
                                              <label>Standort</label>
                                              <select name="venueId">
                                                  ${app.accessibleVenues.map(
                                                      (venue) => html`
                                                          <option .value=${venue.id.toString()}>${venue.name}</option>
                                                      `
                                                  )}
                                              </select>
                                          </div>
                                      `
                                    : ""}

                                <div class="field">
                                    <label>
                                        Versenden An
                                        <i class="info-circle"></i>
                                        <ptc-popover class="tooltip" style="max-width:25em" trigger="hover">
                                            Der Link zum Dokument ist für 14 Tage gültig.
                                        </ptc-popover>
                                    </label>
                                    <input type="email" placeholder="Emailaddresse" name="sendAsEmail" />
                                </div>

                                ${[ExportFormat.PayrollReportDetailed, ExportFormat.TimeSheet].includes(this._page) &&
                                app.hasPermission("manage.employees.documents")
                                    ? html`
                                          <div class="field">
                                              <ptc-checkbox-button
                                                  label="Im Dokumentenarchiv ablegen"
                                                  buttonClass="semislim ghost"
                                                  name="saveToDocuments"
                                              ></ptc-checkbox-button>
                                          </div>

                                          ${this._page === ExportFormat.TimeSheet
                                              ? html`
                                                    <div class="field" ?hidden=${!this._exportFormData.saveToDocuments}>
                                                        <ptc-checkbox-button
                                                            label="Unterschrift Anfordern"
                                                            buttonClass="semislim ghost"
                                                            name="requireEmployeeSignature"
                                                        ></ptc-checkbox-button>
                                                    </div>
                                                `
                                              : ""}

                                          <div class="field" ?hidden=${!this._exportFormData.saveToDocuments}>
                                              <label>Kategorien</label>
                                              <ptc-document-tags-input></ptc-document-tags-input>
                                          </div>
                                      `
                                    : ""}

                                <div class="double-margined vertical layout">
                                    <button class="primary"><i class="download"></i> Export Starten</button>
                                </div>
                            </form>

                            <ptc-exports-result
                                .scroller=${this._scroller}
                                ?loading=${this._loading}
                                .exportResponse=${this._exportResponse}
                                .onCancel=${() => {
                                    this._loading = false;
                                    this._exportResponse = null;
                                }}
                            ></ptc-exports-result>
                        </div>
                    </div>
                </ptc-scroller>
            </div>
        `;
    }
}
