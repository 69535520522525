import {
    Availability,
    AvailabilityStatus,
    Employee,
    EmployeeStatus,
    MonthlyStatement,
    RosterNote,
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
} from "@pentacode/core/src/model";
import { GetAvailabilitesParams, GetRosterNotesParams } from "@pentacode/core/src/api";
import {
    dateAdd,
    dateSub,
    getRange,
    parseDateString,
    monthNames,
    formatWeekDayShort,
    formatDateShort,
    toDateString,
} from "@pentacode/core/src/util";
import { Holiday, getHolidayForDate } from "@pentacode/core/src/holidays";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query, queryAll } from "lit/decorators.js";
import { cache } from "lit/directives/cache.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { shared } from "../styles";
import { app, router } from "../init";
import { alert } from "./alert-dialog";
import "./avatar";
import { AvailabilityDialog } from "./availability-dialog";
import { MonthPicker } from "./month-picker";
import { Popover } from "./popover";
import { Checkbox } from "./checkbox";
import anime from "animejs";
import "./progress";
import "./employees-filter";
import "./spinner";
import { EmployeeSortProperty, matchesFilters } from "@pentacode/core/src/filters";
import { DateRange } from "@pentacode/core/src/time";
import { DateString } from "@pentacode/openapi";

interface Month {
    name: string;
    from: string;
    to: string;
    year: number;
    days: {
        date: DateString;
        holiday: Holiday | null;
        dayOfWeek: number;
    }[];
    isVisible: boolean;
}

type AvailabilityInputEvent = CustomEvent<{ employee: Employee; date: DateString; dragEvent: DragEvent }>;

@customElement("ptc-employee-availabilities-row")
export class EmployeeAvailabilitysRow extends LitElement {
    @property({ attribute: false })
    employee: Employee;

    @property({ attribute: false })
    availabilities: Availability[];

    @property({ attribute: false })
    months: Month[];

    @property({ attribute: false })
    from: DateString;

    @property({ attribute: false })
    to: DateString;

    @property({ type: Boolean })
    isVisible = false;

    shouldUpdate(changes: Map<string, any>) {
        return changes.has("isVisible") || this.isVisible;
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }

    // protected async performUpdate() {
    //     await new Promise<void>((resolve) => setTimeout(resolve));
    //     super.performUpdate();
    // }

    private async _dragstart(dragEvent: DragEvent, employee: Employee, date: string) {
        this.dispatchEvent(new CustomEvent("drag-start", { detail: { dragEvent, employee, date } }));
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        e.dataTransfer!.dropEffect = "copy";
    }

    private _dragenter(dragEvent: DragEvent, employee: Employee, date: string) {
        this.dispatchEvent(new CustomEvent("drag-enter", { detail: { dragEvent, employee, date } }));
    }

    private async _dragend() {
        this.dispatchEvent(new CustomEvent("drag-end"));
    }

    private _drop() {}

    private async _dayClicked(dragEvent: Event, employee: Employee, date: string) {
        this.dispatchEvent(new CustomEvent("day-clicked", { detail: { dragEvent, employee, date } }));
    }

    private async _editAvailability(availability: Availability, e?: Event) {
        e?.stopPropagation();
        this.dispatchEvent(
            new CustomEvent("edit-availability", {
                detail: { availability },
            })
        );
    }

    private _render() {
        const employee = this.employee;
        const availabilities = this.availabilities.filter((av) => av.employeeId === employee.id);

        return html`
            <div
                class="row-header horizontal spacing center-aligning layout click"
                @click=${() => router.go(`employees/${employee.id}/availabilities`)}
            >
                <ptc-avatar .employee=${employee}></ptc-avatar>
                <div class="stretch ellipsis right-padded" style="font-size: 0.9em;">
                    ${employee.lastName}, ${employee.firstName}
                </div>
            </div>
            ${this.months.map(
                ({ days, isVisible }) =>
                    html`<div class="month horizontal layout" style="width: calc(${days.length} * var(--cell-size));">
                        ${cache(
                            isVisible
                                ? html`
                                      ${days.map(({ date, dayOfWeek, holiday }) => {
                                          const avs = availabilities.filter((e) => e.date === date);
                                          const contract = employee.getContractForDate(date);
                                          const blocked =
                                              (!contract || contract.blocked) && (contract?.comment || "inaktiv");

                                          return blocked
                                              ? html`
                                                    <div class="relative centering layout day">
                                                        <i class="faded ban" title="${blocked}"></i>
                                                    </div>
                                                `
                                              : html`
                                                    <div
                                                        class="relative click day ${holiday
                                                            ? "violet"
                                                            : dayOfWeek === 0
                                                              ? "orange"
                                                              : ""}"
                                                        title=${holiday
                                                            ? holiday.name
                                                            : `${formatWeekDayShort(date)}, ${formatDateShort(date)}`}
                                                        @click=${(e: Event) => this._dayClicked(e, employee, date)}
                                                        draggable="false"
                                                        @dragstart=${(e: DragEvent) =>
                                                            this._dragstart(e, employee, date)}
                                                        @dragenter=${(e: DragEvent) =>
                                                            this._dragenter(e, employee, dateAdd(date, { days: 1 }))}
                                                        @dragover=${this._dragover}
                                                        @dragend=${this._dragend}
                                                        @drop=${this._drop}
                                                    >
                                                        ${avs[0]
                                                            ? html`
                                                                  <div
                                                                      class="fullbleed centering layout availability-icon ${avs[0]
                                                                          .start || avs[0].end
                                                                          ? "cutoff-bottomright"
                                                                          : ""}"
                                                                      style="--color-highlight: ${avs[0].color}"
                                                                      title="${avs[0].fullLabel}"
                                                                      @click=${(e: Event) =>
                                                                          this._editAvailability(avs[0], e)}
                                                                  >
                                                                      <i class="${avs[0].icon}"></i>
                                                                  </div>
                                                              `
                                                            : ""}
                                                        ${avs[1]
                                                            ? html`
                                                                  <div
                                                                      class="fullbleed centering layout availability-icon cutoff-topleft"
                                                                      style="--color-highlight: ${avs[1].color}"
                                                                      title="${avs[1].fullLabel}"
                                                                      @click=${(e: Event) =>
                                                                          this._editAvailability(avs[1], e)}
                                                                  >
                                                                      <i class="${avs[1].icon}"></i>
                                                                  </div>
                                                              `
                                                            : ""}
                                                    </div>
                                                `;
                                      })}
                                  `
                                : ""
                        )}
                    </div>`
            )}
        `;
    }
}

@customElement("ptc-employees-availabilities-all")
export class EmployeesAvailabilitiesAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/availabilities/;

    get routeTitle() {
        return "Verfügbarkeiten: Alle Mitarbeiter";
    }

    private get _employees() {
        return app.getFilteredEmployees({ from: this._from, to: this._to });
    }

    @state()
    private _loading = false;

    @state()
    private _from: DateString;

    @state()
    private _to: DateString;

    @state()
    private _months: Month[] = [];

    @state()
    private _availabilities: Availability[] = [];

    @state()
    private _entries: TimeEntry[] = [];

    @state()
    private _statements: MonthlyStatement[] = [];

    @state()
    private _rosterNotes: RosterNote[] = [];

    @state()
    private _rosterNoteLanes: RosterNote[][] = [];

    @singleton("ptc-availability-dialog")
    private _availabilityDialog: AvailabilityDialog;

    private _newAvailabilityData: {
        employee: Employee;
        start: DateString;
        end: DateString;
        length: number;
        top: number;
        left: number;
        right: number;
    } | null = null;

    private _monthsIntersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => this._monthsIntersectionHandler(entries),
        { root: this, rootMargin: "0px 0px 0px -200px" }
    );

    @query(".scrolling", true)
    private _scroller: HTMLDivElement;

    @query(".availability.dragging", true)
    private _dragElement: HTMLDivElement;

    @query("#dragImage", true)
    private _dragImage: HTMLDivElement;

    @query("ptc-month-picker", true)
    private _monthPicker: MonthPicker;

    @queryAll(".header-row .month")
    private _monthHeaders: HTMLDivElement[];

    @query("#newAvailabilityPopover")
    private _newAvailabilityPopover: Popover;

    async handleRoute() {
        if (!this.date) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }

        const months = this._setRange(getRange(this.date, "year"));

        // Load schedule in the background
        void this.updateMonths(months);

        // already scroll to new date as soon as rerender is complete
        await this.updateComplete;
        void this._scrollToDate(this.date);
    }

    private _scrollAnimation: any | null = null;
    private async _scrollToDate(date: string) {
        const el = this.renderRoot!.querySelector(`#header-${date}`) as HTMLElement;
        const rowHeader = this.renderRoot!.querySelector(".header-row .row-header") as HTMLElement;

        if (this._scrollAnimation) {
            this._scrollAnimation.remove(this._scroller);
        }

        this._monthsIntersectionObserver.disconnect();

        this._scrollAnimation = anime({
            targets: this._scroller,
            scrollLeft: el.offsetLeft - rowHeader.offsetWidth,
            duration: 500,
            easing: "easeInOutCubic",
        });

        await this._scrollAnimation.finished;

        this._scrollAnimation = null;

        this._monthHeaders.forEach((e) => this._monthsIntersectionObserver.observe(e));
    }

    private _setRange({ from, to }: DateRange): Month[] | undefined {
        if (from === this._from && to === this._to) {
            return;
        }
        this._from = from;
        this._to = to;
        const days: string[] = [];
        const months: Month[] = [];
        let month: Month | undefined = undefined;
        let date = this._from;

        while (date < this._to) {
            days.push(date);
            const monthRange = getRange(date, "month");
            if (!month || month.from !== monthRange.from) {
                const d = parseDateString(monthRange.from)!;
                const name = monthNames[d.getMonth()];
                month = {
                    from: monthRange.from,
                    to: monthRange.to,
                    name,
                    year: d.getFullYear(),
                    days: [],
                    isVisible: false,
                };
                months.push(month);
            }
            const holiday = getHolidayForDate(date, {
                holidays: app.venues[0]?.enabledHolidays,
                country: app.company!.country,
            });
            month.days.push({ date, holiday, dayOfWeek: new Date(date).getDay() });
            date = dateAdd(date, { days: 1 });
        }

        return months;
    }

    private async updateMonths(months: Month[] | undefined) {
        if (!months) return;

        this._monthsIntersectionObserver.disconnect();
        this._months = months;

        await this._load();

        await this.updateComplete;
        this._monthHeaders.forEach((e) => this._monthsIntersectionObserver.observe(e));
    }

    private _monthsIntersectionHandler(entries: IntersectionObserverEntry[]) {
        for (const entry of entries) {
            const el = entry.target as HTMLElement;
            const month = this._months.find((m) => m.from === el.dataset.date);
            if (month) {
                month.isVisible = entry.isIntersecting;
            }
        }
        const currMonth = this._months.find((m) => m.isVisible)?.from;
        if (currMonth) {
            this._monthPicker.date = currMonth;
            this._monthHeaders.forEach((el) => el.classList.toggle("current-month", el.dataset.date === currMonth));
        }
        this._months = [...this._months];
    }

    private _rowsInteractionObserver?: IntersectionObserver;

    private _rowsIntersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as EmployeeAvailabilitysRow).isVisible = e.isIntersecting));
    }

    private _observeRows() {
        if (!this._rowsInteractionObserver) {
            const scroller = this.renderRoot.querySelector(".scrolling");
            if (!scroller) {
                return;
            }
            this._rowsInteractionObserver = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => this._rowsIntersectionHandler(entries),
                { root: scroller, rootMargin: "100%" }
            );
        }
        const elements = this.renderRoot.querySelectorAll("ptc-employee-availabilities-row");
        for (const el of elements) {
            this._rowsInteractionObserver.observe(el);
        }
    }

    updated() {
        this._observeRows();
    }

    private async _load() {
        this._loading = true;

        try {
            const [availabilities, rosterNotes] = await Promise.all([
                app.api.getAvailabilities(
                    new GetAvailabilitesParams({
                        from: this._from,
                        to: this._to,
                        employees: this._employees.map((e) => e.id),
                    })
                ),
                app.api.getRosterNotes(
                    new GetRosterNotesParams({
                        from: this._from,
                        to: this._to,
                    })
                ),
            ]);

            this._availabilities = availabilities;
            this._rosterNotes = rosterNotes;
            this._updateRosterNoteLanes();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private _updateRosterNoteLanes() {
        const company = app.company;
        if (!company) {
            return;
        }
        const rosterNoteLanes: RosterNote[][] = [];
        const departments = app.accessibleDepartments.filter((department) =>
            matchesFilters(app.state.employeeFilters.filters, { company, department })
        );
        const notes = this._rosterNotes.filter((note) =>
            departments.some((department) => matchesFilters(note.filters, { company, department }))
        );
        for (const note of notes) {
            let lane = rosterNoteLanes.find((notes) => !notes.some((n) => n.start < note.end && n.end > note.start));
            if (!lane) {
                lane = [];
                rosterNoteLanes.push(lane);
            }
            lane.push(note);
        }
        this._rosterNoteLanes = rosterNoteLanes;
    }

    private async _dayClicked({ detail: { employee, date } }: AvailabilityInputEvent) {
        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId: employee.id,
                status: AvailabilityStatus.Available,
                date,
            })
        );
        if (created) {
            // update availabilities in the background
            void this._load();
        }
    }

    private async _openNewAvailabilityPopover(ignoreFirstClick = false) {
        if (this._newAvailabilityData) {
            await this._newAvailabilityPopover.showAt(this._dragElement, ignoreFirstClick);
            // this._newAvailability(this._newAvailabilityData.employee, this._newAvailabilityData.start, this._newAvailabilityData.end);
        }
        this._dragElement.classList.add("invisible");
        this._newAvailabilityData = null;
    }

    private async _newAvailability() {
        if (!this._newAvailabilityData) {
            return;
        }

        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId: this._newAvailabilityData.employee.id,
                start: this._newAvailabilityData.start,
                end: this._newAvailabilityData.end,
            })
        );
        if (created) {
            // update availabilities in the background
            void this._load();
        }
    }

    private async _editAvailability(availability: Availability) {
        const edited = await this._availabilityDialog.show(availability);
        if (edited) {
            // update availabilities in the background
            void this._load();
        }
    }

    private _updateDragElement() {
        const data = this._newAvailabilityData;
        const el = this._dragElement;
        if (!data || !el) {
            return;
        }
        el.classList.remove("invisible");
        el.style.top = `${data.top}px`;
        el.style.left = `${data.left}px`;
        el.style.width = `${data.right - data.left}px`;
        (el.querySelector(".range") as HTMLDivElement).innerText = `${formatDateShort(data.start)} - ${formatDateShort(
            dateAdd(data.end, { days: -1 })
        )}`;
        (el.querySelector(".length") as HTMLDivElement).innerText = `${data.length} T`;
    }

    private _dragStart({ detail: { dragEvent, employee, date } }: AvailabilityInputEvent) {
        const el = dragEvent.target as HTMLElement;
        dragEvent.dataTransfer!.setDragImage(this._dragImage, 0, 0);
        dragEvent.dataTransfer!.effectAllowed = "copy";
        dragEvent.dataTransfer!.dropEffect = "copy";
        dragEvent.dataTransfer!.setData("text/plain", "blah");
        this._newAvailabilityData = {
            employee,
            start: date,
            end: dateAdd(date, { days: 1 }),
            length: 1,
            top: el.offsetTop,
            left: el.offsetLeft,
            right: el.offsetLeft + el.offsetWidth,
        };

        this._updateDragElement();
    }

    private _dragOver({ detail: { dragEvent } }: AvailabilityInputEvent) {
        dragEvent.preventDefault();
        dragEvent.dataTransfer!.dropEffect = "copy";
    }

    private _dragEnter({ detail: { dragEvent, date } }: AvailabilityInputEvent) {
        if (this._newAvailabilityData) {
            const el = dragEvent.target as HTMLElement;
            this._newAvailabilityData.end =
                date > this._newAvailabilityData.start ? date : dateAdd(this._newAvailabilityData.start, { days: 1 });
            this._newAvailabilityData.length = dateSub(this._newAvailabilityData.start, this._newAvailabilityData.end);
            this._newAvailabilityData.right = el.offsetLeft + el.offsetWidth;
            this._updateDragElement();
        }
    }

    private _dragEnd() {
        void this._openNewAvailabilityPopover();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                --cell-size: 2em;
            }

            .row {
                border-bottom: solid 1px var(--shade-2);
                box-sizing: border-box;
                height: 2em;
                contain: size style;
            }

            .header-row {
                background: var(--color-bg);
                position: sticky;
                top: 0;
                z-index: 3;
                border-bottom: solid 1px var(--shade-2);
            }

            .month {
                box-shadow: inset var(--shade-2) -1px 0 0 0px;
                box-sizing: border-box;
                scroll-snap-align: start;
            }

            .month-name {
                padding: 0.9em 1em;
                font-weight: 600;
                transition: all 0.5s;
            }

            .month.current-month .month-name {
                transform: translateX(-50%);
                opacity: 0;
            }

            .row-header {
                background: rgba(255, 255, 255, 0.9);
                border-right: solid 1px var(--shade-2);
                width: calc(7 * var(--cell-size));
                position: sticky;
                left: 0;
                box-sizing: border-box;
                z-index: 1;
            }

            .row-header ptc-avatar {
                font-size: 0.5em;
                margin-left: 0.5em;
            }

            .row-header .vacation {
                margin-right: 0.2em;
                width: 4em;
            }

            .day {
                height: var(--cell-size);
                width: var(--cell-size);
                box-sizing: border-box;
                color: var(--color-highlight);
                background: var(--color-highlight-bg);
                padding: 0.25em;
            }

            .day:not(:last-child) {
                border-right: dashed 1px var(--shade-2);
            }

            .availability {
                position: absolute;
                height: var(--cell-size);
                box-sizing: border-box;
            }

            .availability-inner {
                background: var(--color-highlight);
                color: var(--color-bg);
                border-radius: 0.5em;
                position: absolute;
                top: 0.1em;
                left: 0.1em;
                right: 0.1em;
                bottom: 0.1em;
                padding: 0 0.2em;
                overflow: hidden;
                box-sizing: border-box;
                border: solid 1px transparent;
                line-height: 2em;
            }

            .availability.requested .availability-inner {
                border-color: var(--color-highlight);
                background: var(--color-bg);
                color: var(--color-highlight);
            }

            .dragging {
                opacity: 0.7;
                pointer-events: none;
            }

            .today {
                border-radius: 100%;
                background: var(--blue);
                color: var(--color-bg);
            }

            .roster-notes {
                position: sticky;
                top: 5.2em;
                background: var(--color-bg);
                z-index: 2;
            }

            .availability-icon {
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .availability-icon.cutoff-topleft {
                clip-path: polygon(100% 0, 100% 100%, 0 100%);
            }

            .availability-icon.cutoff-bottomright {
                clip-path: polygon(100% 0, 0 0, 0 100%);
            }
        `,
    ];

    private _renderHeader() {
        const today = toDateString(new Date());
        return html`<div class="horizontal layout header-row">
            <div class="row-header vertical layout"></div>
            ${this._months.map(
                ({ name, days, year, from }) => html`
                    <div class="month vertical start-aligning layout" data-date="${from}">
                        <div class="month-name stretch">${name} ${year}</div>
                        <div class="horizontal layout">
                            ${days.map(({ date, dayOfWeek, holiday }) => {
                                const d = parseDateString(date)!;
                                return html`
                                    <div
                                        class="text-centering day ${holiday
                                            ? "violet"
                                            : dayOfWeek === 0
                                              ? "orange"
                                              : ""}"
                                        title=${holiday
                                            ? holiday.name
                                            : `${formatWeekDayShort(date)}, ${formatDateShort(date)}`}
                                        id="header-${date}"
                                    >
                                        <div class="${date === today ? "today" : ""}">${d.getDate()}</div>
                                    </div>
                                `;
                            })}
                        </div>
                    </div>
                `
            )}
        </div> `;
    }

    private _renderRosterNotes() {
        return html`<div
            class="row horizontal layout roster-notes"
            style="height: calc(${this._rosterNoteLanes.length || 1} * (var(--cell-size) + 1px))"
        >
            <div class="half-padded row-header click">
                <div class="horizontal center-aligning layout">
                    <i class="sticky-note right-margined"></i>
                    <div class="stretch ellipsis right-padded semibold" style="font-size: 0.9em;">
                        Dienstplannotizen
                    </div>
                </div>
            </div>
            <div>
                ${this._rosterNoteLanes.map(
                    (notes, i) => html`
                        <div class="relative horizontal layout ${i ? "border-top" : ""}">
                            ${this._months.map(
                                ({ days }) =>
                                    html`<div class="month horizontal layout">
                                        ${days.map(({ date, dayOfWeek, holiday }) => {
                                            return html`
                                                <div
                                                    class="centering layout click day ${holiday
                                                        ? "violet"
                                                        : dayOfWeek === 0
                                                          ? "orange"
                                                          : ""}"
                                                    title=${holiday
                                                        ? holiday.name
                                                        : `${formatWeekDayShort(date)}, ${formatDateShort(date)}`}
                                                ></div>
                                            `;
                                        })}
                                    </div>`
                            )}
                            ${notes.map((note) => {
                                const length = dateSub(note.start, note.end);
                                const dayIndex = dateSub(this._from, note.start);
                                return html`
                                    <div
                                        class="availability requested"
                                        style="--color-highlight: ${note.color ||
                                        "var(--color-fg)"}; left: calc(${dayIndex} * var(--cell-size)); width: calc(${length} * var(--cell-size) - 1px);"
                                    >
                                        <div
                                            class="availability-inner spacing center-aligning horizontal layout click"
                                            title=${note.text}
                                        >
                                            <i class="smaller sticky-note"></i>
                                            <div class="stretch ellipsis">${note.text.split("\n")[0]}</div>
                                        </div>
                                    </div>
                                `;
                            })}
                        </div>
                    `
                )}
            </div>
        </div> `;
    }

    render() {
        if (!this._from || !this._to || !this._months) {
            return html`
                <div class="fullbleed centering layout scrim">
                    <ptc-spinner active></ptc-spinner>
                </div>
            `;
        }

        const employees = this._employees;
        const daysCount = dateSub(this._from, this._to);

        return html`
            <div class="fullbleed vertical layout">
                <div class="padded horizontal spacing center-aligning layout border-bottom">
                    <ptc-month-picker
                        @change=${({ detail: { date } }: CustomEvent<{ date: string }>) => this.go(null, { date })}
                    ></ptc-month-picker>
                    <div class="stretch"></div>
                </div>
                <ptc-employees-filter
                    class="border-bottom"
                    @change=${() => this._updateRosterNoteLanes()}
                    .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
                ></ptc-employees-filter>
                <div class="scrolling stretch">
                    <div class="scroller-inner" style="width: calc(${daysCount + 17} * var(--cell-size))">
                        ${this._renderHeader()} ${this._renderRosterNotes()}
                        <div class="relative">
                            ${employees.map((employee) => {
                                const filtered =
                                    employee.status === EmployeeStatus.Probation ||
                                    !app.employeeMatchesFilters(employee);
                                return cache(
                                    filtered
                                        ? null
                                        : html`
                                              <ptc-employee-availabilities-row
                                                  class="row horizontal layout"
                                                  .employee=${employee}
                                                  .availabilities=${this._availabilities}
                                                  .statements=${this._statements}
                                                  .entries=${this._entries}
                                                  .months=${this._months}
                                                  .from=${this._from}
                                                  .to=${this._to}
                                                  @day-clicked=${this._dayClicked}
                                                  @edit-availability=${(
                                                      e: CustomEvent<{ availability: Availability }>
                                                  ) => this._editAvailability(e.detail.availability)}
                                                  @drag-start=${this._dragStart}
                                                  @drag-over=${this._dragOver}
                                                  @drag-enter=${this._dragEnter}
                                                  @drag-end=${this._dragEnd}
                                              ></ptc-employee-availabilities-row>
                                          `
                                );
                            })}

                            <div class="grey availability dragging invisible">
                                <div class="availability-inner spacing center-aligning horizontal layout click">
                                    <i class="smaller question"></i>
                                    <div class="stretch ellipsis range"></div>
                                    <div class="subtle semibold length"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ptc-popover id="newAvailabilityPopover" class="larger tooltip" hide-on-click .anchor=${null}>
                <div class="vertical spacing layout">
                    ${[
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                    ].map(
                        (type) => html`
                            <button
                                class="slim ghost"
                                @click=${() => this._newAvailability()}
                                style="--color-highlight: ${timeEntryTypeColor(type)}"
                            >
                                <i class="${app.localized.timeEntryTypeIcon(type)}"></i>
                                ${app.localized.timeEntryTypeLabel(type)}
                            </button>
                        `
                    )}
                </div>
            </ptc-popover>

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner .active=${this._loading}></ptc-spinner>
            </div>
            <img
                id="dragImage"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            />
        `;
    }
}
