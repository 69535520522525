import "../styles/print.css";
import { toDateString } from "@pentacode/core/src/util";
import { CompanyStatus, Employee } from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { ServiceWorker } from "../mixins/service-worker";
import { ErrorHandling } from "../mixins/error-handling";
import { Routing, ready } from "../mixins/routing";
import { shared, config, mixins } from "../styles";
import { app } from "../init";
import Logo from "../img/logo.svg";
import Icon from "../img/icon.svg";
import "./login";
import "./select-company";
import "./set-password";
import "./roster";
import "./time-logging";
import "./settings";
import "./employees";
import "./issues";
import "./revenues";
import "./reports";
import "./planning";
import "./recruiting";
import "./exports";
import { confirm } from "./alert-dialog";
import "./popover";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { changelog, getVersionInfo } from "@pentacode/core/src/version";
import "./avatar";
import { openHelp } from "./help";
import { UpdateInstalledDialog } from "./update-installed-dialog";
import { singleton } from "../lib/singleton";
import { Permission } from "@pentacode/core/src/permissions";
import { provide } from "@lit/context";
import { enabledFeatures, loadEnabledFeatures } from "../contexts/features-context";

@customElement("ptc-app")
export class App extends ServiceWorker(ErrorHandling(Routing(StateMixin(LitElement)))) {
    static pages = [
        "time",
        "roster",
        "employees",
        "recruiting",
        "issues",
        "revenues",
        "planning",
        "reports",
        "settings",
        "select_company",
        "exports",
    ];

    static styles = [
        config,
        shared,
        css`
            :host {
                display: block;
                ${mixins.fullbleed()};
                display: flex;
                flex-direction: column;
            }

            .offline {
                padding: 0.5em;
                text-align: center;
                background: var(--red);
                color: var(--color-bg);
                letter-spacing: 0.2em;
            }

            .main {
                flex: 1;
                align-self: center;
                width: 100%;
                box-sizing: border-box;
                max-width: 1600px;
            }

            .main-menu {
                padding-bottom: 0.5em;
                background: var(--color-bg);
                border-right: solid 1px var(--shade-1);
                max-width: 15em;
                box-sizing: border-box;
            }

            .logo-wrapper {
                padding: 1.5em 0.65em 0 0.65em;
                overflow: hidden;
                position: relative;
            }

            .logo-wrapper .logo {
                width: 12.7em;
                transition: opacity 0.2;
            }

            .logo-wrapper .icon {
                width: 2.8em;
                position: absolute;
                left: 0.6em;
                top: 1.2em;
                opacity: 0;
            }

            .changelog {
                width: 25em;
                font-size: var(--font-size-small);
                text-align: left;
            }

            .changelog-popover {
                padding: 0;
                z-index: 100;
            }

            .changelog-scroller {
                max-height: 35em;
            }

            .main-menu > button {
                font-size: var(--font-size-large);
                background: transparent;
                text-align: left;
                font-weight: 400;
                padding: 0.45em 0.3em;
                margin: 0.25em 0.5em 0 0.5em;
                border-radius: var(--border-radius);
                display: flex;
                align-items: center;
            }

            .button-label {
                padding-left: 0.25em;
                flex: 1;
                overflow: hidden;
                font-size: 0.9em;
                letter-spacing: 0.02em;
            }

            .button-label > :not(:last-child) {
                margin-right: 0.5em;
            }

            .main-menu > button i {
                transition: transform 0.3s;
                flex: none;
            }

            .main-menu > button[active] {
                box-shadow: none;
                color: var(--color-primary);
                background: transparent;
                font-weight: bold;
            }

            .main-views {
                position: relative;
                background: var(--color-bg);
            }

            .main-views > * {
                ${mixins.fullbleed()};
            }

            .account-button ptc-avatar {
                flex: none;
                font-size: var(--font-size-tiny);
            }

            .account-info {
                padding-left: 0.5em;
                flex: 1;
                overflow: hidden;
            }

            .account-email {
                font-weight: 600;
                font-size: var(--font-size-small);
            }

            .account-name {
                font-weight: bold;
                font-size: var(--font-size-small);
            }

            .account-popover button {
                padding: 0.4em;
            }

            .version {
                text-align: center;
                font-size: var(--font-size-tiny);
                opacity: 0.5;
                margin: -1.5em auto 0.2em auto;
            }

            .issues-button.has-issues {
                color: var(--color-negative);
            }

            .main-menu button[active] .pill {
                color: var(--color-bg);
            }

            @media (max-width: 1400px) {
                .main {
                    padding-left: 4em;
                }

                .main-menu {
                    left: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    max-width: 4em;
                    transition: all 0.2s;
                }

                .main-menu:hover {
                    z-index: 11;
                    max-width: 20em;
                    box-shadow: rgba(0, 0, 0, 0.1) 1px 0 5px;
                    border-color: transparent;
                }

                .main-menu:not(:hover) .logo-wrapper .logo {
                    opacity: 0;
                }

                .main-menu .logo-wrapper .icon {
                    opacity: 1;
                }

                .main-menu:hover .logo-wrapper .icon {
                    opacity: 0;
                }
            }

            @media (hover: none) and (max-width: 1400px) {
                .main-menu:hover {
                    max-width: 4em;
                    box-shadow: none;
                }

                .main-menu .logo-wrapper .logo {
                    opacity: 0;
                }

                .main-menu .logo-wrapper .icon {
                    opacity: 1;
                }
            }

            @media print {
                :host {
                    position: static;
                }

                .main {
                    padding: 0 !important;
                }

                .main-views {
                    border: none;
                }

                .main,
                .main-views > * {
                    display: block;
                    position: static;
                    max-width: none;
                }
            }
        `,
    ];

    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @state()
    private _page: string = "";

    @singleton("ptc-update-installed-dialog")
    private _updateInstalledDialog: UpdateInstalledDialog;

    @provide({ context: enabledFeatures })
    protected _enabledFeatures = loadEnabledFeatures();

    readonly active = true;

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        await this.routeChanged(this.router.path, this.router.params);
        ready();
    }

    async routeChanged(path: string, { c, company, showChangesSince, ...params }: { [param: string]: string }) {
        const [, page] = /^([^/]*)\/?.*/.exec(path) || ["", ""];
        const loggedIn = !!app.account;

        const companyId = Number(c || company);

        if (!loggedIn) {
            if (!["login", "setpwd", "invite"].includes(page)) {
                this.go("login", { next: path }, true);
                return;
            }
        } else if (!isNaN(companyId) && (!app.state.session?.companyId || companyId !== app.state.session.companyId)) {
            await app.selectCompany(companyId);
            window.location.reload();
            return;
        } else if (!app.state.session?.companyId) {
            if (page !== "select_company") {
                this.go("select_company", { next: path });
                return;
            }
        } else if (
            !App.pages.includes(page) ||
            (!["select_company", "settings"].includes(page) && !app.hasPermission(`manage.${page}` as Permission))
        ) {
            this.go(App.pages.find((p) => app.hasPermission(`manage.${p}` as Permission)) || "settings");
            return;
        }

        // Delete company param from params
        this.router.params = params;

        this._page = page;

        const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
        loadingScreen.style.display = "none";

        if (showChangesSince) {
            const versionInfo = getVersionInfo(showChangesSince);
            console.log(versionInfo);
            void this._updateInstalledDialog.show(versionInfo);
        }
    }

    private async _logout() {
        const confirmed = await confirm("Sind Sie sicher dass Sie sich ausloggen möchten?", "Ausloggen");
        if (confirmed) {
            await app.logout();
            window.location.reload();
        }
    }

    render() {
        const issues = app.issues;
        const employee = (app.account && app.employees.find((e) => e.accountId === app.account!.id)) || new Employee();

        return html`
            ${this.offline
                ? html` <div class="offline">OFFLINE</div> `
                : app.company?.effectiveStatus === CompanyStatus.PaymentOverdue
                  ? html`
                        <div class="offline">
                            <i class="exclamation-triangle bold"></i> Zahlung überfällig! Bitte wenden Sie sich an
                            unseren Support.
                        </div>
                    `
                  : ""}

            <ptc-popover trigger="hover" class="tooltip">
                Die Applikation konnte keine Verbindung zum Pentacode Server herstellen. Bitte prüfen Sie Ihre
                Internetverbindung!
            </ptc-popover>

            <div class="main horizontal layout">
                <div class="main-menu vertical layout noprint">
                    <div class="logo-wrapper">
                        <img src="${Icon}" class="icon" />

                        <img src="${Logo}" class="logo" />

                        <ptc-popover class="changelog-popover" trigger="hover" alignment="right-bottom">
                            <ptc-scroller class="changelog-scroller">
                                <div class="changelog">${unsafeHTML(changelog)}</div>
                            </ptc-scroller>
                        </ptc-popover>
                    </div>

                    <button class="transparent account-button">
                        <ptc-avatar .employee=${employee}></ptc-avatar>

                        <div class="account-info">
                            <div class="account-name ellipsis">
                                ${app.account && (app.account.name || app.account.email)}
                            </div>
                            <div class="account-email ellipsis">${app.company && app.company.name}</div>
                        </div>
                    </button>

                    <ptc-popover class="popover-menu" alignment="right" hide-on-click>
                        <button @click=${() => this.go("settings/account")} class="transparent stretch">
                            <i class="cog"></i>
                            Einstellungen
                        </button>

                        <button @click=${this._logout} class="transparent stretch">
                            <i class="door-open"></i>
                            Ausloggen
                        </button>

                        <button
                            @click=${() => this.go("select_company")}
                            class="transparent stretch"
                            ?hidden=${!app.account ||
                            !app.account.profiles ||
                            (!app.account.admin && app.account.profiles.length < 2)}
                        >
                            <i class="random"></i>
                            Unternehmen Wechseln
                        </button>
                    </ptc-popover>

                    <button
                        ?active=${this._page === "time"}
                        @click=${() => this.go("time", { date: toDateString(new Date()) })}
                        ?disabled=${!app.hasPermission("manage.time")}
                    >
                        <i class="stopwatch"></i>
                        <div class="button-label">Zeiterfassung</div>
                    </button>

                    <button
                        @click=${() => this.go("roster", { date: toDateString(new Date()) })}
                        ?active=${this._page === "roster"}
                        ?disabled=${!app.hasPermission("manage.roster")}
                    >
                        <i class="table"></i>
                        <div class="button-label">Dienstplan</div>
                    </button>

                    <button
                        @click=${() => this.go("employees")}
                        ?active=${this._page === "employees"}
                        ?disabled=${!app.hasPermission("manage.employees")}
                    >
                        <i class="people-group"></i>
                        <div class="button-label" data-testid="employees-navigation-button">Mitarbeiter</div>
                    </button>

                    <button
                        @click=${() => this.go("recruiting")}
                        ?active=${this._page === "recruiting"}
                        ?disabled=${!app.hasPermission("manage.recruiting")}
                    >
                        <i class="image-polaroid-user"></i>
                        <div class="button-label">Recruiting</div>
                    </button>

                    <button
                        @click=${() => this.go("revenues")}
                        ?active=${this._page === "revenues"}
                        ?disabled=${!app.hasPermission("manage.revenues")}
                    >
                        <i class="euro-sign"></i>
                        <div class="button-label">Umsätze</div>
                    </button>

                    <button
                        @click=${() => this.go("planning")}
                        ?active=${this._page === "planning"}
                        ?disabled=${!app.hasPermission("manage.planning")}
                    >
                        <i class="calendar-alt"></i>
                        <div class="button-label horizontal center-aligning layout">
                            <div class="stretch">Planung</div>
                        </div>
                    </button>

                    <button
                        @click=${() => this.go("reports")}
                        ?active=${this._page === "reports"}
                        ?disabled=${!app.hasPermission("manage.reports")}
                    >
                        <i class="chart-pie"></i>
                        <div class="button-label">Berichte</div>
                    </button>

                    <button @click=${() => this.go("settings")} ?active=${this._page === "settings"}>
                        <i class="cog"></i>
                        <div class="button-label">Einstellungen</div>
                    </button>

                    <button
                        @click=${() => this.go("issues")}
                        ?active=${this._page === "issues"}
                        class="issues-button ${issues.length ? "has-issues" : ""}"
                        ?disabled=${!app.hasPermission("manage.issues")}
                    >
                        <i class="exclamation-triangle"></i>
                        <div class="button-label horizontal center-aligning layout">
                            <div class="stretch">Probleme</div>
                            ${app.state.fetchingIssues
                                ? html`
                                      <ptc-spinner
                                          active
                                          style="font-size: 0.4em; color: inherit; margin: -0.5em 0.5em;"
                                      ></ptc-spinner>
                                  `
                                : html`
                                      <div class="tinier horizontally-margined red pill" style="color: var(--red)">
                                          ${issues.length}
                                      </div>
                                  `}
                        </div>
                    </button>

                    <button
                        @click=${() => this.go("exports")}
                        ?active=${this._page === "exports"}
                        ?disabled=${!app.hasPermission("manage.exports")}
                    >
                        <i class="download"></i>
                        <div class="button-label">Datenexport</div>
                    </button>

                    <div class="stretch"></div>

                    <button class="help-button" @click=${() => openHelp()}>
                        <i class="question"></i>
                        <div class="button-label">Hilfe</div>
                    </button>
                </div>

                <div class="main-views stretch">
                    <ptc-time-logging ?hidden=${this._page !== "time"}></ptc-time-logging>

                    <ptc-employees ?hidden=${this._page !== "employees"}></ptc-employees>

                    <ptc-settings ?hidden=${this._page !== "settings"} @logout=${this._logout}></ptc-settings>

                    <ptc-roster ?hidden=${this._page !== "roster"}></ptc-roster>

                    <ptc-recruiting ?hidden=${this._page !== "recruiting"}></ptc-recruiting>

                    <ptc-issues ?hidden=${this._page !== "issues"}></ptc-issues>

                    <ptc-revenues ?hidden=${this._page !== "revenues"}></ptc-revenues>

                    <ptc-planning ?hidden=${this._page !== "planning"}></ptc-planning>

                    <ptc-reports ?hidden=${this._page !== "reports"}></ptc-reports>

                    <ptc-exports ?hidden=${this._page !== "exports"}></ptc-exports>
                </div>
            </div>

            <ptc-login ?hidden=${this._page !== "login"}></ptc-login>

            <ptc-select-company ?hidden=${this._page !== "select_company"}></ptc-select-company>

            <ptc-set-password ?hidden=${this._page !== "setpwd"}></ptc-set-password>

            <slot></slot>
        `;
    }
}
