import { ExportFormat } from "@pentacode/core";
import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";

@customElement("ptc-exports-headers")
export class ExportsHeaders extends LitElement {
    @property({ type: String })
    page: ExportFormat;

    static styles = [
        shared,
        css`
            .text-embedded-icon {
                transform: translateY(2px);
            }

            .text-embedded-icon > i {
                transform: translateY(2px);
                line-height: 1em;
                height: 1em;
            }
        `,
    ];

    render() {
        switch (this.page) {
            case ExportFormat.PayrollReportDetailed:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohnbericht (Detailliert)</h2>
                        <div class="subtle margined">
                            Dieser Bericht kommt in PDF-Form und enthält <strong>Arbeitszeiten</strong>,
                            <strong>Abwesenheiten</strong>, <strong>Zuschläge</strong>,
                            <strong>Vertragsänderungen</strong> und alle anderen lohn-relevanten Informationen innerhalb
                            des gewählten Zeitraums inklusive einer <strong>Auswertung</strong>.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollReportOverview:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohnbericht (Übersicht)</h2>
                        <div class="subtle margined">
                            Dieser Bericht kommt in PDF-Form und enthält <strong>Arbeitszeiten</strong>,
                            <strong>Abwesenheiten</strong>, <strong>Zuschläge</strong>,
                            <strong>Vertragsänderungen</strong> und alle anderen lohn-relevanten Informationen innerhalb
                            des gewählten Zeitraums inklusive einer <strong>Auswertung</strong>.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollDatevLG:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Datev Lohn & Gehalt</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte
                            <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.datev.de/web/de/datev-shop/personalwirtschaft/lohn-und-gehalt-comfort/"
                                target="_blank"
                            >
                                Datev Lohn & Gehalt
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollDatevLodas:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Datev Lodas</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.datev.de/web/de/datev-shop/personalwirtschaft/lodas-comfort/"
                                target="_blank"
                            >
                                Datev Lodas
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollAgenda:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Agenda</h2>
                        <div class="subtle margined">
                            Eine Textdatei über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://www.agenda-software.de/unternehmen/lohn-software.php" target="_blank">
                                Agenda
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollLexware:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Lexware</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://shop.lexware.de/lohn" target="_blank">Lexware Lohn und Gehalt</a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollAddison:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Addison</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.wolterskluwer.com/de-de/solutions/addison-komplettloesung-mittelstand/addison-lohn-und-gehaltsabrechnung-sme"
                                target="_blank"
                            >
                                Addison
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollLohnAG:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport LohnAG</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://www.lohn-ag.de/" target="_blank"> LohnAG </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollBMDWages:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport BMD</h2>
                        <div class="subtle margined">
                            Eine CSV-Datei mit Lohndaten, die über die <strong>Schnittstelle</strong> von
                            <a
                                href="https://www.bmd.at/Portaldata/1/Resources/help/27.25/OES/Documents/1165317561018012300.html#Textmarke_4"
                                target="_blank"
                            >
                                BMD
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollBMDAbsences:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> BMD Nichtleistungszeiten</h2>
                        <div class="subtle margined">
                            Eine CSV-Datei mit angefallenen Nichtleistungszeiten, die über die
                            <strong>Schnittstelle</strong> von
                            <a
                                href="hhttps://www.bmd.at/Portaldata/1/Resources/help/27.01/OES/Documents/1173103919002700700.html"
                                target="_blank"
                            >
                                BMD
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.TimeSheet:
                return html`
                    <div class="text-centering">
                        <h2><i class="list-timeline"></i> Arbeitszeit-Nachweis</h2>
                        <div class="subtle margined">
                            Eine Auflistung aller <strong>Arbeitszeiten</strong> und <strong>Fehltage</strong> zur
                            Aushändigung an und Unterschrift durch den Mitarbeiter.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookDatev:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export DATEV Buchungsstapel</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            im <strong>DATEV Buchungstapel</strong> Format, welches direkt nach DATEV importiert werden
                            kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookDatevOnline:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export DATEV Online</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            in einem Format, welches direkt nach <strong>DATEV Online</strong> importiert werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookLexware:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export Lexware</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            im ein Format, welches direkt in das Buchhaltungs Lexware eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookGeneric:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export Generisch</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            als generisches CSV-Format.
                        </div>
                    </div>
                `;
            case ExportFormat.EmployeeDataPDF:
                return html`
                    <div class="text-centering">
                        <h2><i class="address-card"></i> Mitarbeiter Datenblatt</h2>
                        <div class="subtle margined">
                            Die Stammdaten Ihrer Mitarbeiter auf einer übersichtlichen PDF-Seite.
                        </div>
                    </div>
                `;
            case ExportFormat.EmployeeDataCSV:
                return html`
                    <div class="text-centering">
                        <h2><i class="address-card"></i> Mitarbeiter Stammdaten</h2>
                        <div class="subtle margined">
                            Die Stammdaten Ihrer Mitarbeiter als tabellarischer CSV-Export.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesTimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Arbeitszeitkonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Arbeitszeitkonten</strong> für den gewählten Zeitraum im Microsoft Excel Format.
                            Über die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesVacationExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Urlaubskonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Urlaubskonten</strong> für den gewählten Zeitraum im Microsoft Excel Format. Über
                            die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesBonusesExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Zuschlagskonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Zuschlagskonten</strong> für den gewählten Zeitraum im Microsoft Excel Format. Über
                            die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.RosterExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Dienstplan Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Darstellung des Dienstplans für den gewählten Zeitraum im
                            Microsoft Excel Format.
                        </div>
                    </div>
                `;
            case ExportFormat.OvertimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Überstunden Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Auflistung der angefallenen Überstunden für den gewählten
                            Zeitraum im Microsoft Excel Format.
                        </div>
                    </div>
                `;
            case ExportFormat.AverageWorkTimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Durchschnittliche Arbeitszeit Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Auflistung der durchschnittlichen Arbeitszeit pro Interval im
                            gewählten Zeitraum im Microsoft Excel Format.
                        </div>
                    </div>
                `;
            case ExportFormat.EmployeeTableExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Individuelle Excel-Tabelle</h2>
                        <div class="subtle margined">
                            Dieser Export bietet die Möglichkeit, Mitarbeiterdaten im Microsoft Excel-Format zu
                            exportieren. Nutzen Sie <strong>Filter</strong> und die
                            <span class="slim transparent semibold text-embedded-icon">
                                <i class="toggle-on large"></i>
                            </span>
                            <strong> Spaltenauswahl</strong>, um die tabellarische Darstellung nach Ihren Bedürfnissen
                            anzupassen.
                        </div>
                    </div>
                `;
        }
    }
}
