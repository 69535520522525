import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { aggregateTimeReportItems, getRange, toDurationString } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./date-range-picker";
import "./avatar";
import "./employees-filter";
import "./spinner";
import "./popover";
import { CommitTimesDialog } from "./commit-times-dialog";
import { singleton } from "../lib/singleton";
import { TimeEntryType, timeEntryTypeColor } from "@pentacode/core/src/model";
import { formatFraction } from "../lib/lit-dependent-util";
import { virtualize } from "@lit-labs/virtualizer/virtualize.js";
import { EmployeeSortProperty, serializeFilters } from "@pentacode/core/src/filters";
import { DateRange } from "@pentacode/core/src/time";
import { BatchHourAdjustmentDialog } from "./batch-hour-adjustment-dialog";
import { GetTimeReportsParams, TimeReport } from "@pentacode/core/src/api";
import { serializeColumOptions } from "@pentacode/core";

@customElement("ptc-employees-time-all")
export class EmployeesTimeAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/time$/;

    get routeTitle() {
        return "Arbeitszeiten: Alle Mitarbeiter";
    }

    @state()
    private _reports: TimeReport[] = [];

    @state()
    private _loading = false;

    @singleton("ptc-commit-times-dialog")
    private _commitTimesDialog: CommitTimesDialog;

    @singleton("ptc-batch-hour-adjustments-dialog")
    private _batchHourAdjustmentDialog: BatchHourAdjustmentDialog;

    protected defaultRange = () => getRange(new Date(), "month");
    protected rangeChanged = () => this.load();

    async load() {
        if (this._loading || !this.dateRange) {
            return;
        }

        this._loading = true;

        try {
            this._reports = await app.api.getTimeReports(
                new GetTimeReportsParams({
                    ...this.dateRange,
                    filters: app.employeeFilters.filters,
                    resolution: "none",
                    types: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                    ],
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _addBatchHourAdjustment() {
        await this._batchHourAdjustmentDialog.show({
            filters: app.employeeFilters.filters,
        });
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
            }

            .company {
                text-align: right;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr 4rem 6rem 4rem 6rem 4rem 6rem;
                align-items: center;
                width: 100%;
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th,
            td {
                padding: 0.5em;
                vertical-align: middle;
            }

            thead {
                font-weight: bold;
                background: var(--color-bg);
                text-align: center;
            }

            thead th[scope="col"] {
                text-align: left;
            }

            tbody.total {
                font-weight: bold;
                font-size: var(--font-size-larger);
                position: sticky;
                top: 4em;
                z-index: 2;
                background: var(--color-bg);
                border-bottom: solid 1px var(--shade-2);
            }
        `,
    ];

    render() {
        const reports = this._filterAndSortReports();
        const total = aggregateTimeReportItems(reports.flatMap((r) => r.items));

        return html`
            <div class="center-aligning horizontal layout padded">
                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                    .range=${this.dateRange}
                    maxDays=${366}
                ></ptc-date-range-picker>

                <div class="stretch"></div>

                <button class="transparent slim">
                    <i class="plus"></i>
                </button>

                <ptc-popover class="popover-menu" hide-on-click>
                    <button @click=${() => this._addBatchHourAdjustment()} class="small">
                        <i class="book"></i>
                        Zeitbuchung
                    </button>
                </ptc-popover>

                <button
                    class="slim transparent"
                    title="Arbeitszeiten als Excel-Datei Exportieren"
                    @click="${() => {
                        this.go("exports/employee_table_excel", {
                            from: this.dateRange?.from,
                            to: this.dateRange?.to,
                            filters: serializeFilters(app.employeeFilters.filters),
                            columns: serializeColumOptions([
                                { type: "fullId" },
                                { type: "timeReportDays" },
                                { type: "timeReportHours" },
                                { type: "vacationReportDays" },
                                { type: "vacationReportHours" },
                                { type: "sickReportDays" },
                                { type: "sickReportHours" },
                            ]),
                        });
                    }}"
                >
                    <i class="file-excel"></i>
                </button>

                <button
                    class="transparent slim right-margined"
                    title="Arbeitszeiten Exportieren"
                    @click=${() =>
                        this.go("exports/time_sheet", {
                            filters: serializeFilters(app.employeeFilters.filters),
                            ...this.dateRange,
                        })}
                >
                    <i class="download"></i>
                </button>

                <button
                    class="transparent slim right-margined"
                    @click=${() => this._commitTimesDialog.show()}
                    ?hidden=${!app.hasPermission("manage.settings.company")}
                    title="Arbeitzeiten & Konten Festschreiben"
                >
                    <i class="file-lock"></i>
                </button>
            </div>

            <ptc-employees-filter
                class="border-top border-bottom"
                @change=${() => this.load()}
                .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
            ></ptc-employees-filter>

            <div class="scroller">
                <div class="double-padded">
                    <table class="fill-horizontally">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="colgroup" colspan="2" style="grid-column: span 2;">
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Work)}"></i> Arbeit
                                </th>
                                <th
                                    scope="colgroup"
                                    class="number colored-text"
                                    colspan="2"
                                    style="--color-highlight: ${timeEntryTypeColor(
                                        TimeEntryType.Vacation
                                    )}; grid-column: span 2;"
                                >
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Vacation)}"></i> Urlaub
                                </th>
                                <th
                                    scope="colgroup"
                                    class="number colored-text"
                                    colspan="2"
                                    style="--color-highlight: ${timeEntryTypeColor(
                                        TimeEntryType.Sick
                                    )}; grid-column: span 2;"
                                >
                                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Sick)}"></i> Krank
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                                <th scope="col" class="smaller">Tage</th>
                                <th scope="col" class="smaller">Stunden</th>
                            </tr>
                        </thead>

                        <tbody class="total">
                            <tr>
                                <th scope="row"><i class="people-group"></i> Gesamt</th>
                                <td>${formatFraction(total.work.days || 0)}</td>
                                <td>${toDurationString(total.work.hours)}</td>
                                <td>${formatFraction(total.vacation.days || 0)}</td>
                                <td>${toDurationString(total.vacation.hours)}</td>
                                <td>${formatFraction(total.sick.days || 0)}</td>
                                <td>${toDurationString(total.sick.hours)}</td>
                            </tr>
                        </tbody>

                        <tbody>
                            ${virtualize({
                                items: reports,
                                renderItem: (report) => {
                                    const employee = app.getEmployee(report.employeeId);
                                    if (!employee) {
                                        return html``;
                                    }

                                    const { work, vacation, sick } = aggregateTimeReportItems(report.items);

                                    return html`
                                        <tr
                                            class="click"
                                            @click=${() => this.go(`employees/${employee.id}/time`, this.dateRange!)}
                                        >
                                            <th>
                                                <div class="horizontal center-aligning spacing layout employee-header">
                                                    <ptc-avatar class="tinier" .employee=${employee}></ptc-avatar>
                                                    <div class="stretch collapse ellispis">
                                                        <span class="semibold">
                                                            ${employee.lastName}, ${employee.firstName}
                                                        </span>
                                                        ${employee.staffNumber
                                                            ? html`
                                                                  <span class="subtle">#${employee.staffNumber}</span>
                                                              `
                                                            : ""}
                                                    </div>
                                                </div>
                                            </th>
                                            <td>${formatFraction(work.days)}</td>
                                            <td>${toDurationString(work.hours)}</td>
                                            <td>${formatFraction(vacation.days)}</td>
                                            <td>${toDurationString(vacation.hours)}</td>
                                            <td>${formatFraction(sick.days)}</td>
                                            <td>${toDurationString(sick.hours)}</td>
                                        </tr>
                                    `;
                                },
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }

    private _filterAndSortReports(): TimeReport[] {
        const filteredEmployeeOrder = new Map(app.getFilteredEmployees().map((e, index) => [e.id, index]));

        return this._reports
            .filter((r) => filteredEmployeeOrder.has(r.employeeId))
            .sort((a, b) => {
                return (filteredEmployeeOrder.get(a.employeeId) ?? 0) - (filteredEmployeeOrder.get(b.employeeId) ?? 0);
            });
    }
}
