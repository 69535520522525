import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { routeProperty, Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart } from "./chart";
import { Euros } from "@pentacode/openapi";
import {
    GetSavingsReportsParams,
    SavingsReport,
    getRange,
    monthNames,
    formatNumber as fmtNum,
    parseDateString,
    formatMonthShort,
    toDateString,
    formatDateShort,
} from "@pentacode/core";
import "./spinner";
import { popover } from "../directives/popover";

const formatNumber = (val: number) => fmtNum(val, 0);

@customElement("ptc-reports-savings")
export class Reports extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/savings/;

    get routeTitle() {
        return "Ersparnisbericht";
    }

    @state()
    private _loading = false;

    @state()
    private _dailyReports: SavingsReport[] = [];

    @state()
    private _monthlyReports: SavingsReport[] = [];

    @routeProperty({ param: "year", type: Number, default: () => new Date().getFullYear() })
    private _year: number;

    @routeProperty({ param: "month", type: Number, default: () => new Date().getMonth() })
    private _month: number;

    @query(".chart-savings-monthly")
    private _chartSavingsMonthly: Chart;

    @query(".chart-savings-daily")
    private _chartSavingsDaily: Chart;

    @query(".chart-savings-gauge")
    private _chartSavingsGauge: Chart;

    @query("#yearSelect")
    private _yearSelect: HTMLSelectElement;

    @query("#monthSelect")
    private _monthSelect: HTMLSelectElement;

    async handleRoute() {
        void this._load();
        await this.updateComplete;
        this._yearSelect.value = this._year.toString();
        this._monthSelect.value = this._month.toString();
    }

    private _yearSelected(e: Event) {
        const year = Number((e.target as HTMLSelectElement).value);
        this.go(null, { month: this._month, year });
    }

    private _monthSelected(e: Event) {
        const month = Number((e.target as HTMLSelectElement).value);
        this.go(null, { month, year: this._year });
    }

    private async _load() {
        if (!this._year) {
            return;
        }

        const currYear = getRange(new Date(this._year, 0, 1), "year");
        const currMonth = getRange(new Date(this._year, this._month, 1), "month");

        this._loading = true;

        try {
            const [monthlyReports, dailyReports] = await Promise.all([
                app.api.getSavingsReports(new GetSavingsReportsParams({ ...currYear, resolution: "month" })),
                app.api.getSavingsReports(new GetSavingsReportsParams({ ...currMonth, resolution: "day" })),
            ]);
            this._monthlyReports = monthlyReports;
            this._dailyReports = dailyReports;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
        this._updateCharts();
    }

    private _getChartConfigSavingsMonthly() {
        const series = [
            {
                name: "Ersparnis (€)",
                data: this._monthlyReports.map((r) => ({
                    x: formatMonthShort(r.from, false),
                    y: r.savings,
                    goals: [
                        {
                            name: "Gebühren",
                            value: r.fees,
                            strokeHeight: 2,
                            strokeColor: colors.red,
                            strokeDashArray: 2,
                        },
                    ],
                })),
                type: "bar",
            },
            {
                type: "line",
                name: "Ersparnis (%)",
                data: this._monthlyReports.map((r) => ({
                    x: formatMonthShort(r.from, false),
                    y: r.actualCosts ? (r.savings / r.actualCosts) * 100 : 0,
                })),
            },
        ];

        const labels = this._monthlyReports.map((report) => formatMonthShort(report.from, false));

        return {
            chart: {
                type: "line" as const,
                height: 300,
                selection: { enabled: false },
                events: {
                    click: (_event: unknown, _context: unknown, { dataPointIndex }: { dataPointIndex: number }) => {
                        this.go(null, { month: dataPointIndex, year: this._year });
                    },
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            series,
            labels,
            xaxis: {
                type: "category" as const,
            },
            colors: [colors.blue, colors.green],
            yaxis: [
                {
                    opposite: true,
                    title: {
                        text: "Ersparnis (€)",
                        style: {
                            color: colors.blue,
                        },
                    },
                    labels: {
                        formatter: (val: number) => formatNumber(val),
                        style: {
                            colors: colors.blue,
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: colors.blue,
                    },
                    axisTicks: {
                        show: true,
                        color: colors.blue,
                    },
                },
                {
                    seriesName: "Ersparnis (%)",
                    opposite: true,
                    title: {
                        text: "Ersparnis (%)",
                        style: {
                            color: colors.green,
                        },
                    },
                    labels: {
                        formatter: (val: number) => fmtNum(val, 1),
                        style: {
                            colors: colors.green,
                        },
                    },
                    axisTicks: {
                        show: true,
                        color: colors.green,
                    },
                    axisBorder: {
                        show: true,
                        color: colors.green,
                    },
                },
            ],
            tooltip: {
                y: [
                    {
                        formatter: (val: number) => formatNumber(val) + " €",
                    },
                    {
                        formatter: (val: number) => fmtNum(val, 1) + " %",
                    },
                ],
            },
            plotOptions: {
                bar: {
                    columnWidth: "60%",
                    borderRadius: 4,
                    borderRadiusApplication: "end" as const,
                },
            },
            stroke: {
                curve: "smooth" as const,
                width: [1, 4],
            },
        };
    }

    private _getChartConfigSavingsDaily() {
        const today = toDateString(new Date());
        const savings = this._dailyReports.map((report) => ({
            x: parseDateString(report.from),
            y: report.from < today ? report.savings : null,
        }));

        const serviceFees = this._dailyReports.reduce((total, report) => total + report.fees, 0);

        for (let i = 1; i < savings.length; i++) {
            if (savings[i].y === null) {
                break;
            }
            savings[i].y = (savings[i].y! + savings[i - 1].y!) as Euros;
        }

        const breakEvenDate = savings.find((s) => s.y || 0 >= serviceFees)?.x;

        const totalSavings = this._dailyReports.reduce((total, report) => total + report.savings, 0);
        const colorStop = totalSavings ? 100 - (serviceFees / totalSavings) * 100 : 0;

        const series = [
            {
                name: "Ersparnis",
                data: savings,
            },
        ];

        return {
            chart: {
                type: "area" as const,
                toolbar: {
                    show: false,
                },
                height: 300,
                zoom: { enabled: false },
                selection: {
                    enabled: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            series,
            xaxis: {
                type: "datetime" as const,
            },
            yaxis: {
                min: 0,
                max: Math.max(totalSavings, serviceFees * 5),
                forceNiceScale: true,
                opposite: true,
                title: {
                    text: "Ersparnis (€)",
                },
                labels: {
                    formatter: (val: number) => formatNumber(val),
                },
            },
            tooltip: { enabled: false, y: { formatter: (val: number) => formatNumber(val) + " €" } },
            annotations: {
                yaxis: [
                    {
                        y: serviceFees,
                        borderColor: colors.blue,
                        strokeDashArray: 3,
                        opacity: 1,
                        label: {
                            position: "left" as const,
                            textAnchor: "start" as const,
                            borderColor: colors.blue,
                            style: {
                                color: "#fff",
                                background: colors.blue,
                                fontSize: "0.9em",
                            },
                            text: breakEvenDate
                                ? `Breakeven: ${formatDateShort(breakEvenDate, true, false)}`
                                : "Breakeven",
                            offsetY: -10,
                            offsetX: 10,
                        },
                    },
                ],
            },
            fill: {
                type: "gradient" as const,
                gradient: {
                    type: "vertical" as const,
                    colorStops: [
                        {
                            offset: 0,
                            color: colors.green,
                            opacity: 1,
                        },
                        {
                            offset: colorStop,
                            color: colors.green,
                            opacity: 0.2,
                        },
                        {
                            offset: colorStop,
                            color: colors.red,
                            opacity: 0.2,
                        },
                        {
                            offset: 100,
                            color: colors.red,
                            opacity: 0.5,
                        },
                    ],
                },
            },
        };
    }

    private _getChartConfigSavingsGauge() {
        const savings = this._dailyReports.reduce((total, report) => total + report.savings, 0);
        const fees = this._dailyReports.reduce((total, report) => total + report.fees, 0);
        const roi = Math.floor((savings / fees) * 100);
        const max = Math.max(savings, fees);
        return {
            series: [(savings / max) * 100, (fees / max) * 100],
            chart: {
                height: 265,
                type: "radialBar" as const,
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        size: "40%",
                        background: "transparent",
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        },
                    },
                    barLabels: {
                        enabled: true,
                        useSeriesColors: true,
                        offsetX: -8,
                        fontSize: "14px",
                        formatter: function (seriesName: string, { seriesIndex }: { seriesIndex: number }) {
                            return `${seriesName}:  ${seriesIndex === 1 ? formatNumber(fees) : formatNumber(savings)} €`;
                        },
                    },
                },
            },
            colors: [colors.blue, colors.red],
            labels: ["Ersparnis", "Gebühren"],
            title: {
                text: `ROI: ${roi}%`,
                align: "center" as const,
                offsetY: 145,
                style: { fontSize: "1em", fontWeight: "700" },
            },
        };
    }

    private _updateCharts() {
        this._chartSavingsMonthly.config = this._getChartConfigSavingsMonthly();
        this._chartSavingsDaily.config = this._getChartConfigSavingsDaily();
        this._chartSavingsGauge.config = this._getChartConfigSavingsGauge();
    }

    static styles = [
        shared,
        css`
            :host {
                display: grid;
                grid-template-columns: 200px 1fr;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
            }

            .charts {
                position: sticky;
                left: 0;
                padding: 0 1em;
                display: flex;
                align-items: center;
            }

            .charts-savings-daily {
                flex: 1;
                width: 0;
            }

            .charts-savings-monthly {
                flex: 1;
                width: 0;
            }

            .charts-savings-gauge,
            .charts-savings-total {
                width: 265px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .charts-savings {
                margin-top: 1em;
            }

            .chart-savings-gauge {
                display: block;
                height: 265px;
                margin-top: -2em;
            }

            .chart-savings-total-euro,
            .chart-savings-total-percent {
                text-align: center;
                font-size: var(--font-size-enormous);
                color: var(--color-highlight);
                font-weight: 300;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const costTotal = this._monthlyReports.reduce((total, s) => total + s.actualCosts, 0);
        const savingsTotal = this._monthlyReports.reduce((total, s) => total + s.savings, 0);
        const savingsPercent = costTotal ? (savingsTotal / costTotal) * 100 : 0;

        return html`
            <div class="fullbleed vertical layout">
                <div class="horizontal padded spacing center-aligning layout border-bottom">
                    <div class="stretch"></div>
                    <i
                        class="info-circle"
                        ${popover(
                            html`
                                Die hier gezeigten Ersparnisse ergeben sich aus den
                                <strong>Regeln, die Sie für die Zeiterfassung definiert haben</strong>. Es werden je
                                nach Einstellung <strong>zu früher Dienstbeginn</strong>, <strong>Verspätung</strong>,
                                <strong>Minutenrundung</strong> und <strong>Raucherpausen</strong> Mitarbeiter-genau
                                erhoben und mit dessen Lohndaten berechnet.
                                <strong
                                    >Dieser Bericht setzt die Nutzung der elektronischen Zeiterfassung von Pentacode
                                    voraus.</strong
                                >
                            `,
                            { trigger: "hover", style: "max-width: 25em;" }
                        )}
                    ></i>
                    <select id="monthSelect" @change=${this._monthSelected} class="slim">
                        ${monthNames.map((month, i) => html` <option .value=${i.toString()}>${month}</option> `)}
                    </select>
                    <select id="yearSelect" @change=${this._yearSelected} class="slim">
                        ${years.map((year) => html` <option .value=${year.toString()}>${year}</option> `)}
                    </select>
                </div>

                <div class="scroller stretch" ?hidden=${!savingsTotal}>
                    <div class="charts charts-savings">
                        <div class="charts-savings-gauge">
                            <div class="charts-title">${monthNames[this._month]} ${this._year}</div>
                            <ptc-chart class="chart-savings-gauge"></ptc-chart>
                        </div>
                        <div class="charts-savings-daily">
                            <ptc-chart class="chart-savings-daily"></ptc-chart>
                        </div>
                    </div>

                    <div class="charts">
                        <div class="charts-savings-total vertical center-justifying layout">
                            <div class="charts-title">${this._year} Gesamt</div>
                            <div class="chart-savings-total-euro blue">${formatNumber(savingsTotal)} €</div>
                            <div class="chart-savings-total-percent green">${fmtNum(savingsPercent, 1)} %</div>
                        </div>

                        <div class="charts-savings-monthly">
                            <ptc-chart class="chart-savings-monthly"></ptc-chart>
                        </div>
                    </div>
                </div>

                <div class="stretch centering layout" ?hidden=${this._loading || !!savingsTotal}>
                    <div class="margined double-padded box">
                        <i class="info-circle"></i>
                        Der Ersparnisbericht erfordert die Nutzung der elektronischen Zeiterfassung.
                    </div>
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
