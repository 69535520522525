import { Venue, Department, TimeEntry, Availability } from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { property, customElement } from "lit/decorators.js";
import { mixins, shared } from "../styles";
import { app } from "../init";
import { parseTimes, toDateString, toTimeString, wait } from "@pentacode/core/src/util";
import { overlap } from "@pentacode/core/src/time";

export const entryPositions = new Map<string, { x: number; y: number }>();

@customElement("ptc-roster-entry")
export class RosterEntry extends LitElement {
    @property({ attribute: false })
    entry: TimeEntry;

    @property({ reflect: true, type: Boolean })
    error: boolean = false;

    @property({ attribute: false })
    department: Department;

    @property({ attribute: false })
    venue: Venue;

    @property({ type: Boolean, reflect: true })
    readonly: boolean = false;

    @property({ type: Boolean })
    displayPublished: boolean = false;

    @property()
    color?: string;

    @property({ type: Number })
    stackSize?: number;

    @property({ type: Boolean, reflect: true })
    condensed = false;

    @property({ type: Boolean })
    animated = false;

    @property({ attribute: false })
    availabilities?: Availability[] = [];

    private _fireRemove(e: Event) {
        e.stopPropagation();
        this.dispatchEvent(new CustomEvent("remove", { detail: { entry: this.entry }, bubbles: true, composed: true }));
    }

    private _dispatchSelect(field: string, e: Event) {
        const otherDepartment =
            this.entry.position && this.department && this.entry.position.departmentId !== this.department.id;
        if (!otherDepartment) {
            this.dispatchEvent(
                new CustomEvent("select", { detail: { entry: this.entry.id, field }, bubbles: true, composed: true })
            );
            e.stopPropagation();
            e.preventDefault();
        }
    }

    async _flip() {
        const id = `${this.department?.id}_${this.entry.id}`;
        const before = entryPositions.get(id);
        const after = this.getBoundingClientRect();
        entryPositions.set(id, after);
        if (before && (before.x !== after.x || before.y !== after.y)) {
            this.style.transition = "";
            this.style.transform = `translate(${before.x - after.x}px, ${before.y - after.y}px)`;
            await wait(Math.random() * 100 + 10);
            this.style.zIndex = "10";
            this.style.opacity = "1";
            this.style.transition = "transform 0.5s";
            this.style.transform = "";
            await wait(500);
            this.style.position = "";
            this.style.zIndex = "";
        } else {
            this.style.opacity = "1";
        }
    }

    updated() {
        if (this.animated) {
            void this._flip();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                border-radius: var(--border-radius);
                display: block;
                cursor: pointer;
                position: relative;
                will-change: transform, opacity;
            }

            .container {
                position: relative;
                overflow: hidden;
                border-radius: var(--border-radius);
                border: solid 1px;
                color: var(--color-highlight);
                z-index: 1;
                background: var(--color-bg);
            }

            :host(.selected) .container {
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            :host([readonly]) .container {
                pointer-events: none;
            }

            .container:hover .status {
                display: none;
            }

            .delete-button {
                padding: 0.2em;
                margin: -0.3em;
                border-radius: 100%;
                background: transparent;
                color: inherit;
            }

            .container:not(:hover) .delete-button {
                display: none;
            }

            .dash {
                font-size: var(--font-size-smaller);
                vertical-align: middle;
                font-weight: 900;
                position: relative;
                margin: 0 -0.1em;
            }

            .type {
                text-align: center;
                padding: 0.7em;
                font-size: var(--font-size-huger);
            }

            .status {
                font-weight: 600;
                margin: -0.1em;
            }

            .time {
                font-size: 1.55em;
                margin: 0 0.1em 0.1em 0.1em;
            }

            .time > button {
                padding: 0 0.2em;
                letter-spacing: 0.05em;
                font-weight: bold;
                color: inherit;
            }

            .subtle {
                border: none;
            }

            .subtle * {
                font-weight: normal !important;
            }

            .subtle button {
                pointer-events: none;
                color: inherit;
            }

            .subtle > .status,
            .subtle > .delete-button {
                display: none !important;
            }

            .stack-1,
            .stack-2 {
                border-radius: var(--border-radius);
                border: solid 1px;
                color: var(--color-highlight);
                background: var(--color-bg);
                ${mixins.fullbleed()};
                transition: transform 0.1s;
            }

            .stack-1 {
                transform: scale(0.98) translateY(-2px);
                z-index: -1;
            }

            .stack-2 {
                transform: scale(0.96) translateY(-4px);
                z-index: -2;
            }

            :host(:hover) .stack-1,
            :host(:hover) .stack-2 {
                transform: none;
            }

            :host([condensed]) .type {
                padding: 0.16em;
            }

            :host([condensed]) .time {
                margin-top: 0.1em;
            }
        `,
    ];

    render() {
        const a = this.entry;

        const { venue, department } = (a.position && app.getDepartment(a.position.departmentId)) || {
            venue: null,
            department: null,
        };

        const otherVenue = a.position && this.venue && (!venue || venue.id !== this.venue.id);
        const otherDep = a.position && this.department && (!department || department.id !== this.department.id);
        const depName = (department && department.name) || "[Archiv. Pos.]";
        const venueName = (venue && venue.name) || "[Archiv. Pos.]";
        const color = this.color || app.getTimeEntryColor(this.entry);
        const isPast = this.entry.date < toDateString(new Date());

        const start =
            this.displayPublished && a.published
                ? a.published.startPlanned
                : a.startFinal || a.endFinal
                  ? toTimeString(a.startFinal)
                  : toTimeString(a.startPlanned);
        const end =
            this.displayPublished && a.published
                ? a.published.endPlanned
                : a.endFinal
                  ? toTimeString(a.endFinal)
                  : toTimeString(a.endPlanned);

        const planned = this.entry.planned;

        const availability = this.availabilities?.find((av) => {
            const interval = parseTimes(av.date, av.start || "00:00", av.end || "24:00") as [Date, Date];
            return planned && overlap(planned, [interval]) > 0;
        });

        return a.position
            ? html`
                  <div
                      class="container ${otherDep ? "subtle" : ""}"
                      style="--color-highlight: ${color};"
                      @click=${(e: Event) => this._dispatchSelect("start", e)}
                  >
                      <div
                          class="horizontal center-aligning layout"
                          style="margin: 0.4em 0.4em 0 0.6em"
                          ?hidden=${this.condensed}
                      >
                          <div class="semibold stretch collapse ellipsis">
                              ${this.stackSize && this.stackSize > 1 ? html`<strong>${this.stackSize} x </strong>` : ""}
                              ${otherVenue ? venueName : otherDep ? `${depName} / ${a.position.name}` : a.position.name}
                          </div>

                          ${availability
                              ? html`
                                    <div class="status" style="margin-right: 0">
                                        <i style="color: ${availability.color}" class="${availability.icon}"></i>
                                    </div>
                                `
                              : ""}

                          <button class="delete-button" @click=${this._fireRemove} tabindex="-1">
                              <i class="times"></i>
                          </button>

                          ${this.error
                              ? html`
                                    <div class="issue status">
                                        <i class="exclamation-triangle"></i>
                                    </div>
                                `
                              : !a.position.active
                                ? html`
                                      <div class="issue status">
                                          <i class="exclamation-triangle"></i>
                                      </div>
                                  `
                                : a.preview
                                  ? html`
                                        <div class="status">
                                            <i class="floppy-disk-circle-arrow-right"></i>
                                        </div>
                                    `
                                  : a.startFinal
                                    ? html`
                                          <div class="status">
                                              <i class="${a.endFinal ? "check" : "clock"}"></i>
                                          </div>
                                      `
                                    : a.offered && a.offered > a.updated && (!a.taken || a.offered > a.taken)
                                      ? html`
                                            <div class="status">
                                                <i class="hand-holding-magic"></i>
                                            </div>
                                        `
                                      : a.offered && a.taken && a.taken > a.offered && a.taken > a.updated
                                        ? html`
                                              <div class="status">
                                                  <i class="people-arrows"></i>
                                              </div>
                                          `
                                        : a.taken && a.taken >= a.updated
                                          ? html`
                                                <div class="status">
                                                    <i class="thumbs-up"></i>
                                                </div>
                                            `
                                          : a.isPublished && a.isSeen
                                            ? html`
                                                  <div class="status">
                                                      <i class="eye"></i>
                                                  </div>
                                              `
                                            : a.isPublished
                                              ? html`
                                                    <div class="status">
                                                        <i class="paper-plane"></i>
                                                    </div>
                                                `
                                              : html`
                                                    <div class="status">
                                                        <i class="pencil-alt"></i>
                                                    </div>
                                                `}
                      </div>

                      <div class="horizontal center-aligning layout time">
                          <button
                              class="transparent ${isPast && !a.startFinal ? "faded" : ""}"
                              @click=${(e: Event) => this._dispatchSelect("start", e)}
                              tabindex="-1"
                          >
                              ${start || html`<span class="semibold">offen</span>`}
                          </button>

                          <span class="dash">&ndash;</span>

                          <button
                              class="transparent ${(isPast || a.startFinal) && !a.endFinal ? "faded" : ""}"
                              @click=${(e: Event) => this._dispatchSelect("end", e)}
                              tabindex="-1"
                          >
                              ${end || html`<span class="semibold">offen</span>`}
                          </button>
                      </div>
                  </div>
                  ${this.stackSize && this.stackSize > 1
                      ? html` <div class="stack-1" style="--color-highlight: ${color};"></div> `
                      : ""}
                  ${this.stackSize && this.stackSize > 2
                      ? html` <div class="stack-2" style="--color-highlight: ${color};"></div> `
                      : ""}
              `
            : html`
                  <div
                      class="container"
                      style="--color-highlight: ${color}"
                      @click=${(e: Event) => this._dispatchSelect("start", e)}
                  >
                      <div class="type">${app.localized.timeEntryTypeLabel(a.type)}</div>

                      <button
                          class="delete-button absolute margined"
                          style="top: 0.5em; right: 0.5em;"
                          @click=${this._fireRemove}
                          tabindex="-1"
                      >
                          <i class="times"></i>
                      </button>

                      ${this.error
                          ? html`
                                <div class="issue status">
                                    <i class="exclamation-triangle"></i>
                                </div>
                            `
                          : ""}
                  </div>
              `;
    }
}
