import {
    Employee,
    EmploymentType,
    EntityFilter,
    TimeEntryType,
    getEmploymentTypes,
    timeEntryTypeColor,
} from "@pentacode/core/src/model";
import {
    getRange,
    toDateString,
    parseDateString,
    monthNames,
    formatNumber as fmtNum,
    throttle,
} from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart, ChartConfig } from "./chart";
import "./spinner";
import { DateString } from "@pentacode/openapi";
import { CostReport, GetCostReportsParams } from "@pentacode/core/src/api";
import "./entity-multi-select";
import "./entity-filters";
import type { EntityFiltersEl } from "./entity-filters";
import { Checkbox } from "./checkbox";
import type { EntityMultiSelect } from "./entity-multi-select";

const formatNumber = (val: number) => fmtNum(val, 0);

interface ChartSeries {
    name: string;
    data: number[];
    type?: string;
    color: string;
    width?: number;
    dashArray?: number;
}

const components = [
    {
        name: "Basislohn",
        key: "base",
        icon: "digging",
        color: colors.green,
    },
    {
        name: "Pausen",
        key: "breaks",
        icon: "coffee",
        color: colors.orange,
    },
    {
        name: "Provision",
        key: "commission",
        icon: "euro-sign",
        color: colors.red,
    },
    {
        name: "Zuschläge",
        key: "bonuses",
        icon: "badge-percent",
        color: colors.purple,
    },
    {
        name: "Mitarbeiteressen",
        key: "meals",
        icon: "utensils",
        color: colors.teal,
    },
];

const employmentTypes = getEmploymentTypes();

const timeEntryTypes = [
    TimeEntryType.Work,
    TimeEntryType.Vacation,
    TimeEntryType.Sick,
    TimeEntryType.ChildSick,
    TimeEntryType.SickInKUG,
    TimeEntryType.CompDay,
    TimeEntryType.HourAdjustment,
    TimeEntryType.VacationAdjustment,
];

@customElement("ptc-reports-costs")
export class Reports extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/costs/;

    get routeTitle() {
        return "Personalkostenbericht";
    }

    @state()
    private _loading = false;

    @state()
    private _reports: CostReport[] = [];

    @state()
    private _selectedEmployee: Employee | null = null;

    @state()
    private _segmentation: "type" | "component" | "employment" | "workArea" = "workArea";

    @state()
    private _filterString = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query(".chart-cost-monthly")
    private _chartCostMonthly: Chart;

    @query(".chart-cost-total")
    private _chartCostTotal: Chart;

    @query("ptc-entity-filters")
    private _entityFilters: EntityFiltersEl;

    @query("#totalCheckbox")
    private _totalCheckbox: Checkbox;

    @query("#ancillaryCostsCheckbox")
    private _ancillaryCostsCheckbox: Checkbox;

    @query("#typeSelector")
    private _typeSelector: EntityMultiSelect<TimeEntryType>;

    @query("#componentSelector")
    private _componentSelector: EntityMultiSelect<(typeof components)[number]>;

    @query("#employmentTypeSelector")
    private _employmentTypeSelector: EntityMultiSelect<(typeof employmentTypes)[number]>;

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _cachedCosts = new Map<string, number>();

    handleRoute() {
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
        void this.load();
    }

    updated(changes: Map<string, unknown>) {
        if (
            (changes.has("_reports") || changes.has("_segmentation") || changes.has("_selectedEmployee")) &&
            this._reports.length &&
            this._chartCostMonthly &&
            this._chartCostTotal
        ) {
            this._cachedCosts.clear();
            this._entityFilters.filters =
                app.accessibleVenues.length > 1
                    ? app.accessibleVenues.map((venue) => ({ type: "venue", value: venue.id }) as EntityFilter)
                    : app.accessibleDepartments.map(
                          (department) =>
                              ({
                                  type: "department",
                                  value: department.id,
                              }) as EntityFilter
                      );
            this._typeSelector.selected = timeEntryTypes;
            this._componentSelector.selected = components;
            this._employmentTypeSelector.selected = employmentTypes;
            this.requestUpdate();
            this._updateCharts();
        }
    }

    async load() {
        if (!this.year) {
            return;
        }

        let { from, to } = getRange(`${this.year}-01-01` as DateString, "year");

        const currMonth = getRange(toDateString(new Date()), "month");

        if (to > currMonth.to) {
            to = currMonth.to;
        }

        this._loading = true;

        try {
            this._reports = await app.api.getCostReports(
                new GetCostReportsParams({
                    from,
                    to,
                    resolution: "month",
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _getCosts({
        type,
        component,
        month,
        employeeId,
        employmentType,
        departmentId,
        venueId,
    }: {
        type?: TimeEntryType;
        component?: (typeof components)[number]["key"];
        month?: number;
        employeeId?: number;
        employmentType?: EmploymentType;
        departmentId?: number;
        venueId?: number;
    }) {
        const includeAncillaryCosts = this._ancillaryCostsCheckbox?.checked || false;
        const cacheKey = `${includeAncillaryCosts}_${type || "all"}_${component || "all"}_${typeof month === "number" ? month : "all"}_${employeeId || "all"}_${typeof employmentType === "number" ? employmentType : "all"}_${departmentId || "all"}_${venueId || "all"}`;

        if (this._cachedCosts.has(cacheKey)) {
            return this._cachedCosts.get(cacheKey)!;
        }

        let reports = this._reports;

        if (typeof employeeId === "number") {
            reports = reports.filter((report) => report.employeeId === employeeId);
        }
        if (typeof month === "number") {
            reports = reports.filter((report) => parseDateString(report.from)?.getMonth() === month);
        }
        if (typeof employmentType === "number") {
            reports = reports.filter((report) => {
                const employee = app.getEmployee(report.employeeId);
                const contract = employee && employee.getContractForRange(report);
                return contract && employmentType === contract.employmentType;
            });
        }

        let items = reports.flatMap((report) => report.items);

        if (typeof departmentId === "number") {
            items = items.filter(
                (item) => item.positionId && app.getPosition(item.positionId)?.department?.id === departmentId
            );
        }

        if (typeof venueId === "number") {
            items = items.filter((item) => item.positionId && app.getPosition(item.positionId)?.venue?.id === venueId);
        }

        if (type) {
            items = items.filter((item) => item.type === type);
        }

        const prop = includeAncillaryCosts ? ("totalCosts" as const) : ("wages" as const);

        const costs = items.reduce((total, item) => {
            switch (component) {
                case "base":
                    return total + item.base[prop];
                case "breaks":
                    return total + item.breaks[prop];
                case "bonuses":
                    return total + item.bonuses[prop];
                case "commission":
                    return total + item.commission[prop];
                case "meals":
                    return total + item.meals[prop];
                default:
                    return (
                        total +
                        item.base[prop] +
                        item.breaks[prop] +
                        item.bonuses[prop] +
                        item.meals[prop] +
                        item.commission[prop]
                    );
            }
        }, 0);

        this._cachedCosts.set(cacheKey, costs);
        return costs;
    }

    private _getCostSeriesByComponent() {
        const employeeId = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return this._componentSelector.selected.map(({ name, key, color }) => ({
            name,
            data: monthNames.map((_n, month) => this._getCosts({ component: key, employeeId: employeeId, month })),
            color,
        }));
    }

    private _getCostSeriesByType() {
        const employeeId = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return this._typeSelector.selected.map((type) => ({
            name: app.localized.timeEntryTypeLabel(type),
            data: monthNames.map((_n, month) => this._getCosts({ type, employeeId, month })),
            color: timeEntryTypeColor(type) || colors.brown,
        }));
    }

    private _getCostSeriesByEmploymentType() {
        const employeeId = (this._selectedEmployee && this._selectedEmployee.id) || undefined;

        return this._employmentTypeSelector.selected.map(({ type, label, color }) => ({
            name: label,
            data: monthNames.map((_n, month) =>
                this._getCosts({ employeeId: employeeId, month, employmentType: type })
            ),
            color,
        }));
    }

    private _getCostSeriesByWorkarea() {
        const colorsArr = Object.values(colors);
        const employee = (this._selectedEmployee && this._selectedEmployee.id) || undefined;
        const filters = this._entityFilters.filters.filter((f) => f.type === "department" || f.type === "venue");

        let venueCount = 0;
        const series = filters.map((filter) => {
            switch (filter.type) {
                case "department": {
                    const department = app.getDepartment(filter.value).department!;
                    return {
                        name: department.name,
                        data: monthNames.map((_n, month) =>
                            this._getCosts({ employeeId: employee, month, departmentId: department.id })
                        ),
                        color: colors[department.color] || department.color,
                    };
                }
                case "venue": {
                    const venue = app.getVenue(filter.value)!;
                    venueCount++;
                    return {
                        name: venue.name,
                        data: monthNames.map((_n, month) =>
                            this._getCosts({ employeeId: employee, month, venueId: venue.id })
                        ),
                        color: colorsArr[(venueCount - 1) % colorsArr.length],
                        dashArray: 5,
                        width: 2,
                    };
                }
            }
        });

        return series;
    }

    private _getChartConfigCostMonthly(series: ChartSeries[]): ChartConfig {
        const employeeId = this._selectedEmployee?.id;

        if (this._totalCheckbox.checked) {
            series = [
                {
                    name: "Gesamt",
                    data: monthNames.map((_n, month) => this._getCosts({ employeeId, month })),
                    color: colors.black,
                    width: 3,
                },
                ...series,
            ];
        }
        return {
            chart: {
                type: "line",
                stacked: false,
                height: 400,
                selection: { enabled: false },
            },
            legend: {
                show: false,
            },
            series: series,
            labels: monthNames.map((n) => n.slice(0, 3)),
            xaxis: {
                type: "category",
            },
            yaxis: {
                title: { text: "Personalkosten (Tausend Euro)" },
                labels: {
                    formatter: (val: number) => (val / 1000).toFixed(0),
                },
                opposite: true,
                forceNiceScale: true,
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
                x: {
                    formatter: (val: number) => `${monthNames[val - 1]} ${this.year}`,
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            stroke: {
                width: series.map((s) => s.width || 1),
                dashArray: series.map((s) => s.dashArray || 0),
                colors: series.map((s) => s.color),
                show: true,
                curve: "smooth",
            },
        };
    }

    private _getChartConfigCostTotal(monthlySeries: ChartSeries[]): ChartConfig {
        const series = monthlySeries.map((s) => s.data.reduce((total: number, val: number) => total + val, 0));
        const labels = monthlySeries.map((s) => s.name);
        const clrs = monthlySeries.map((s) => s.color);

        const includeOther = this._totalCheckbox?.checked;

        if (includeOther) {
            const total = this._getCosts({
                employeeId: this._selectedEmployee?.id,
            });
            const other = total - series.reduce((total, val) => total + val, 0);
            series.push(other);
            labels.push("Sonstige");
            clrs.push(colors.grey);
        }

        const seriesTotal = series.reduce((total, val) => total + val, 0);

        return {
            chart: {
                type: "donut",
                height: "300",
                selection: { enabled: false },
            },
            colors: clrs,
            legend: {
                show: false,
            },
            labels,
            series,
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: (val: number) => formatNumber(val) + " €",
                },
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                label: includeOther ? "Gesamt" : "Auswahl",
                                showAlways: true,
                                show: true,
                                formatter: () => formatNumber(seriesTotal) + " €",
                            },
                            value: {
                                formatter: (val: any) => formatNumber(val) + " €",
                            },
                        },
                    },
                },
            },
        };
    }

    private _updateCharts = throttle(() => {
        const series: ChartSeries[] =
            this._segmentation === "employment"
                ? this._getCostSeriesByEmploymentType()
                : this._segmentation === "workArea"
                  ? this._getCostSeriesByWorkarea()
                  : this._segmentation === "type"
                    ? this._getCostSeriesByType()
                    : this._getCostSeriesByComponent();

        this._chartCostMonthly.config = this._getChartConfigCostMonthly(series);

        this._chartCostTotal.config = this._getChartConfigCostTotal(series);
    }, 100);

    private _yearSelected(e: Event) {
        const date = parseDateString(this.date) || new Date();
        date.setFullYear(parseInt((e.target as HTMLSelectElement).value));
        this.go(null, { date: toDateString(date) });
    }

    private _selectEmployee(employee: Employee | null) {
        this._selectedEmployee = employee;
        this.renderRoot.querySelector(".charts")!.scrollIntoView();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: grid;
                grid-template-columns: 200px 1fr;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
            }

            .charts {
                position: sticky;
                left: 0;
                padding: 0 1em;
                display: flex;
                align-items: center;
                z-index: 10;
            }

            .charts-cost-total {
                flex: 1;
            }

            .charts-cost-monthly {
                flex: 3;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .table {
                margin: 0 0.5em;
            }

            .row {
                display: grid;
                grid-template-columns: 12em repeat(13, 1fr);
                background: var(--color-bg);
                min-width: 75em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .column {
                padding: 0.5em;
                text-align: right;
                min-width: 0;
            }

            .column:not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 1;
            }

            .header-column {
                text-align: left;
                position: sticky;
                left: 0.5em;
                background: inherit;
                border-radius: inherit;
            }

            .header-row > .column {
                font-weight: 600;
                border: none;
                text-align: center;
            }

            .header-row > .header-column {
                padding-left: 0;
                padding-bottom: 0.3em;
            }

            .filter-input input {
                font-weight: normal;
                padding-top: 0.4em;
                padding-bottom: 0.4em;
            }

            .row.total-row > .column {
                font-weight: bold;
            }

            .total-column {
                font-weight: 600;
            }

            .row:not(.header-row):hover:not([active]) {
                background: #f1f1f1;
                cursor: pointer;
            }

            .row[active] {
                border-radius: var(--border-radius);
                border-color: transparent;
                background: var(--color-primary);
                color: var(--color-bg);
                position: sticky;
                top: 3.5em;
                bottom: 0.5em;
                z-index: 2;
                font-weight: bold;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const employees = app.accessibleEmployees.filter(({ name }) =>
            name.toLowerCase().includes(this._filterString.toLowerCase())
        );

        return html`
            <div class="fullbleed vertical layout">
                <div class="header horizontal spacing center-aligning layout padded-medium noprint">
                    <div class="tabs">
                        <button
                            ?active=${this._segmentation === "workArea"}
                            @click=${() => (this._segmentation = "workArea")}
                        >
                            Arbeitsbereiche
                        </button>
                        <button ?active=${this._segmentation === "type"} @click=${() => (this._segmentation = "type")}>
                            Status
                        </button>
                        <button
                            ?active=${this._segmentation === "component"}
                            @click=${() => (this._segmentation = "component")}
                        >
                            Komponenten
                        </button>
                        <button
                            ?active=${this._segmentation === "employment"}
                            @click=${() => (this._segmentation = "employment")}
                        >
                            Anstellung
                        </button>
                    </div>

                    <div class="stretch"></div>

                    <ptc-checkbox-button
                        .label=${html`<i class="octagon-plus"></i> Inkl. Nebenkosten`}
                        id="ancillaryCostsCheckbox"
                        buttonClass="small slim transparent ghost"
                        checked
                        @change=${() => {
                            this.requestUpdate();
                            this._updateCharts();
                        }}
                    ></ptc-checkbox-button>

                    <ptc-checkbox-button
                        .label=${html`<i class="chart-line"></i> Gesamt`}
                        id="totalCheckbox"
                        buttonClass="small slim transparent ghost"
                        checked
                        @change=${this._updateCharts}
                    ></ptc-checkbox-button>

                    <select .value=${this.year.toString()} @change=${this._yearSelected} class="slim">
                        ${years.map((year) => html` <option .value=${year.toString()}>${year}</option> `)}
                    </select>
                </div>

                <div class="scroller stretch">
                    <div class="charts">
                        <div class="charts-cost-total">
                            <div class="charts-title">
                                ${(this._selectedEmployee && this._selectedEmployee.name) || "Alle Mitarbeiter"}
                            </div>
                            <ptc-chart class="chart-cost-total"></ptc-chart>
                        </div>
                        <div class="charts-cost-monthly">
                            <ptc-chart class="chart-cost-monthly"></ptc-chart>
                        </div>
                    </div>

                    <div
                        class="padded border-bottom"
                        style="position: relative; z-index: 100;"
                        ?hidden=${this._segmentation !== "workArea"}
                    >
                        <ptc-entity-filters
                            .filterTypes=${["venue", "department"] as const}
                            .icon=${"chart-pie"}
                            .hideEmployeeCount=${true}
                            emptyIcon="ban"
                            emptyLabel="Keine Arbeitsbereiche gewählt"
                            noOptionsLabel="Keine Arbeitsbereiche gefunden."
                            @change=${this._updateCharts}
                        ></ptc-entity-filters>
                    </div>

                    <div
                        class="padded border-bottom horizontal center-aligning layout"
                        style="position: relative; z-index: 100;"
                        ?hidden=${this._segmentation !== "type"}
                    >
                        <i class="chart-pie icon left-margined"></i>
                        <ptc-entity-multi-select
                            id="typeSelector"
                            .existing=${timeEntryTypes}
                            .getLabel=${(type: TimeEntryType) => app.localized.timeEntryTypeLabel(type)}
                            .getColor=${(type: TimeEntryType) => timeEntryTypeColor(type)}
                            .getIcon=${(type: TimeEntryType) => app.localized.timeEntryTypeIcon(type)}
                            @change=${this._updateCharts}
                            emptyIcon="ban"
                            emptyLabel="Keine Stati gewählt"
                            noOptionsLabel="Keine Stati gefunden."
                            style="border: none"
                            class="small"
                        ></ptc-entity-multi-select>
                    </div>

                    <div
                        class="padded border-bottom horizontal center-aligning layout"
                        style="position: relative; z-index: 100;"
                        ?hidden=${this._segmentation !== "component"}
                    >
                        <i class="chart-pie icon left-margined"></i>
                        <ptc-entity-multi-select
                            id="componentSelector"
                            .existing=${components}
                            .getLabel=${(component: (typeof components)[number]) => component.name}
                            .getColor=${(component: (typeof components)[number]) => component.color}
                            .getIcon=${(component: (typeof components)[number]) => component.icon}
                            .getId=${(component: (typeof components)[number]) => component.key}
                            @change=${this._updateCharts}
                            emptyIcon="ban"
                            emptyLabel="Keine Komponenten gewählt"
                            noOptionsLabel="Keine Komponenten gefunden."
                            style="border: none"
                            class="small"
                        ></ptc-entity-multi-select>
                    </div>

                    <div
                        class="padded border-bottom horizontal center-aligning layout"
                        style="position: relative; z-index: 100;"
                        ?hidden=${this._segmentation !== "employment"}
                    >
                        <i class="chart-pie icon left-margined"></i>
                        <ptc-entity-multi-select
                            id="employmentTypeSelector"
                            .existing=${employmentTypes}
                            .getLabel=${(type: (typeof employmentTypes)[number]) => type.label}
                            .getColor=${(type: (typeof employmentTypes)[number]) => type.color}
                            .getIcon=${() => "file-contract"}
                            .getId=${(type: (typeof employmentTypes)[number]) => type.type}
                            @change=${this._updateCharts}
                            emptyIcon="ban"
                            emptyLabel="Keine Anstellungen gewählt"
                            noOptionsLabel="Keine Anstellungen gefunden."
                            style="border: none"
                            class="small"
                        ></ptc-entity-multi-select>
                    </div>

                    <div class="table">
                        <div class="row header-row">
                            <div class="column header-column">
                                <div class="filter-input right icon input">
                                    <input
                                        id="filterInput"
                                        type="text"
                                        placeholder="Suchen..."
                                        @input=${this._updateFilter}
                                    />
                                    <i
                                        class="${this._filterString ? "times click" : "search"} icon"
                                        @click=${this._clearFilter}
                                    ></i>
                                </div>
                            </div>

                            ${monthNames.map(
                                (n) => html`
                                    <div class="column vertical end-justifying layout">
                                        <div>${n.slice(0, 3)}</div>
                                    </div>
                                `
                            )}

                            <div class="column vertical end-justifying layout total-column"><div>Gesamt</div></div>
                        </div>

                        <div
                            class="row total-row"
                            ?active=${!this._selectedEmployee}
                            @click=${() => this._selectEmployee(null)}
                        >
                            <div class="column header-column">Alle Mitarbeiter</div>

                            ${monthNames.map(
                                (_n, month) => html`
                                    <div class="column">${formatNumber(this._getCosts({ month }))}</div>
                                `
                            )}

                            <div class="column total-column">${formatNumber(this._getCosts({}))}</div>
                        </div>

                        ${employees.map((employee) => {
                            const data = monthNames.map((_n, month) =>
                                this._getCosts({
                                    employeeId: employee.id,
                                    month,
                                })
                            );

                            if (data.every((d) => !d)) {
                                return null;
                            }

                            return html`
                                <div
                                    class="row"
                                    ?active=${this._selectedEmployee && this._selectedEmployee.id === employee.id}
                                    @click=${() => this._selectEmployee(employee)}
                                >
                                    <div class="column header-column ellipsis">
                                        ${employee.lastName}, ${employee.firstName}
                                    </div>

                                    ${data.map((val) => html` <div class="column">${formatNumber(val)}</div> `)}

                                    <div class="column total-column">
                                        ${formatNumber(data.reduce((total, m) => total + m))}
                                    </div>
                                </div>
                            `;
                        })}
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
