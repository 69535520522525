import { LitElement, html, css } from "lit";
import { customElement, state, query, queryAll } from "lit/decorators.js";
import { RevenueEntry, RevenueType, RevenueGroup, TaxKey } from "@pentacode/core/src/model";
import { GetRevenueEntriesParams, UpdateRevenueEntriesParams, GetRevenueGroupsParams } from "@pentacode/core/src/api";
import { dateAdd, formatDate, formatNumber, sum, round, propSum } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import type { RevenueItem } from "../elements/revenue-item";
import "./revenue-item";
import { alert, confirm } from "./alert-dialog";
import { CountingLogForm } from "./counting-log-form";
import { Popover } from "./popover";
import { Checkbox } from "./checkbox";
import "./spinner";
import { Euros } from "@pentacode/openapi";

@customElement("ptc-revenues-daily")
export class RevenuesDaily extends Routing(StateMixin(LitElement)) {
    routePattern = /^revenues\/daily/;

    get routeTitle() {
        return (this.venue && `Tagesabrechnung: ${this.venue.name}`) || undefined;
    }

    @state()
    private _loading = false;

    @state()
    private _entries: RevenueEntry[] = [];

    @state()
    private _templates: RevenueGroup[] = [];

    @state()
    private _cashDifference = 0;

    @state()
    private _editing = false;

    @query("#cashCount")
    private _cashCountInput: HTMLInputElement;

    @query("ptc-counting-log-form")
    private _countingLogForm: CountingLogForm;

    @query("#enableCountingLogButton")
    private _enableCountingLogButton: Checkbox;

    @query(".counting-log-popover")
    private _countingLogPopover: Popover;

    @queryAll("ptc-revenue-item")
    private _revenueItems: RevenueItem[];

    private get _todaysEntries() {
        return this._entries.filter((e) => e.date === this.date);
    }

    private get _salesEntries() {
        return this._todaysEntries.filter((e) => e.type === RevenueType.Sales);
    }

    private get _cashlessEntries() {
        return this._todaysEntries.filter((e) => e.type === RevenueType.Cashless);
    }

    private get _expenseEntries() {
        return this._todaysEntries.filter((e) => e.type === RevenueType.Expense);
    }

    private get _debtEntries() {
        return this._todaysEntries.filter((e) => e.type === RevenueType.Debt);
    }

    private get _advanceEntries() {
        return this._todaysEntries.filter((e) => e.type === RevenueType.PayAdvance);
    }

    private get _cashCountEntry() {
        return this._todaysEntries.find((e) => e.type === RevenueType.CashCount);
    }

    private get _adjustmentEntry() {
        return this._todaysEntries.find((e) => e.type === RevenueType.Adjustment);
    }

    private get _adjustmentAmount() {
        return (this._adjustmentEntry && this._adjustmentEntry.cash) || 0;
    }

    private get _isDraft() {
        return !this._todaysEntries.some((e) => !e.draft);
    }

    private get _readonly() {
        return this._todaysEntries.some((e) => e.readonly);
    }

    private _getTemplates(type: RevenueType) {
        return this._templates.filter((entry) => entry.type === type);
    }

    private _getSuggestions(type: RevenueType) {
        return this._getTemplates(type).filter(
            (t) => !!t.id && t.daily && !this._todaysEntries.some((e) => e.name === t.name)
        );
    }

    private get _salesTotal() {
        return round(
            propSum(
                this._salesEntries.filter((e) => e.cashbook),
                "cash"
            )
        );
    }

    private get _cashlessTotal() {
        return round(
            propSum(
                this._cashlessEntries.filter((e) => e.cashbook),
                "cash"
            )
        );
    }

    private get _expensesTotal() {
        return round(
            propSum(
                this._expenseEntries.filter((e) => e.cashbook),
                "cash"
            )
        );
    }

    private get _debtTotal() {
        return round(
            propSum(
                this._debtEntries.filter((e) => e.cashbook),
                "cash"
            )
        );
    }

    private get _advanceTotal() {
        return round(
            propSum(
                this._advanceEntries.filter((e) => e.cashbook),
                "cash"
            )
        );
    }

    private get _cashTotalNominal() {
        return round(
            sum([this._salesTotal, this._cashlessTotal, this._expensesTotal, this._debtTotal, this._advanceTotal])
        );
    }

    private get _countingLogEnabled() {
        return !!this._enableCountingLogButton && this._enableCountingLogButton.checked;
    }

    private get _countingLog() {
        return (this._cashCountEntry && this._cashCountEntry.countingLog) || null;
    }

    handleRoute() {
        void this._load();
    }

    private _createRevenueEntry(group: RevenueGroup | Partial<RevenueGroup>) {
        const {
            name = "",
            type = RevenueType.Sales,
            ledger = "",
            costCenter = "",
            taxKey = TaxKey.None,
            postingKey = "",
            cashbook = true,
            reporting = true,
        } = group;

        const entry = new RevenueEntry({
            type,
            name,
            ledger,
            costCenter,
            taxKey,
            postingKey,
            cashbook,
            reporting,
            date: this.date,
            venueId: this.venueId,
            daily: true,
            draft: true,
            amount: 0 as Euros,
            group: group instanceof RevenueGroup && !!group.id ? group : null,
        });
        this._entries.push(entry);
        this.requestUpdate();
        return entry;
    }

    private async _focusNext(e: Event, type: RevenueType) {
        const next = e.target && ((e.target as HTMLElement).nextElementSibling as HTMLElement);
        if (next) {
            next.focus();
        } else {
            this._createRevenueEntry({ type });
            await this.updateComplete;
            setTimeout(() => {
                const next = this.renderRoot!.querySelector(`.${type} ptc-revenue-item:last-child`);
                next && (next as RevenueItem).focus();
            }, 100);
        }
    }

    private _updateCashDifference() {
        const counted = parseFloat(this._cashCountInput.value);
        this._cashDifference = !isNaN(counted) ? round(counted - this._cashTotalNominal - this._adjustmentAmount) : 0;
        this.requestUpdate();
    }

    private _updateAdjustment(name: string, amount: Euros) {
        const adjustment =
            this._adjustmentEntry ||
            this._createRevenueEntry({
                type: RevenueType.Adjustment,
            });

        adjustment.name = name;
        adjustment.amount = amount;
    }

    private async _settleDifference() {
        const adjustedDifference = this._cashDifference;

        const difference = (adjustedDifference + this._adjustmentAmount) as Euros;

        if (!difference) {
            this._updateAdjustment("Kassendifferenz", 0 as Euros);
            return true;
        }

        if (this._cashTotalNominal < 0) {
            const confirmed = await confirm(
                `Es besteht eine positive Kassendifferenz von ${difference.toFixed(
                    2
                )} €. Soll diese als unbares Trinkgeld verbucht werden?`,
                "Bestätigen",
                "Abbrechen",
                { title: "Kassendifferenz" }
            );
            if (!confirmed) {
                return false;
            }

            this._updateAdjustment("Unbares Trinkgeld", difference);
        } else {
            const confirmed = await confirm(
                `Es liegt ein Kassenfehlbestand von ${difference.toFixed(
                    2
                )} € vor. Soll dieser mit einer Kassendifferenzbuchung ausgeglichen werden?`,
                "Bestätigen",
                "Abbrechen",
                { title: "Kassendifferenz" }
            );
            if (!confirmed) {
                return false;
            }

            this._updateAdjustment("Kassendifferenz", difference);
        }

        return true;
    }

    private async _save(settle = false) {
        if (this._loading) {
            return;
        }

        this._cashCountInput.required = !!settle;

        for (const item of [...this._revenueItems, this._cashCountInput]) {
            if (!item.reportValidity()) {
                return;
            }
        }

        if (this._countingLogEnabled && !this._countingLogForm.checkValidity()) {
            this._cashCountInput.focus();
            this._countingLogForm.reportValidity();
            return;
        }

        if (settle && this._cashDifference && !(await this._settleDifference())) {
            return;
        }

        const cashCount = parseFloat(this._cashCountInput.value) as Euros;
        if (!isNaN(cashCount)) {
            if (!this._cashCountEntry) {
                this._createRevenueEntry({
                    type: RevenueType.CashCount,
                    name: "Kassenstand Tageskasse",
                });
            }

            this._cashCountEntry!.amount = cashCount;
            this._cashCountEntry!.countingLog = this._countingLogEnabled ? this._countingLogForm.data : null;
        }

        this._loading = true;
        try {
            const typeOrder = [
                RevenueType.Expense,
                RevenueType.PayAdvance,
                RevenueType.Sales,
                RevenueType.Cashless,
                RevenueType.Debt,
                RevenueType.CashCount,
                RevenueType.Adjustment,
            ];

            const updated = this._entries
                .filter((e) => e.date === this.date && !!e.name && (e.amount || e.type === RevenueType.CashCount))
                .sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));
            const removed = this._entries.filter(
                (e) => e.date === this.date && !!e.id && !e.amount && e.type !== RevenueType.CashCount
            );

            const adjustment = updated.find((e) => e.type === RevenueType.Adjustment);
            if (adjustment && !adjustment.amount) {
                updated.splice(updated.indexOf(adjustment), 1);
                removed.push(adjustment);
            }

            for (const entry of updated) {
                entry.draft = !settle;
            }

            await app.api.updateRevenueEntries(
                new UpdateRevenueEntriesParams({
                    updated,
                    removed,
                })
            );

            this._loading = false;

            await this._load();

            // update issues in the background in case this was reported as an unfinished daily revenues entry
            void app.fetchIssues();
        } catch (e) {
            this._loading = false;
            void alert(e.message, { type: "warning" });
        }
    }

    private async _load() {
        if (this._loading || !this.venue || !this.date) {
            return;
        }

        this._loading = true;
        try {
            const [entries, templates] = await Promise.all([
                app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        from: this.date,
                        to: dateAdd(this.date, { days: 1 }),
                        venue: this.venue.id,
                    })
                ),
                app.api.getRevenueGroups(
                    new GetRevenueGroupsParams({
                        venue: this.venue.id,
                    })
                ),
            ]);

            this._entries = entries.filter((e) => e.daily);
            this._templates = templates;

            for (const entry of this._getSuggestions(RevenueType.Sales)) {
                this._createRevenueEntry(entry);
            }
            this._createRevenueEntry({ type: RevenueType.Sales });
            for (const entry of this._getSuggestions(RevenueType.Cashless)) {
                this._createRevenueEntry(entry);
            }
            this._createRevenueEntry({ type: RevenueType.Cashless });
            for (const entry of this._getSuggestions(RevenueType.Expense)) {
                this._createRevenueEntry(entry);
            }
            this._createRevenueEntry({ type: RevenueType.Expense });
            this._createRevenueEntry({ type: RevenueType.Debt });
            this._createRevenueEntry({ type: RevenueType.PayAdvance });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._cashCountInput.value = (this._cashCountEntry && this._cashCountEntry.amount.toFixed(2)) || "";
        const countingLog = this._countingLog;
        this._countingLogForm.reset(countingLog);
        this._enableCountingLogButton.checked = !!countingLog;
        this._updateCashDifference();

        this._loading = false;
        this._editing = this._isDraft;
    }

    private async _countingLogChanged() {
        const countingLog = this._countingLogForm.data;
        const cashCount = this._countingLogEnabled
            ? countingLog.count.reduce((total, { amount, value }) => total + amount * value, 0).toFixed(2)
            : "";
        this._cashCountInput.value = cashCount;
        this._updateCashDifference();
        this.requestUpdate();
        await this.updateComplete;
        this._countingLogPopover.align();
    }

    private async _print() {
        this._editing = false;
        await this.updateComplete;
        print();
        this._editing = true;
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                position: relative;
                display: flex;
                flex-direction: column;
            }

            ptc-scroller {
                flex: 1;
                min-height: 0;
            }

            .side-bar {
                width: 300px;
            }

            .section {
                border-radius: calc(2 * var(--border-radius));
                background: var(--color-bg);
                border: solid 2px var(--shade-2);
                padding: 1em 0.5em 0.5em 0.5em;
                margin: 2em 1em;
                position: relative;
            }

            .section-title {
                padding: 0 0.4em;
                position: absolute;
                top: -0.8em;
                left: 0.8em;
                background: inherit;
                font-size: var(--font-size-large);
                color: var(--color-primary);
            }

            .section-title {
                font-weight: 600;
            }

            ptc-revenue-item {
                margin: 0.5em;
            }

            .footer {
                padding: 0.5em;
            }

            .footer > :not(:last-child) {
                margin-right: 0.5em;
            }

            .section.results {
                position: sticky;
                top: 2em;
                margin-left: 0;
                border-color: var(--color-primary);
            }

            .results-item {
                display: flex;
                align-items: center;
                margin: 0.5em 0 0.5em 0.5em;
                color: var(--color-highlight);
            }

            .results-item-label {
                flex: 1;
                min-width: 0;
            }

            .results-item-value {
                text-align: right;
                width: 7.5em;
            }

            .results-item-value input {
                text-align: right;
            }

            .results-item-value input[readonly] {
                border-color: transparent;
            }

            .results-item-value.counting-log input[readonly] {
                border-color: var(--purple);
                cursor: pointer;
            }

            .results-item-value.counting-log > i.coins {
                position: absolute;
                left: 0.2em;
                bottom: 0.2em;
                font-size: var(--font-size-tiny);
                top: auto;
                color: var(--purple);
            }

            .results-item.nominal,
            .results-item.actual,
            .results-item.difference {
                font-weight: bold;
            }

            .section .separator {
                border-bottom: solid 1px var(--shade-2);
                margin: 0 0.5em;
            }

            .counting-log-popover {
                padding: 0.5em;
            }

            ptc-counting-log-form {
                width: 25em;
            }

            .section.counting-log {
                margin-left: 0;
                border-color: var(--purple);
            }

            .counting-log .section-title {
                color: var(--purple);
            }

            .counting-log-item {
                margin: 1em;
            }

            .counting-log-item .times {
                position: relative;
                top: 0.1em;
            }

            .counting-log-value {
                font-weight: 600;
            }

            .counting-log-amount {
                font-weight: 600;
                width: 2em;
                text-align: right;
            }

            .signature {
                padding: 0.5em;
                margin: 6em 2em 2em 2em;
                border-top: solid 1px;
                font-size: 0.8em;
                text-align: center;
            }

            .print-header {
                padding: 1em 1em 0 1em;
            }

            @media print {
                ptc-revenue-item {
                    margin: 0;
                }

                ptc-revenue-item:not(:last-child) {
                    border-bottom: solid 1px var(--shade-2);
                }

                :host {
                    display: block;
                }
            }
        `,
    ];

    render() {
        if (!this.venue || !this.date) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const hasCashbookPerms = app.hasPermission(`manage.revenues.cashbook`);

        if (!this._isDraft && !hasCashbookPerms) {
            return html`<div class="fullbleed text-centering centering vertical subtle layout">
                <i class="giant ban"></i>
                <div style="max-width: 25em">
                    Das Einsehen abgeschlossener Tagesabrechnungen erfordert die Berechtigung "Kassenbuch".
                </div>
            </div>`;
        }

        return html`
            <div class="big horizontal layout print-header printonly">
                <div class="stretch">
                    Tagesabrechnung -
                    <strong>${formatDate(this.date)}</strong>
                </div>
                <div>${this.venue.name}</div>
            </div>

            <ptc-scroller>
                <div class="horizontal layout">
                    <div class="sections stretch">
                        <div class="section sales">
                            <div class="section-title">
                                <i class="money-bill-wave"></i>
                                Einnahmen
                            </div>

                            ${this._salesEntries.map((entry) =>
                                !entry.id && !this._editing
                                    ? ""
                                    : html`
                                          <ptc-revenue-item
                                              .entry=${entry}
                                              placeholder="Neuer Eintrag"
                                              .bookingPrefix=${"Umsatz"}
                                              .templates=${this._getTemplates(RevenueType.Sales)}
                                              @submit=${(e: Event) => this._focusNext(e, RevenueType.Sales)}
                                              @change=${this._updateCashDifference}
                                              .readonly=${!this._editing}
                                          ></ptc-revenue-item>
                                      `
                            )}
                        </div>

                        <div class="section cashless">
                            <div class="section-title">
                                <i class="credit-card"></i>
                                Unbare Zahlungen
                            </div>

                            ${this._cashlessEntries.map((entry) =>
                                !entry.id && !this._editing
                                    ? ""
                                    : html`
                                          <ptc-revenue-item
                                              .entry=${entry}
                                              placeholder="Neuer Eintrag"
                                              .bookingPrefix=${"Unbare Zahlungen"}
                                              .templates=${this._getTemplates(RevenueType.Cashless)}
                                              @submit=${(e: Event) => this._focusNext(e, RevenueType.Cashless)}
                                              @change=${this._updateCashDifference}
                                              .readonly=${!this._editing}
                                          ></ptc-revenue-item>
                                      `
                            )}
                        </div>

                        <div class="section debt">
                            <div class="section-title">
                                <i class="file-invoice"></i>
                                Auf Rechnung
                            </div>

                            ${this._debtEntries.map((entry) =>
                                !entry.id && !this._editing
                                    ? ""
                                    : html`
                                          <ptc-revenue-item
                                              .entry=${entry}
                                              placeholder="Neuer Eintrag"
                                              .bookingPrefix=${"Rechnung"}
                                              .templates=${this._getTemplates(RevenueType.Debt)}
                                              @submit=${(e: Event) => this._focusNext(e, RevenueType.Debt)}
                                              @change=${this._updateCashDifference}
                                              .readonly=${!this._editing}
                                          ></ptc-revenue-item>
                                      `
                            )}
                        </div>

                        <div class="section expense">
                            <div class="section-title">
                                <i class="shopping-cart"></i>
                                Ausgaben
                            </div>

                            ${this._expenseEntries.map((entry) =>
                                !entry.id && !this._editing
                                    ? ""
                                    : html`
                                          <ptc-revenue-item
                                              .entry=${entry}
                                              placeholder="Neuer Eintrag"
                                              .bookingPrefix=${"Ausgaben"}
                                              .templates=${this._getTemplates(RevenueType.Expense)}
                                              @submit=${(e: Event) => this._focusNext(e, RevenueType.Expense)}
                                              @change=${this._updateCashDifference}
                                              .readonly=${!this._editing}
                                          ></ptc-revenue-item>
                                      `
                            )}
                        </div>

                        <div class="section advance">
                            <div class="section-title">
                                <i class="heart"></i>
                                Gehaltsvorschüsse
                            </div>

                            ${this._advanceEntries.map((entry) =>
                                !entry.id && !this._editing
                                    ? ""
                                    : html`
                                          <ptc-revenue-item
                                              .entry=${entry}
                                              placeholder="Neuer Eintrag"
                                              .bookingPrefix=${"Gehaltsvorschuss"}
                                              .templates=${this._getTemplates(RevenueType.PayAdvance)}
                                              @submit=${(e: Event) => this._focusNext(e, RevenueType.PayAdvance)}
                                              @change=${this._updateCashDifference}
                                              .readonly=${!this._editing}
                                          ></ptc-revenue-item>
                                      `
                            )}
                        </div>
                    </div>

                    <div class="side-bar">
                        <div class="section results">
                            <div class="section-title">
                                <i class="coins"></i>
                                Tageskasse
                            </div>

                            <div class="results-item">
                                <div class="results-item-label">Umsätze</div>
                                <div class="results-item-value right icon input">
                                    <input type="number" readonly .value=${this._salesTotal.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="results-item">
                                <div class="results-item-label">Unbare Zahlungen</div>
                                <div class="results-item-value right icon input">
                                    <input type="number" readonly .value=${this._cashlessTotal.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="results-item">
                                <div class="results-item-label">Auf Rechnung</div>
                                <div class="results-item-value right icon input">
                                    <input type="number" readonly .value=${this._debtTotal.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="results-item">
                                <div class="results-item-label">Ausgaben</div>
                                <div class="results-item-value right icon input">
                                    <input type="number" readonly .value=${this._expensesTotal.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="results-item">
                                <div class="results-item-label">Gehaltsvorschüsse</div>
                                <div class="results-item-value right icon input">
                                    <input type="number" readonly .value=${this._advanceTotal.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="separator"></div>

                            <div class="results-item nominal ${this._cashTotalNominal < 0 ? "orange" : "blue"}">
                                <div class="results-item-label">Kassenstand Soll</div>
                                <div class="results-item-value right icon input">
                                    <input
                                        type="number"
                                        readonly
                                        .value=${this._cashTotalNominal.toFixed(2)}
                                        step="0.01"
                                    />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>

                            <div class="results-item actual">
                                <div class="results-item-label">Kassenstand Ist</div>
                                <div
                                    class="results-item-value right icon input ${this._countingLogEnabled
                                        ? "counting-log"
                                        : ""}"
                                >
                                    <i class="coins" ?hidden=${!this._countingLogEnabled}></i>
                                    <input
                                        type="number"
                                        id="cashCount"
                                        min="0"
                                        max=${this._cashTotalNominal < 0 ? "0" : ""}
                                        step="0.01"
                                        @input=${this._updateCashDifference}
                                        ?readonly=${!this._editing || this._countingLogEnabled}
                                    />
                                    <i class="euro-sign"></i>
                                </div>

                                <ptc-popover
                                    trigger="focus"
                                    class="counting-log-popover vertical layout"
                                    .preferAlignment=${["right", "left"]}
                                >
                                    <ptc-checkbox-button
                                        buttonClass="transparent"
                                        .label=${html`
                                            <i class="coins"></i>
                                            Zählprotokoll
                                        `}
                                        id="enableCountingLogButton"
                                        @change=${this._countingLogChanged}
                                        .readonly=${!this._editing}
                                    ></ptc-checkbox-button>

                                    <ptc-scroller class="stretch" ?hidden=${!this._countingLogEnabled}>
                                        <ptc-counting-log-form
                                            @change=${this._countingLogChanged}
                                            .readonly=${!this._editing}
                                        ></ptc-counting-log-form>
                                    </ptc-scroller>
                                </ptc-popover>
                            </div>

                            <div class="separator"></div>

                            ${this._adjustmentAmount
                                ? html`
                                      <div class="results-item">
                                          <div class="results-item-label">${this._adjustmentEntry!.name}</div>
                                          <div class="results-item-value right icon input">
                                              <input
                                                  type="number"
                                                  readonly
                                                  .value=${this._adjustmentAmount.toFixed(2)}
                                              />
                                              <i class="euro-sign"></i>
                                          </div>
                                      </div>
                                  `
                                : ""}

                            <div class="results-item difference ${this._cashDifference ? "red" : "green"}">
                                <div class="results-item-label">Differenz</div>
                                <div class="results-item-value right icon input">
                                    <input readonly type="number" .value=${this._cashDifference.toFixed(2)} />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>
                        </div>

                        ${this._countingLog
                            ? html`
                                  <div class="section counting-log printonly">
                                      <div class="section-title">
                                          <i class="coins"></i>
                                          Zählprotokoll
                                      </div>

                                      <div class="counting-log-item">
                                          <strong>Gezählt von: </strong>
                                          ${this._countingLog.countedBy}
                                      </div>

                                      <div class="separator"></div>

                                      ${this._countingLog.count.map(
                                          ({ value, amount }) => html`
                                              <div class="counting-log-item horizontal center-aligning layout">
                                                  <div class="counting-log-value stretch">${formatNumber(value)} €</div>
                                                  <i class="times"></i>
                                                  <div class="counting-log-amount">${amount}</div>
                                              </div>
                                          `
                                      )}
                                  </div>
                              `
                            : ""}

                        <div class="printonly">
                            <div class="signature">Ort, Datum, Unterschrift</div>
                        </div>
                    </div>
                </div>
            </ptc-scroller>

            <div class="horizontal center-aligning layout footer noprint">
                <button class="large transparent icon" @click=${this._print}>
                    <i class="print"></i>
                </button>

                <div class="stretch"></div>

                <button @click=${() => this._save()} ?hidden=${!this._isDraft || !this._editing}>
                    Zwischenspeichern
                </button>

                <button @click=${() => this._load()} ?hidden=${this._isDraft || !this._editing}>Abbrechen</button>

                <button
                    class="primary"
                    @click=${() => this._save(true)}
                    ?hidden=${!this._editing}
                    ?disabled=${!hasCashbookPerms}
                >
                    Abschließen & Buchen
                </button>

                <button
                    @click=${() => (this._editing = true)}
                    ?hidden=${this._editing || this._readonly}
                    ?disabled=${!hasCashbookPerms}
                >
                    Bearbeiten
                </button>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
