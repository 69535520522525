import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { getRange, formatNumber, formatDate, dateAdd } from "@pentacode/core/src/util";
import { GetRevenueEntriesParams } from "@pentacode/core/src/api";
import { RevenueEntry, RevenueType } from "@pentacode/core/src/model";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import "./date-range-picker";
import "./avatar";
import "./spinner";
import { alert } from "./alert-dialog";
import "./popover";
import "./employees-filter";
import { singleton } from "../lib/singleton";
import { PayAdvanceDialog } from "./pay-advance-dialog";
import { DateRange } from "packages/core/src/time";
import { EmployeeSortProperty } from "@pentacode/core";

@customElement("ptc-employees-advances-all")
export class EmployeesAdvanceAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/advances/;

    get routeTitle() {
        return "Vorschüsse: Alle Mitarbeiter";
    }

    defaultRange = () => getRange(new Date(), "month");
    rangeChanged = () => this.load();

    @state()
    private _loading = false;

    @state()
    private _entries: RevenueEntry[] = [];

    @singleton("ptc-pay-advance-dialog")
    private _payAdvanceDialog: PayAdvanceDialog;

    async load() {
        this._loading = true;

        try {
            this._entries = (
                await app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        ...this.dateRange,
                        type: [RevenueType.PayAdvance],
                    })
                )
            ).filter((e) => !e.draft);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newAdvance() {
        const created = await this._payAdvanceDialog.show();
        if (created) {
            // Reload the data in the background
            void this.load();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: column;
                max-width: 75em;
            }

            ptc-scroller {
                flex: 1;
                min-height: 0;
            }

            .month-picker {
                max-width: 600px;
                padding-left: 0;
                padding-right: 0;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .range-input-wrapper {
                margin: -0.2em;
            }

            .range-input-wrapper button {
                padding: 0.5em;
            }

            .cell {
                padding: 0.5em 0.8em;
                width: 7em;
            }

            .cell.amount {
                text-align: right;
            }

            .header .cell {
                text-align: center;
                font-weight: bold;
            }

            .employee {
                border-bottom: solid 1px var(--shade-2);
            }

            .employee-header {
                padding: 0.5em;
            }

            .employee-header ptc-avatar {
                margin-right: 0.5em;
            }

            .employee-header .pill {
                margin-top: 0.5em;
            }

            .employee-name {
                font-weight: 600;
            }

            .employee-advance {
                border-bottom: solid 1px var(--shade-2);
            }

            .employee-advance.cell:not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .employee-advance-total {
                font-weight: bold;
                border-top: solid 1px var(--shade-2);
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                ptc-scroller {
                    height: auto;
                }
            }
        `,
    ];

    render() {
        if (!app.company || !this.dateRange) {
            return;
        }
        return html`
            <div class="padded center-aligning spacing horizontal layout header noprint">
                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                    .range=${this.dateRange}
                ></ptc-date-range-picker>

                <div class="stretch"></div>

                <button class="transparent slim" @click=${this._newAdvance}>
                    <i class="plus"></i>
                </button>

                <button class="transparent slim" @click=${() => print()}>
                    <i class="print"></i>
                </button>
            </div>

            <ptc-employees-filter
                class="noprint border-bottom"
                .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
            ></ptc-employees-filter>

            <div class="horizontal layout padded header printonly small-caps bottom-margined">
                <div class="stretch">
                    Gehaltsvorschüsse
                    <strong
                        >${formatDate(this.dateFrom)} -
                        ${this.dateTo && formatDate(dateAdd(this.dateTo, { days: -1 }))}</strong
                    >
                </div>
                <div class="subtle">${app.company?.name}</div>
            </div>

            <div class="header horizontal layout">
                <div class="stretch"></div>
                <div class="vertical end-justifying layout cell"><div>Datum</div></div>
                <div class="vertical end-justifying layout cell"><div>Konto</div></div>
                <div class="vertical end-justifying layout cell"><div>Betrag</div></div>
            </div>

            <ptc-scroller>
                ${app.getFilteredEmployees(this.dateRange!).map((employee) => {
                    const entries = this._entries.filter((e) => e.employeeId === employee.id);
                    if (!entries.length) {
                        return;
                    }

                    if (!app.employeeMatchesFilters(employee, this.dateRange!)) {
                        return;
                    }

                    const total = entries.reduce((total, entry) => total + Math.abs(entry.amount), 0);

                    return html`
                        <div
                            class="row employee start-aligning horizontal layout click"
                            @click=${() => this.go(`employees/${employee.id}/advances`)}
                        >
                            <div class="employee-header horizontal center-aligning layout stretch">
                                <ptc-avatar class="small noprint" .employee=${employee}></ptc-avatar>
                                <div class="stretch collapse" title=${employee.name}>
                                    <div class="employee-name">${employee.lastName}, ${employee.firstName}</div>
                                    ${employee.staffNumber
                                        ? html`
                                              <div class="tiny pill">Pnr: <strong>${employee.staffNumber}</strong></div>
                                          `
                                        : ""}
                                </div>
                            </div>

                            <div class="employee-advances">
                                ${entries.map(
                                    (entry) => html`
                                        <div class="employee-advance horizontal layout">
                                            <div class="date cell">${formatDate(entry.date)}</div>

                                            <div class="ledger cell">${entry.ledger}</div>

                                            <div class="amount cell">${formatNumber(Math.abs(entry.amount), 2)} €</div>
                                        </div>
                                    `
                                )}

                                <div class="employee-advance-total horizontal layout">
                                    <div class="cell"></div>
                                    <div class="cell"></div>
                                    <div class="cell amount">${formatNumber(total)} €</div>
                                </div>
                            </div>
                        </div>
                    `;
                })}
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
