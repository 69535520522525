import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { parseDurationString, toDurationString } from "@pentacode/core/src/util";
import { Hours } from "packages/openapi/src/units";

@customElement("ptc-duration-input")
export class DurationInput extends LitElement {
    readonly type = "time";

    @property()
    name: string;

    @property({ type: Boolean, reflect: true })
    disabled: boolean;

    @property({ type: Boolean, reflect: true })
    allowNegative: boolean;

    @property({ reflect: true })
    icon: string;

    @property({ type: Number })
    min: number = 0;

    @property({ type: Number })
    max: number = Infinity;

    @property({ type: Boolean })
    required: boolean;

    @property({ type: Boolean })
    readonly: boolean;

    @property({ type: String })
    defaultSign: "+" | "-" = "+";

    @property({ attribute: false })
    get value(): Hours | null {
        const absVal = this._hiddenInput?.value ? (Number(this._hiddenInput.value) as Hours) : null;
        return absVal && this._signSelect?.selectedIndex === this._getSignIndex("-") ? (-absVal as Hours) : absVal;
    }
    set value(val: Hours | null) {
        const updateValues = () => {
            const durationString = typeof val === "number" ? toDurationString(val) : null;
            const normalized = (durationString && this._normalize(durationString)) || "";
            this._input.value = normalized;
            this._hiddenInput.value = typeof val === "number" ? Math.abs(val).toString() : "";
            if (this._signSelect && val) {
                this._signSelect.selectedIndex = val < 0 ? this._getSignIndex("-") : this._getSignIndex("+");
            }
            this._hasChanged = false;
        };

        if (this._input && this._hiddenInput) {
            updateValues();
        } else if (this.updateComplete) {
            void this.updateComplete.then(() => {
                if (!this._input || !this._hiddenInput) return;
                updateValues();
            });
        }
    }

    focus() {
        this._input.focus();
    }

    blur() {
        this._input.blur();
    }

    setCustomValidity(val: string) {
        this._input.setCustomValidity(val);
    }

    @query("input[type='text']")
    private _input: HTMLInputElement;

    @query("input[type='hidden']")
    private _hiddenInput: HTMLInputElement;

    @query("select")
    private _signSelect: HTMLSelectElement;

    private _hasChanged = false;

    private _getSignIndex(sign: "+" | "-") {
        return this.defaultSign === sign ? 0 : 1;
    }

    private _normalize(val: string = "") {
        let [hoursStr, minutesStr] = val.split(":").slice(0, 2);

        if (!hoursStr || !minutesStr) {
            return null;
        }

        hoursStr = hoursStr.replace(/[^\d]/g, "");
        minutesStr = minutesStr.replace(/[^\d]/g, "");

        if (!hoursStr && !minutesStr) {
            return null;
        }

        let hours = Number(hoursStr);
        let minutes = Number(minutesStr);

        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;

        const normalized = `${hours.toString()}:${minutes.toString().padStart(2, "0")}`;

        return normalized;
    }

    private _focus() {
        try {
            this._input.select();
        } catch (e) {
            this._input.setSelectionRange(0, this._input.value.length);
        }
    }

    private _blur() {
        const normalized = this._normalize(this._input.value) || "";
        this._input.value = normalized;
        this._hiddenInput.value = normalized ? parseDurationString(normalized).toString() : "";
        this._validate();
        if (this._hasChanged) {
            this.dispatchEvent(new CustomEvent("change", { composed: true, bubbles: true }));
        }
        this._hasChanged = false;
    }

    private _oninput() {
        const digits = this._input.value.replace(/[^\d]/g, "");
        const hours = digits.slice(0, -2);
        const minutes = digits.slice(-2);
        this._input.value = `${hours.padStart(1, "–")}:${minutes.padStart(2, "–")}`;
        this._hiddenInput.value = parseDurationString(this._normalize(`${hours}:${minutes}`) || "").toString();
        this._validate();
        this._hasChanged = true;
    }

    private _keypress(e: KeyboardEvent) {
        if ((e.key === "-" || e.key === "+") && this._signSelect) {
            this._signSelect.selectedIndex = this._getSignIndex(e.key);
            e.preventDefault();
        }
    }

    private _validate() {
        if (this._hiddenInput.value && Number(this._hiddenInput.value) > this.max) {
            this.setCustomValidity(`Bitte geben Sie einen Wert kleiner oder gleich ${toDurationString(this.max)} ein!`);
        } else if (this._hiddenInput.value && Number(this._hiddenInput.value) < this.min) {
            this.setCustomValidity(`Bitte geben Sie einen Wert größer oder gleich ${toDurationString(this.min)} ein!`);
        } else {
            this.setCustomValidity("");
        }
    }

    static styles = css`
        ptc-duration-input {
            position: relative;
            display: inline-block;
            min-width: 5em;
        }

        ptc-duration-input input {
            width: 100%;
            text-align: center;
            font-variant-numeric: tabular-nums lining-nums;
            letter-spacing: 0.05em;
        }

        ptc-duration-input.slim input {
            padding: 0.5em;
        }

        ptc-duration-input.skinny input {
            padding: 0.3em;
        }

        ptc-duration-input[allownegative] input {
            padding-left: 1.5em;
        }

        ptc-duration-input[icon] input {
            padding-left: 2em;
        }

        ptc-duration-input i {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0.5em;
            margin: auto 0;
            opacity: 0.9;
            font-size: 90%;
            transform: translate(0, 1px);
        }

        ptc-duration-input select {
            border: none;
            position: absolute;
            top: 0.05em;
            left: 0.4em;
            background: transparent;
        }
    `;

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            ${
                this.allowNegative
                    ? html`
                          <select class="plain skinny" @input=${this._oninput} tabindex="-1">
                              <option>${this.defaultSign}</option>
                              <option>${this.defaultSign === "+" ? "-" : "+"}</option>
                          </select>
                      `
                    : this.icon
                      ? html` <i class="${this.icon}"></i> `
                      : ""
            }

            <input
                type="text"
                inputmode="numeric"
                placeholder="–:––"
                @focus=${this._focus}
                @blur=${this._blur}
                @input=${this._oninput}
                @keypress=${this._keypress}
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                autocomplete="nah"
            />

            <input type="hidden" .name=${this.name}></input>
        `;
    }
}
