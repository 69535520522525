import { LitElement, css, unsafeCSS, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";
import ApexCharts from "apexcharts";
// @ts-expect-error importing a file
// eslint-disable-next-line import/no-unresolved
import apexcss from "!!raw-loader!apexcharts/dist/apexcharts.css";

export type ChartConfig = ApexCharts.ApexOptions;

@customElement("ptc-chart")
export class Chart extends LitElement {
    private _chart: ApexCharts;

    static styles = [
        shared,
        css`
            ${unsafeCSS(apexcss)}

            :host {
                display: block;
            }
        `,
    ];

    @property({ attribute: false })
    config: ChartConfig;

    @property({ attribute: false })
    series: ChartConfig["series"];

    private _initChart() {
        const config = { ...this.config };
        config.chart!.fontFamily = "Nunito";
        config.chart!.toolbar = { show: false };
        const el = document.createElement("div");
        this.renderRoot.querySelector(".inner")!.appendChild(el);
        this._chart = new ApexCharts(el, config);
        void this._chart.render();
    }

    async updated(changes: Map<string, unknown>) {
        if (this.config && !this._chart) {
            this._initChart();
        }

        if (changes.has("config") && this.config) {
            const { series, ...rest } = this.config;
            await this._chart.updateOptions(rest);

            if (series) {
                this.series = series;
            }
        }

        if (changes.has("series") && this.series) {
            await this._chart.updateSeries(this.series);
        }
    }

    render() {
        return html` <div class="inner"></div> `;
    }
}
