import {
    AbsenceMode,
    Benefit,
    Bonus,
    Company,
    Contract,
    Employee,
    EmploymentType,
    employmentTypeLabel,
    NominalHoursMode,
    Salary,
    VacationIncrement,
} from "./model";
import { DateRange } from "./time";
import { formatNumber, toDurationString } from "./util";

type Field = {
    prop: keyof Contract | (keyof Contract)[];
    label: string;
    formatValue: (company: Company, ...val: unknown[]) => string;
    hideOnAccountingReport?: true;
};

const FIELDS: Field[] = [
    {
        prop: "employmentType",
        label: "Beschäftigungsverhältnis",
        formatValue: (_, val: EmploymentType) => employmentTypeLabel(val),
    },
    {
        prop: ["nominalHoursMode", "hoursPerWeek", "hoursPerDay"],
        label: "Sollstunden",
        formatValue: (_, mode: NominalHoursMode, hoursPerWeek: number, hoursPerDay: Contract["hoursPerDay"]) => {
            const fixedDays = hoursPerDay
                ?.filter((v) => !!v)
                .map(
                    (h, i) => `
                        ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"][i]}
                        <strong> ${toDurationString(h, true)} </strong>
                    `
                )
                .join(" ");

            switch (mode) {
                case NominalHoursMode.WeekFactor:
                    return `${formatNumber(hoursPerWeek)} Std / Wo (Wochenfaktor)`;
                case NominalHoursMode.Exact:
                    return `${formatNumber(hoursPerWeek)} Std / Wo (Monatsgenau)`;
                case NominalHoursMode.ExactWithoutHolidays:
                    return `${formatNumber(hoursPerWeek)} Std / Wo (Monatsgen. abz. Feiert.)`;
                case NominalHoursMode.FixedDays:
                    return fixedDays || "";
                case NominalHoursMode.FixedDaysWithoutHolidays:
                    return `${fixedDays} (abz. Feiert.)`;
            }
        },
    },
    {
        prop: "salaries",
        label: "Lohn/Gehalt",
        formatValue: (_, val: Salary[]) =>
            val
                .map(({ amount, type }) => `${formatNumber(amount)} € / ${type === "monthly" ? "Monat" : "Stunde"}`)
                .join(", "),
    },
    {
        prop: "benefits",
        label: "Sonderleistungen",
        formatValue: (company, val: Benefit[]) =>
            val
                .map(({ typeId, amount }) => {
                    const type = company.benefitTypes.find((t) => t.id === typeId);
                    return `${type?.name || "Unbekannt"}: ${formatNumber(amount)} €`;
                })
                .join(", ") || "Keine",
    },
    {
        prop: "bonuses",
        label: "Zuschläge",
        formatValue: (company, val: Bonus[]) =>
            val
                .sort((a, b) => a.id - b.id)
                .map(({ typeId, percent }) => {
                    const type = company.bonusTypes.find((t) => t.id === typeId);
                    return `${type?.name || "Unbekannt"}: ${formatNumber(percent)} %`;
                })
                .join(", ") || "Keine",
    },
    {
        prop: ["absenceMode", "absenceHours", "hoursPerDay"],
        label: "Fehlstundenberechnung",
        formatValue: (_, val: AbsenceMode, hours: number, hoursPerDay: number[]) =>
            val === AbsenceMode.Average
                ? "13-Wochen-Durchschnitt"
                : val === AbsenceMode.FixedDays
                  ? `
                    Feste Wochentage:
                    ${
                        hoursPerDay
                            ?.filter((v) => !!v)
                            .map(
                                (h, i) => `
                                ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"][i]}
                                <strong> ${toDurationString(h, true)} </strong>
                            `
                            )
                            .join(" ") || "Keine"
                    }
                `
                  : `Pauschale Stundenzahl: ${formatNumber(hours)} Stunden`,
        hideOnAccountingReport: true,
    },
    {
        prop: "annualTimeSheet",
        label: "Arbeitszeitkonto vereinbart",
        formatValue: (_, val: boolean) => (val ? "Ja" : "Nein"),
        hideOnAccountingReport: true,
    },
    {
        prop: "enableSFNLedger",
        label: "SFN Konto",
        formatValue: (_, val: boolean) => (val ? "Ja" : "Nein"),
        hideOnAccountingReport: true,
    },
    {
        prop: "sfnAdvance",
        label: "SFN-Pauschale",
        formatValue: (_, val: number) => `${formatNumber(val)} € / Monat`,
    },
    // {
    //     prop: "christmasBonus",
    //     label: "Weihnachtsgeld",
    //     formatValue: (val: number) => `${formatNumber(val)} €`,
    // },
    // {
    //     prop: "vacationBonus",
    //     label: "Urlaubsgeld",
    //     formatValue: (val: number) => `${formatNumber(val)} €`,
    // },
    {
        prop: "maxSalary",
        label: "Lohngrenze",
        formatValue: (_, val: number) => (val ? `${formatNumber(val)} €` : "Keine"),
    },
    {
        prop: "vacationDays",
        label: "Urlaubsanspruch",
        formatValue: (_, val: number) => `${formatNumber(val)} Tage / Jahr`,
    },
    {
        prop: "vacationIncrement",
        label: "Urlaubszuwachs",
        formatValue: (_, val: VacationIncrement) =>
            val === VacationIncrement.Monthly ? "monatlich" : "nach Arbeitsstunden",
        hideOnAccountingReport: true,
    },
    // {
    //     prop: "bonusNight1",
    //     label: "Nacht-1-Zuschlag",
    //     formatValue: (val: number) => `${formatNumber(val)} %`,
    // },
    // {
    //     prop: "bonusNight2",
    //     label: "Nacht-2-Zuschlag",
    //     formatValue: (val: number) => `${formatNumber(val)} %`,
    // },
    // {
    //     prop: "bonusSunday",
    //     label: "Sonntagszuschlag",
    //     formatValue: (val: number) => `${formatNumber(val)} %`,
    // },
    // { prop: "bonusHoliday", label: "Feitertagszuschlag", formatValue: (val: number) => `${formatNumber(val)} %` },
    // { prop: "bonusSpecial", label: "Sondertags-Zuschlag", formatValue: (val: number) => `${formatNumber(val)} %` },
    // {
    //     prop: "stackBonuses",
    //     label: "Zuschläge kumulieren",
    //     formatValue: (val: boolean) => (val ? "Ja" : "Nein"),
    // },
    // {
    //     prop: "blocked",
    //     label: "Beschäftigungspause",
    //     formatValue: (val: boolean) => (val ? "Ja" : "Nein"),
    // },
];

export type ContractChange = {
    type: "start" | "end" | "pause" | "resume" | "change";
    date: string;
    contractA?: Contract;
    contractB?: Contract;
    fields: {
        label: string;
        before?: string;
        after?: string;
        hideOnAccountingReport?: true;
    }[];
};

export function compareContracts(company: Company, a: Contract, b: Contract) {
    const change: ContractChange = {
        date: a.end!,
        type: b.blocked ? "pause" : a.blocked && !b.blocked ? "resume" : "change",
        fields: [],
        contractA: a,
        contractB: b,
    };

    for (const { prop, label, formatValue, hideOnAccountingReport } of FIELDS) {
        const before = Array.isArray(prop)
            ? formatValue(company, ...prop.map((p) => a[p]))
            : formatValue(company, a[prop]);
        const after = Array.isArray(prop)
            ? formatValue(company, ...prop.map((p) => b[p]))
            : formatValue(company, b[prop]);
        if (before !== after) {
            change.fields.push({
                label,
                before,
                after,
                hideOnAccountingReport,
            });
        }
    }

    return change;
}

export function getContractChanges(company: Company, emp: Employee, range?: DateRange) {
    const contracts = [...emp.contracts].sort((a, b) => (a.start < b.start ? -1 : 1));
    const changes: ContractChange[] = [];

    for (let i = 0; i < contracts.length; i++) {
        const a = contracts[i - 1];
        const b = contracts[i];
        if (a && a.end === b.start && (!range || (b.start >= range.from && b.start < range.to))) {
            changes.push(compareContracts(company, a, b));
        } else {
            if (a && a.end && (!range || (a.end >= range.from && a.end < range.to))) {
                changes.push({
                    type: "end",
                    date: a.inclusiveEnd!,
                    contractA: a,
                    fields: FIELDS.map(({ label, prop, formatValue, hideOnAccountingReport }) => ({
                        hideOnAccountingReport,
                        label,
                        before: Array.isArray(prop)
                            ? formatValue(company, ...prop.map((p) => a[p]))
                            : formatValue(company, a[prop]),
                    })),
                });
            }
            if (!range || (b.start >= range.from && b.start < range.to)) {
                changes.push({
                    type: "start",
                    date: b.start,
                    contractB: b,
                    fields: FIELDS.map(({ label, prop, formatValue, hideOnAccountingReport }) => ({
                        hideOnAccountingReport,
                        label,
                        after: Array.isArray(prop)
                            ? formatValue(company, ...prop.map((p) => b[p]))
                            : formatValue(company, b[prop]),
                    })),
                });
            }
        }
    }

    const finalContract = contracts[contracts.length - 1];
    if (finalContract?.end && (!range || (finalContract.end >= range.from && finalContract.end < range.to))) {
        changes.push({
            type: "end",
            date: finalContract.inclusiveEnd!,
            contractA: finalContract,
            fields: FIELDS.map(({ label, prop, formatValue, hideOnAccountingReport }) => ({
                hideOnAccountingReport,
                label,
                before: Array.isArray(prop)
                    ? formatValue(company, ...prop.map((p) => finalContract[p]))
                    : formatValue(company, finalContract[prop]),
            })),
        });
    }

    return changes;
}
