import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BonusType } from "@pentacode/core/src/model";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./scroller";
import "./bonus-type-item";
import { alert, confirm } from "./alert-dialog";
import { clone } from "@pentacode/core/src/encoding";
import { singleton } from "../lib/singleton";
import { BonusTypeDialog } from "./bonus-type-dialog";
import "./sortable-list";

@customElement("ptc-settings-bonuses")
export class SettingsBonuses extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/bonuses/;

    @state()
    private _loading = false;

    @state()
    private _bonusTypes: BonusType[] = [];

    @singleton("ptc-bonus-type-dialog")
    private _bonusTypeDialog: BonusTypeDialog;

    private _reset() {
        this._bonusTypes = app.company?.bonusTypes?.map(clone) || [];
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("active") && this.active) {
            this._reset();
        }
    }

    private async _addBonusType(bonusType = new BonusType()) {
        const submitted = await this._bonusTypeDialog.show(bonusType);
        if (submitted) {
            this._bonusTypes.push(bonusType);
            await this._submit();
        }
    }

    private async _editBonusType(type: BonusType) {
        const changed = await this._bonusTypeDialog.show(type);
        if (changed) {
            await this._submit();
        }
    }

    private async _removeBonusType(index: number) {
        if (
            await confirm("Sind Sie sicher dass Sie diesen Zuschlagsart löschen möchten?", "Löschen", "Abbrechen", {
                type: "destructive",
            })
        ) {
            this._bonusTypes.splice(index, 1);
            await this._submit();
        }
    }

    private async _duplicateBonusType(type: BonusType) {
        const duplicate = clone(type);
        // @ts-ignore
        delete duplicate.id;
        duplicate.name += " (Kopie)";
        // @ts-ignore
        duplicate.overrides.forEach((o) => delete o.id);
        await this._addBonusType(duplicate);
    }

    private async _orderChanged() {
        this._bonusTypes.forEach((t, i) => (t.order = i));
        await this._submit();
    }

    private async _submit() {
        if (!app.company) {
            return;
        }
        this._loading = true;
        try {
            await app.updateCompany({ bonusTypes: this._bonusTypes });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._reset();
        this._loading = false;
    }

    static styles = [shared, css``];

    render() {
        return html`
            <ptc-scroller class="fullbleed">
                <div style="max-width: 50em; margin: 0 auto;">
                    <div class="padded">
                        <h2>Zuschlagsarten</h2>
                        <div class="box">
                            <ptc-sortable-list
                                .items=${this._bonusTypes}
                                .renderItem=${(type: BonusType, i: number) => html`
                                    <div class="relative border-bottom">
                                        <ptc-bonus-type-item
                                            .bonusType=${type}
                                            @click=${() => this._editBonusType(type)}
                                            class="click"
                                            style="padding-right: 3em;"
                                        ></ptc-bonus-type-item>

                                        <button class="transparent slim margined absolute top right" type="button">
                                            <i class="ellipsis-h"></i>
                                        </button>

                                        <ptc-popover class="popover-menu" hide-on-click>
                                            <button @click=${() => this._editBonusType(type)} type="button">
                                                <i class="pencil-alt"></i>
                                                Bearbeiten
                                            </button>

                                            <button @click=${() => this._removeBonusType(i)} type="button">
                                                <i class="trash"></i>
                                                Entfernen
                                            </button>

                                            <button @click=${() => this._duplicateBonusType(type)} type="button">
                                                <i class="clone"></i>
                                                Duplizieren
                                            </button>
                                        </ptc-popover>
                                    </div>
                                `}
                                @item-moved=${this._orderChanged}
                            ></ptc-sortable-list>

                            <div class="margined vertical layout" @click=${() => this._addBonusType()}>
                                <button class="transparent"><i class="plus"></i> Neue Zuschlagsart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
