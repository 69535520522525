import { ExportFormat } from "@pentacode/core";
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";
import { app } from "../init";

@customElement("ptc-exports-menu")
export class ExportsMenu extends LitElement {
    @property({ type: String })
    page: ExportFormat;

    @property({ attribute: false })
    hasPermission: (page: ExportFormat) => boolean;

    go(page: ExportFormat) {
        this.dispatchEvent(
            new CustomEvent<ExportFormat>("navigate", {
                detail: page,
            })
        );
    }

    static styles = [shared];

    render() {
        return html`
            <div class="vertical tabs">
                <div class="subtle margined">
                    <i class="hand-holding-dollar"></i>
                    Lohnbuchhaltung
                </div>
                <button
                    ?active=${this.page === ExportFormat.PayrollReportDetailed}
                    @click=${() => this.go(ExportFormat.PayrollReportDetailed)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollReportDetailed)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohnbericht (Detailliert)</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollReportOverview}
                    @click=${() => this.go(ExportFormat.PayrollReportOverview)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollReportOverview)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohnbericht (Übersicht)</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollDatevLG}
                    @click=${() => this.go(ExportFormat.PayrollDatevLG)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollDatevLG)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Lohn & Gehalt</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollDatevLodas}
                    @click=${() => this.go(ExportFormat.PayrollDatevLodas)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollDatevLodas)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Lodas</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollAgenda}
                    @click=${() => this.go(ExportFormat.PayrollAgenda)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollAgenda)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Agenda</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollLexware}
                    @click=${() => this.go(ExportFormat.PayrollLexware)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollLexware)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lexware</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollAddison}
                    @click=${() => this.go(ExportFormat.PayrollAddison)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollAddison)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Addison</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.PayrollLohnAG}
                    @click=${() => this.go(ExportFormat.PayrollLohnAG)}
                    ?disabled=${!this.hasPermission(ExportFormat.PayrollLohnAG)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohn-AG</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                ${app.company?.country === "AT"
                    ? html`
                          <button
                              ?active=${this.page === ExportFormat.PayrollBMDWages}
                              @click=${() => this.go(ExportFormat.PayrollBMDWages)}
                              ?disabled=${!this.hasPermission(ExportFormat.PayrollBMDWages)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">BMD Lohndaten</div>
                                  <i class="subtle file-csv"></i>
                              </div>
                          </button>
                          <button
                              ?active=${this.page === ExportFormat.PayrollBMDAbsences}
                              @click=${() => this.go(ExportFormat.PayrollBMDAbsences)}
                              ?disabled=${!this.hasPermission(ExportFormat.PayrollBMDAbsences)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">BMD Nichtleistungszeiten</div>
                                  <i class="subtle file-csv"></i>
                              </div>
                          </button>
                      `
                    : ""}

                <div class="subtle margined">
                    <i class="list-timeline"></i>
                    Arbeitszeiten
                </div>
                <button
                    ?active=${this.page === ExportFormat.TimeSheet}
                    @click=${() => this.go(ExportFormat.TimeSheet)}
                    ?disabled=${!this.hasPermission(ExportFormat.TimeSheet)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Arbeitszeit-Nachweis</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="table"></i>
                    Dienstplan
                </div>
                <button
                    ?active=${this.page === ExportFormat.RosterExcel}
                    @click=${() => this.go(ExportFormat.RosterExcel)}
                    ?disabled=${!this.hasPermission(ExportFormat.RosterExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Dienstplan</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="scale-balanced"></i>
                    Konten
                </div>

                <button
                    ?active=${this.page === ExportFormat.BalancesTimeExcel}
                    @click=${() => this.go(ExportFormat.BalancesTimeExcel)}
                    ?disabled=${!this.hasPermission(ExportFormat.BalancesTimeExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Arbeitszeitkonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <button
                    ?active=${this.page === ExportFormat.BalancesVacationExcel}
                    @click=${() => this.go(ExportFormat.BalancesVacationExcel)}
                    ?disabled=${!this.hasPermission(ExportFormat.BalancesVacationExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Urlaubskonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <button
                    ?active=${this.page === ExportFormat.BalancesBonusesExcel}
                    @click=${() => this.go(ExportFormat.BalancesBonusesExcel)}
                    ?disabled=${!this.hasPermission(ExportFormat.BalancesBonusesExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Zuschlagskonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                ${app.company?.country === "AT"
                    ? html`
                          <button
                              ?active=${this.page === ExportFormat.OvertimeExcel}
                              @click=${() => this.go(ExportFormat.OvertimeExcel)}
                              ?disabled=${!this.hasPermission(ExportFormat.OvertimeExcel)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">Überstunden</div>
                                  <i class="subtle file-excel"></i>
                              </div>
                          </button>

                          <button
                              ?active=${this.page === ExportFormat.AverageWorkTimeExcel}
                              @click=${() => this.go(ExportFormat.AverageWorkTimeExcel)}
                              ?disabled=${!this.hasPermission(ExportFormat.AverageWorkTimeExcel)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">Durchschnittliche Arbeitszeit</div>
                                  <i class="subtle file-excel"></i>
                              </div>
                          </button>
                      `
                    : ""}

                <div class="subtle margined">
                    <i class="address-card"></i>
                    Stammdaten
                </div>
                <button
                    ?active=${this.page === ExportFormat.EmployeeDataPDF}
                    @click=${() => this.go(ExportFormat.EmployeeDataPDF)}
                    ?disabled=${!this.hasPermission(ExportFormat.EmployeeDataPDF)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Datenblatt</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.EmployeeDataCSV}
                    @click=${() => this.go(ExportFormat.EmployeeDataCSV)}
                    ?disabled=${!this.hasPermission(ExportFormat.EmployeeDataCSV)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">CSV-Export</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="book"></i>
                    Kassenbuch
                </div>
                <button
                    ?active=${this.page === ExportFormat.CashbookPDF}
                    @click=${() => this.go(ExportFormat.CashbookPDF)}
                    ?disabled=${!this.hasPermission(ExportFormat.CashbookPDF)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Ausdruck / PDF</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.CashbookDatev}
                    @click=${() => this.go(ExportFormat.CashbookDatev)}
                    ?disabled=${!this.hasPermission(ExportFormat.CashbookDatev)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Buchungsstapel</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.CashbookDatevOnline}
                    @click=${() => this.go(ExportFormat.CashbookDatevOnline)}
                    ?disabled=${!this.hasPermission(ExportFormat.CashbookDatevOnline)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Datev Online</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.CashbookLexware}
                    @click=${() => this.go(ExportFormat.CashbookLexware)}
                    ?disabled=${!this.hasPermission(ExportFormat.CashbookLexware)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lexware</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this.page === ExportFormat.CashbookGeneric}
                    @click=${() => this.go(ExportFormat.CashbookGeneric)}
                    ?disabled=${!this.hasPermission(ExportFormat.CashbookGeneric)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Generisch</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <div class="subtle margined">
                    <i class="fa-sliders"></i>
                    Individuelle Exporte
                </div>
                <button
                    ?active=${this.page === ExportFormat.EmployeeTableExcel}
                    @click=${() => this.go(ExportFormat.EmployeeTableExcel)}
                    ?disabled=${!this.hasPermission(ExportFormat.EmployeeTableExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Individuelle Excel-Tabelle</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>
            </div>
        `;
    }
}
