import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import "./scroller";
import { alert } from "./alert-dialog";
import "./avatar";
import { CreateInvitesParams } from "@pentacode/core/src/api";
import { Employee } from "@pentacode/core/src/model";
import { VirtualList } from "./virtual-list";

interface RecipientData {
    employee: Employee;
    email: string;
    checked: boolean;
}

@customElement("ptc-invite-dialog")
export class InviteDialog extends Dialog<Employee | undefined, void> {
    @query("[name='message']")
    private _messageInput: HTMLInputElement;

    @state()
    private _recipients: RecipientData[] = [];

    @query("ptc-virtual-list")
    private _list: VirtualList<RecipientData>;

    async show(emp?: Employee): Promise<void> {
        const promise = super.show();

        this._recipients = app.accessibleEmployees
            .filter((e) => e.active && !e.accountId)
            .map((employee) => ({ employee, email: employee.email, checked: employee.id === emp?.id }))
            .sort((a, b) => Number(!!b.email) - Number(!!a.email));

        await this.updateComplete;

        setTimeout(() => this._list.scrollToIndex(this._recipients.findIndex((r) => r.checked)), 500);

        return promise;
    }

    private _toggle(e: Event, r?: RecipientData) {
        const input = e.target as Checkbox;
        const checked = input.checked;

        if (r) {
            r.checked = checked;
        } else {
            this._recipients.forEach((r) => (r.checked = checked));
        }

        this.requestUpdate();
    }

    private _emailUpdated(e: Event, r: RecipientData) {
        r.email = (e.target as HTMLInputElement).value;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const missing = this._recipients.findIndex((r) => r.checked && !r.email);
        if (missing !== -1) {
            await this._list.scrollToIndex(missing);
            setTimeout(() => {
                const invalidInput = this.renderRoot.querySelector(":invalid") as HTMLInputElement;
                invalidInput?.focus();
                invalidInput?.reportValidity();
            }, 500);
            return;
        }

        const message = this._messageInput.value;
        const recipients = this._recipients
            .filter((r) => r.checked)
            .map(({ employee: { id }, email }) => ({
                id,
                email,
            }));

        this.loading = true;
        try {
            await app.api.createInvites(new CreateInvitesParams({ message, recipients }));
            this.done();
        } catch (e) {
            this.done();
            void alert(e.message, { type: "warning" });
        }
        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        css`
            .inner {
                padding: 0;
                overflow: hidden !important;
                max-width: 40em;
                height: 100%;
                max-height: 50em;
            }

            form {
                height: 100%;
            }

            select[name="full"] {
                margin-right: 0.5em;
            }

            ptc-checkbox {
                padding: 0;
            }
        `,
    ];

    renderContent() {
        return html`
            <form
                class="vertical layout"
                @submit=${this._submit}
                @input=${() => this.requestUpdate()}
                autocomplete="off"
            >
                <div class="padded horizontal center-aligning layout">
                    <div class="large stretch horizontally-margined">
                        <i class="envelope"></i> Einladungen Versenden
                    </div>

                    <ptc-checkbox-button
                        label="Alle"
                        buttonClass="transparent"
                        @change=${(e: Event) => this._toggle(e)}
                        .checked=${this._recipients.every((r) => r.checked)}
                    ></ptc-checkbox-button>
                </div>

                <ptc-virtual-list
                    class="stretch relative"
                    .data=${this._recipients}
                    .itemHeight=${36}
                    .renderItem=${(r: RecipientData) => html`
                        <div
                            class="spacing center-aligning horizontal layout half-padded horizontally-margined border-bottom"
                        >
                            <ptc-avatar .employee=${r.employee} class="tinier"></ptc-avatar>
                            <div class="stretch">${r.employee.name}</div>
                            <input
                                .value=${r.email}
                                placeholder="Emailadresse"
                                class="small skinny"
                                style="width: 20em"
                                ?required=${r.checked}
                                type="email"
                                autocomplete="off"
                                @change=${(e: Event) => this._emailUpdated(e, r)}
                            />
                            <ptc-checkbox
                                .checked=${r.checked}
                                @change=${(e: Event) => this._toggle(e, r)}
                            ></ptc-checkbox>
                        </div>
                    `}
                >
                </ptc-virtual-list>

                <div class="padded spacing vertical layout">
                    <textarea name="message" placeholder="Nachricht (optional)"></textarea>
                    <div class="horizontal spacing stretching layout">
                        <button class="primary" type="submit" ?disabled=${!this._recipients.some((r) => r.checked)}>
                            An <strong>${this._recipients.filter((r) => r.checked).length}</strong> Mitarbeiter
                            Versenden
                        </button>
                        <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                    </div>
                </div>
            </form>
        `;
    }
}
